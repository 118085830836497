import { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Form } from '@kega/sps-elements';
import { useConfig, useStorefront } from '@kega/sps-core';

import { Col, Row } from '../../../components/grid';
import { t } from '../../../lib/translations';

import ErrorMessage from '../../../components/messages/ErrorMessage';

import useReCaptcha from '../../../hooks/useReCaptcha';
import useTranslatePath from '../../../hooks/useTranslatePath';
import useRegister from '../../../hooks/useRegister';

import Input from '../../../components/form/Input';
import Button from '../../../components/buttons/Button';

import classes from './CompanyCheck.module.css';

const CompanyCheck = () => {
    const config = useConfig();
    const navigate = useNavigate();
    const { setBreadcrumb  } = useOutletContext();

    const [ mode, setMode ] = useState(null);
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    const { getPath } = useTranslatePath();
    const { addCompanies, addCompany, clear } = useRegister();

    const { api: { occregister } } = useStorefront();

    const { recaptchakey } = config.get('google');

    const { loadToken, loading: loadingToken } = useReCaptcha({ 
        key: recaptchakey,  
        action: 'companycheck',
        language: config.get('language'),
        autoLoad: false
    });

    useEffect(() => {
        setBreadcrumb([
            { name: 'Home', url_key: '/' },
            { name: 'Registratie', url_key: '/register/companycheck' },
            { name: t('register.companycheck.header') }
        ]);
    }, []);

    const getCompanyInfo = async ({ kvk='', postalcode='', housenumber='', vatNumber='' }, mode) => {
        const recaptchaToken = await loadToken();

        let payload = {
            recaptchaToken: recaptchaToken
        }

        if (config.get('country') === 'nl') {
            if (mode === 'kvk') {
                payload = {
                    ...payload,
                    dossierNumber: kvk,
                }
            } else {
                payload = {
                    ...payload,
                    postalCode: postalcode,
                    houseNumber: housenumber,
                }
            }
        } else {
            payload = {
                ...payload,
                vatNumber: vatNumber
            }
        }

        return await occregister.getCompanyInfo(payload);
    
    }

    const onSubmit = async ({ valid, values, resetForm }) => {

        if (valid) {

            try {

                setLoading(true);

                const companies = await getCompanyInfo(values, mode);
                if (companies && companies.length > 0) {

                    clear();

                    addCompanies(companies);
                    
                    resetForm();

                    setLoading(false);

                    if (companies.length === 1) {
                        const company = await getCompanyInfo({ kvk: companies[0].dossierNumber, vatNumber: companies[0].vatNumber }, 'kvk');
                        addCompany(company[0]);

                        navigate(getPath('/register/details'));
                    } else {
                        navigate(getPath('/register/selectcompany'));
                    }
                } else {
                    setError({ message: t('register.companycheck.error_no_companies') });
                    setLoading(false);
                }

            } catch (error) {
                if (error?.data?.errors) {
                    setError(error?.data?.errors);
                } else {
                    setError(error);
                }
                setLoading(false);
            }
        }
    }

    const onPostalcodeChange = ({ target: { value } }) => {
        if (mode !== 'postalcode' && value.length > 0) {
            setMode('postalcode');
        } else if (mode === 'postalcode' && value.length === 0) {
            setMode(null);
        }
    }

    const onNumberChange = ({ target: { value } }) => {
        if (mode !== 'kvk' && value.length > 0) {
            setMode('kvk');
        } else if (mode === 'kvk' && value.length === 0) {
            setMode(null);
        }
    }

    return (
        <>
            {
                error !== null
                &&
                <ErrorMessage>
                    { 
                        Array.isArray(error)
                            ?
                            <>
                                {
                                    error.map(({ message }, index) => {
                                        return <span key={'e'+index}>{ message }</span>
                                    })
                                }
                            </>
                            :
                            error?.message 
                    }
                </ErrorMessage>
            }

            <Form onSubmit={onSubmit}>
                <div className={classes.form}>
                    {
                        config.get('country') === 'nl'
                            ?
                            <Row>
                                <Col md="12"><h4 className={classes.header}>{ t('register.companycheck.kvk_header') }</h4></Col>
                                <Col md="12"><Input name="kvk" placeholder={ t('register.companycheck.kvk_label') } label={ t('register.companycheck.kvk_label') } required={(mode === null || mode === 'kvk')} onChange={onNumberChange} className={classes.input} /></Col>
                                <Col md="12"><h4 className={classes.header}>{ t('register.companycheck.postalcode_header') }</h4></Col>
                                <Col md="6"><Input name="postalcode" placeholder={ t('form.postalcode_label') } label={ t('form.postalcode_label') } required={(mode === null || mode === 'postalcode')} onChange={onPostalcodeChange} className={classes.input} /></Col>       
                                <Col md="6"><Input name="housenumber" placeholder={ t('form.housenumber_label') } label={ t('form.housenumber_label') } required={(mode === null || mode === 'postalcode')} className={classes.input} /></Col> 
                                <Col md="12"><Button variant="primary" type="submit" loading={(loadingToken || loading)} >{ t('register.companycheck.btn_send') }</Button></Col>
                            </Row>
                            :
                            <Row>
                                <Col md="12"><h4 className={classes.header}>{ t('register.companycheck.companynumber_header') }</h4></Col>
                                <Col md="12"><Input name="vatNumber" placeholder={ t('register.companycheck.companynumber_label') } label={ t('register.companycheck.companynumber_label') } required className={classes.input} /></Col>  
                                <Col md="12"><Button variant="primary" type="submit" loading={(loadingToken || loading)} >{ t('register.companycheck.btn_send') }</Button></Col>
                            </Row>
                    }         
                </div>
            </Form>
   
        </>
    );
} 

export default CompanyCheck;