import Radio from '../../../../components/form/Radio';
import { TruckIcon } from '../../../../components/icons';

import classes from './ShipmentMethode.module.css';

const ShipmentMethode = ({ data: { name, code }, selected, onClick=() => {} }) => {
    return (
        <div className={classes.root} onClick={onClick.bind(this, code)}>
            <Radio name={code} className={classes.ckechbox} checked={selected} /> <TruckIcon size={30} className={classes.icon} /> { name }
        </div>
    )
}

export default ShipmentMethode;