export default ({ client, tokens }) => {

    const api = {};

    api.sendMail = async ({ recaptchaToken, payload }) => {

        const { token='' } = await tokens.get('public');

        return new Promise((resolve, reject) => {
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'recaptcha-response': recaptchaToken
            }

            client.post('/email/send', payload, headers).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });

    }

    return api;
};