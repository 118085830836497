import { Helmet } from 'react-helmet-async';

import { t } from '../../../lib/translations';

import { default as ShipmentModule } from '../../../modules/checkout/shipment/Shipment';

const Shipment = () => {

    return (
        <>
            <Helmet>
                <title>{ t('checkout.shipment.page_title') }</title>
                <meta name="description" content={''} />
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <ShipmentModule />

        </>
    )
}

export default Shipment;