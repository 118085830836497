import { forwardRef } from 'react';
import { Select as DefaultSelect } from '@kega/sps-elements';

import classNames from 'classnames';

import classes from './Form.module.css';

const Select = ({ className, ...props }, ref) => {
    const inputClassName = classNames(classes.input, className);

    return (
        <DefaultSelect ref={ref} className={inputClassName} {...props} />
    );
}

export default forwardRef(Select);