import IconWrapper from '../wrapper/IconWrapper';

const ProfileIcon = ({ size = 24, color = null, className = null, title = "account-icon" }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="21.526" height="20.45" viewBox="0 0 21.526 20.45">
                <title>{title}</title>
                <path d="M10.61,13.687C7.737,13.687,2,15.118,2,17.992v3.229H13.936c-.022-.151-.043-.291-.054-.441a3.1,3.1,0,0,1-.043-.635,5.688,5.688,0,0,1,.086-.969,6.689,6.689,0,0,1,.99-2.626,9.034,9.034,0,0,1,.7-.84l.022-.032a6.453,6.453,0,0,1,.818-.71,13.876,13.876,0,0,0-2.336-.8,15.13,15.13,0,0,0-3.509-.484m1.13,5.489h-7.7V17.992c0-.689,3.369-2.26,6.565-2.26a12.8,12.8,0,0,1,2.153.226,8.564,8.564,0,0,0-1.022,3.218M10.61,12.61A4.305,4.305,0,1,0,6.305,8.305,4.3,4.3,0,0,0,10.61,12.61m0-6.458A2.153,2.153,0,1,1,8.458,8.305,2.152,2.152,0,0,1,10.61,6.153M23.526,21.221,20.3,24.45l-3.229-3.229h2.153V16.916h2.153v4.305Z" transform="translate(-2 -4)" />
            </svg>
        </IconWrapper>
    );
}

export default ProfileIcon;


