import { useEffect, useState } from 'react';

import { ProductImage } from '@kega/sps-components';
import { useCartId, useCreateCart, useAddToCart, useUpdateCart, useConfig } from '@kega/sps-core';

import { t } from '../../lib/translations';

import { default as AddToCartButton } from '../../components/buttons/AddToCart';
import { CheckRoundIcon, MaxIcon, MinIcon } from '../../components/icons';

import useTagManager from '../../hooks/useTagManager';

import Button from '../../components/buttons/Button';
import Counter from "../../components/counter/Counter";

import useCartItem from '../../hooks/useCartItem';
import useUserToken from '../../hooks/useUserToken';
import useUserId from "../../hooks/useUserId";

import aa from 'search-insights'

import classes from './AddToCart.module.css';

const AddToCart = ({ product, onModalClose = () => { } }) => {
    const getCartId = useCartId();
    const getUserId = useUserId();
    const { createCart } = useCreateCart();
    const { addToCart, error } = useAddToCart();
    const { updateCart } = useUpdateCart();
    const { ecommerceEvent } = useTagManager();
    const { get } = useUserToken();
    const user = get();

    const config = useConfig();

    const storeCode = config.get('storeCode');
    const { index: algoliaIndex } = config.get('algolia');

    const cart_item = useCartItem(product ? product.code : null);

    const [loading, setLoading] = useState(false);
    const [quantity, setQuantity] = useState('');
    const [quantityError, setQuantityError] = useState('');

    const stockLevel = product?.stock?.stockLevel || 9999;
    const maxQuantity = stockLevel - (cart_item?.quantity || 0);
    const minQuantity = product?.minQuantity || product?.assortmentMinQuantity || 1;
    const productName = product?.commercialProductName || product?.name;
    const winePackagingUnit = product?.packagingUnit === 'fles';
    const { packagingUnit, contentUnit, numbercontentunits, priceUnit, nonfoodOrderUnitDescription, assortmentMinQuantity = 0 } = product;

    const packingInfo = `${packagingUnit || ''} ${numbercontentunits || ''} ${contentUnit || ''}`.trim()

    const onAddToCart = async (event) => {

        event.preventDefault();

        const newQuantity = Number(quantity);
        const updatedQuantity = cart_item ? (cart_item.quantity + newQuantity) : newQuantity;

        if ((Number(updatedQuantity) > stockLevel)) {
            setQuantityError(t('productdetails.addtocart.error_stock', { stockLevel }));
            return;
        } else if (Number(updatedQuantity) < minQuantity) {
            setQuantityError(t('productdetails.addtocart.error_min_qty'));
            return;
        } else if (Number(updatedQuantity) % minQuantity) {
            setQuantityError(t('productdetails.addtocart.error_order_qty'));
            return;
        } else {
            setQuantityError('');
        }

        setLoading(true);

        const cartId = getCartId();
        const userId = getUserId();

        if (cartId === null) {
            await createCart({ userId });
        }

        if (cart_item) {
            await updateCart({
                product: {
                    code: product.code
                },
                quantity: updatedQuantity,
                entryNumber: cart_item.entryNumber,
                userId: userId
            });
        } else {
            await addToCart({
                product: {
                    code: product.code
                },
                quantity: updatedQuantity,
                userId: userId
            });
        }

        aa('convertedObjectIDsAfterSearch', {
            userToken: 'user-' + user,
            eventName: 'PLP: Product Added to Cart',
            queryID: product.__queryID,
            index: algoliaIndex + '-product-' + storeCode,
            objectIDs: [product.objectID],
        });

        
        try {
            const { code, Merk, price } = product;

            ecommerceEvent('add_to_cart', {
                value: price?.value,
                currency: config.get('currency'),
                items:[{
                    item_id: code,
                    item_name: productName,
                    item_brand: Merk ?? product?.manufacturer,
                    price: price?.value
                }]
            });
        } catch (error) {
            console.log(error)
        }

        onModalClose();

        setLoading(false);
    };

    const onQuantityChange = (newValue) => {
        const newQuantity = Number(newValue);
        const updatedQuantity = cart_item ? (cart_item.quantity + newQuantity) : newQuantity;

        if ((Number(updatedQuantity) > stockLevel)) {
            setQuantityError(t('productdetails.addtocart.error_stock', { stockLevel }));
        } else if (Number(updatedQuantity) < minQuantity) {
            setQuantityError(t('productdetails.addtocart.error_min_qty'));
        } else if (Number(updatedQuantity) % minQuantity) {
            setQuantityError(t('productdetails.addtocart.error_order_qty'));
        } else {
            setQuantityError('');
        }

        setQuantity(newQuantity);
    };

    useEffect(() => {
        setQuantity(minQuantity);
    }, [minQuantity]);

    return (
        <div className={classes.root}>
            <h4>
                <CheckRoundIcon size={16} className={classes.icon} /> {t('addtocart.intro')}
            </h4>
            <div className={classes.form}>

                <div className={classes.product}>
                    <div className={classes.image}>
                        <ProductImage
                            src={(product.images[0] ? product.images[0].url : '/images/placeholder.png')}
                            width={256}
                            height={256}
                            alt={productName}
                        />
                    </div>

                    <div className={classes.details}>
                        <div>
                            {product?.Merk ?? product?.manufacturer}
                        </div>
                        <div className={classes.name}>
                            {productName}
                        </div>

                        <div className={classes.unit}>
                            <div className={classes.row}>
                                <span className={classes.label_info}>{t('productdetails.info.packing')}</span> <span className={classes.package_unit}> { packingInfo || nonfoodOrderUnitDescription || priceUnit } </span>
                            </div>
                            {
                                minQuantity > 0
                                &&
                                <div className={classes.row}>
                                    <span className={classes.label_info}>{t('productdetails.info.qty_min')} </span>  {`${minQuantity} ${minQuantity === 1 ? t('productdetails.piece') : t('productdetails.pieces')}`}
                                </div>
                            }
                        </div>
                        <div className={classes.qty}>
                            <span>{t('addtocart.qty')}</span>
                            <Counter
                                name="quantity"
                                value={maxQuantity < minQuantity ? 0 : quantity}
                                min={minQuantity}
                                max={maxQuantity}
                                step={minQuantity}
                                disabled={maxQuantity < minQuantity}
                                parentCallback={onQuantityChange}
                                autoAdjustQty={false}
                                minComponent={MinIcon}
                                maxComponent={MaxIcon}
                            />
                        </div>
                    </div>
                </div>

                <form onSubmit={onAddToCart} noValidate className={classes.buttons_form}>
                    <div className={classes.buttons}>
                        <AddToCartButton type="submit" disabled={!product} loading={loading}>{t('addtocart.order')}</AddToCartButton>
                        <Button variant="outline" onClick={() => onModalClose()}>{t('addtocart.continue_shopping')}</Button>
                    </div>
                </form>
            </div>
            <div className={classes.error}>
                {
                    error
                        &&
                        <>
                            {
                                error?.data?.errors
                                    ?
                                    <>
                                        {
                                            error.data.errors.map(({ message }, index) => {
                                                return <p key={'e'+index}>{ message }</p>
                                            })
                                        }
                                    </>
                                    :
                                    <p>{ error.message }</p>
                            }
                        </>
                }
                {
                    quantityError
                        &&
                        <p>{ t(quantityError) }</p>
                }
            </div>
        </div>
    );
}

export default AddToCart;