import { forwardRef } from 'react';
import { Input as DefaultInput } from '@kega/sps-elements';

import classNames from 'classnames';

import classes from './Form.module.css';

const Input = ({ className, ...props }, ref) => {
    const inputClassName = classNames(classes.input, className);

    return (
        <DefaultInput ref={ref} className={inputClassName} {...props} />
    );
}

export default forwardRef(Input);