import { t } from '../../../lib/translations';

import { EnergyLabelA, EnergyLabelB, EnergyLabelC, EnergyLabelD, EnergyLabelE, EnergyLabelF, EnergyLabelG, ShowroomModel, ShopOnly } from "./labels";

import classNames from 'classnames';
import classes from './ProductLabels.module.css';

const ProductLabels = ({ className, product }) => {
    const labels = [];
    const productLabelMapping = {
        'ENERGY_LABEL_A': EnergyLabelA,
        'ENERGY_LABEL_B': EnergyLabelB,
        'ENERGY_LABEL_C': EnergyLabelC,
        'ENERGY_LABEL_D': EnergyLabelD,
        'ENERGY_LABEL_E': EnergyLabelE,
        'ENERGY_LABEL_F': EnergyLabelF,
        'ENERGY_LABEL_G': EnergyLabelG,
        'SHOWROOM_MODEL': ShowroomModel,
        'HANOS_SHOP_ONLY': ShopOnly,
    };

    if (product?.Actie || product?.price?.promotion) {
        labels.push(<span className={classNames(classes.label, classes.promotion)}>{ t('productdetails.labels.promotion') }</span>)
    } else if (product?.hanosProductLabel && productLabelMapping.hasOwnProperty(product.hanosProductLabel)) {
        const ProductLabel = productLabelMapping[product.hanosProductLabel];
        labels.push(<ProductLabel className={classes.img_label}/>);
    }

    return (
        <ul className={classNames(classes.root, className)}>
            {
                labels.map((label, index) => {
                    return (
                        <li key={index}>
                            { label }
                        </li>
                    );
                })
            }
        </ul>
    );
}

export default ProductLabels;