import { NavLink } from 'react-router-dom';

import { useConfig } from '@kega/sps-core';

import useTranslatePath from '../../hooks/useTranslatePath';

const NavItem = ({ children, className, url_key, type, external, ...props }) => {
    const { getPath } = useTranslatePath();
    const config = useConfig();

    const storeCode = config.get('storeCode');

    let path = '';

    if (external) {
        path = url_key
    } else if (type === 'CATEGORY_PAGE') {
        path = getPath(url_key);
    } else if (type === 'PRODUCT_PAGE') {
        path = getPath(url_key);
    } else if (type === 'CONTENT_PAGE') {
        path = `/${storeCode}${url_key}`;
    } else if (type === 'STATIC_URL') {
        path = `/${storeCode}${url_key}`;
    }

    if (type === 'HEADING') { return <span className={className} {...props}>{ children }</span>; }

    return (
        <NavLink className={className} to={path} {...props}>
            { children }
        </NavLink>
    );
}

export default NavItem;