import { useMemo  } from 'react';

import { useCart } from '@kega/sps-core';

const useCartItem = (code) => {

    const { items } = useCart();

    const item = useMemo(() => {
        
        const item = items.find(({ product }) => product.code === code);
        
        return item || null;
    }, [code, items]);

    return item;
}

export default useCartItem;