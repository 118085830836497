import { Helmet } from 'react-helmet-async';

import { default as AccountModule } from '../../modules/account/Account';
import { t } from '../../lib/translations';

const Account = () => {
    
    return (
        <>
            <Helmet>
                <title>{ t('account.page_title') }</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <AccountModule />
        </>
    )
}

export default Account;