import { lazy, Suspense, useEffect } from 'react';
import { useCart, useCreateCart, useClearCart } from "@kega/sps-core";

import { Loader } from '@kega/sps-elements';

import useUserId from '../../../hooks/useUserId';

const AdyenForm = lazy(() => import('./AdyenForm'));

const Payment = () => {
    const { createCart } = useCreateCart();
    const { fetchCart } = useCart();

    const getUserId = useUserId();
    const clearCart = useClearCart();

    useEffect(() => {

        const unlockCard = async () => {
            try {
                try {

                    const userId = getUserId();
                    if (userId === 'anonymous') {
                        clearCart();
                    } else {
    
                        // Create new cart
                        await createCart({ 
                            userId: userId,
                        });
    
                        await fetchCart('current');

                    }
    
                } catch (error) {
                    console.log(error);
                }

            } catch (error) {
                console.log('getCurrentCart', error);
            }
        }

        return () => {
            unlockCard();
        }
    }, []);

    return (
        <div>
            <Suspense fallback={<Loader variant="primary" />}>
                <AdyenForm />
            </Suspense>
        </div>
    )
}

export default Payment;