import { useMemo } from 'react';
import { useConfig } from '@kega/sps-core';
import { useFrequentlyBoughtTogether } from '@algolia/recommend-react';

import recommend from '@algolia/recommend';

import { t } from '../../../lib/translations';
import ProductSlider from '../../../modules/sliders/ProductSlider';

import useAlgoliaProductData from "../../../hooks/useAlgoliaProductData";

const FrequentlyBoughtTogether = ({ code }) => {
    const config = useConfig();
    const { index, appId, apiKey } = config.get('algolia');
    const storeCode = config.get('storeCode');

    const recommendClient = useMemo(() => recommend(appId, apiKey), [appId, apiKey]);

    const { parseProduct } = useAlgoliaProductData();
    const { recommendations } = useFrequentlyBoughtTogether({
        recommendClient,
        indexName: index + '-product-' + storeCode,
        objectIDs: Array.isArray(code) ? code : [code],
    });
    
    const products = useMemo(() => {

        return recommendations.reduce((products, product) => {
            products.push(parseProduct(product));

            return products;
        }, []);

    }, [recommendations]);

    if (products.length === 0) { return null; }

    return (
        <>
            <h2>{t('productdetails.frequently_products')}</h2>
            <ProductSlider products={products} />
        </>
    )
}



export default FrequentlyBoughtTogether;