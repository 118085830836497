import { useEffect } from 'react';

import { Helmet } from 'react-helmet-async';

import { t } from '../../../lib/translations';

import useRegister from '../../../hooks/useRegister';
import useTranslatePath from '../../../hooks/useTranslatePath';

import BuilderSectionLazy from '../../../modules/builder/BuilderSectionLazy';

const RegisterSuccess = () => {
    const { clear } = useRegister();
    const { getPath } = useTranslatePath();

    useEffect(() => {
        clear();
    }, [clear]);

    return (
        <>
            <Helmet>
                <title>{ t('register.success.page_title') }</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <BuilderSectionLazy model="success-page" url={getPath('/register/success')} />
        </>
    )
}

export default RegisterSuccess;