import IconWrapper from '../wrapper/IconWrapper';

const AddRoundIcon = ({ size = 24, color = null, direction='top', className = null }) => {
    return (
        <IconWrapper size={size} color={color} direction={direction} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16.936" height="16.936" viewBox="0 0 16.936 16.936">
                <path d="M12.532,16.7a.615.615,0,0,0,.635-.635V13.188h2.922a.591.591,0,0,0,.434-.18.635.635,0,0,0-.455-1.09h-2.9V8.848a.591.591,0,0,0-.18-.434.635.635,0,0,0-1.09.455v3.049H8.848a.591.591,0,0,0-.434.18.635.635,0,0,0,.455,1.09H11.9v2.9a.591.591,0,0,0,.18.434A.617.617,0,0,0,12.532,16.7Zm-.064,4.234a8.589,8.589,0,0,1-3.345-.646,8.2,8.2,0,0,1-4.478-4.478A8.589,8.589,0,0,1,4,12.468a8.534,8.534,0,0,1,.646-3.324A8.265,8.265,0,0,1,6.435,6.456,8.442,8.442,0,0,1,12.468,4a8.4,8.4,0,0,1,8.468,8.468A8.442,8.442,0,0,1,18.481,18.5a8.265,8.265,0,0,1-2.689,1.789A8.534,8.534,0,0,1,12.468,20.936Z" transform="translate(-4 -4)"/>
            </svg>
        </IconWrapper>
    );
}

export default AddRoundIcon;