import IconWrapper from '../wrapper/IconWrapper';

const MaxIcon = ({ size = 24, color = null, direction='top', className = null }) => {
    return (
        <IconWrapper size={size} color={color} direction={direction} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path id="Path_451" data-name="Path 451" d="M0,0H24V24H0Z" fill="none"/>
                <path id="Path_452" data-name="Path 452" d="M19,13H13v6H11V13H5V11h6V5h2v6h6Z" fill="currentColor"/>
            </svg>
        </IconWrapper>
    );
}

export default MaxIcon;