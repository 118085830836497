const EnergyLabelB = ({ className }) => {
    return (
        <div className={className}>
            <svg id="Laag_2" data-name="Laag 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128.28 60">
                <title>Energy Label B</title>
                <g id="Laag_1-2" data-name="Laag 1">
                    <g>
                        <polygon strokeWidth="0" fill="#32a936" points="100.77 0 0 0 0 60 100.77 60 128.28 30 100.77 0"/>
                        <path strokeWidth="0" fill="#fff" d="m36.95,16.35c6.11,0,9.35,3.24,9.35,7.5,0,3.03-1.84,5.41-4.39,6.11,2.62.61,5.08,2.58,5.08,6.56,0,4.26-3.12,7.46-8.65,7.46h-11.19v-27.63h9.8Zm-4.63,11.64h3.12c3.9,0,5.58-1.8,5.58-3.94s-1.44-3.48-4.71-3.48h-3.98v7.42Zm4.43,11.77c3.32,0,4.96-1.31,4.96-3.77,0-2.25-1.48-3.85-5.17-3.85h-4.22v7.63h4.43Z"/>
                    </g>
                </g>
            </svg>
        </div>
    )
};

export default EnergyLabelB;
