import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
    lastOrderId: null
};

export default createReducer(initialState, {

    [actions.setLastOrderId]: (state, action) => {
        const { payload } = action;

        return {
            ...state,
            lastOrderId: payload
        }
    },

    [actions.clearCheckoutData]: () => {
        return initialState;
    }

});
