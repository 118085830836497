import classNames from "classnames";

import classes from './AnchorScrollItem.module.css';

const AnchorScrollItem = ({ label, scrollToId, className }) => {
    const scrollTo = () => {
        const element = document.getElementById(scrollToId);
        if (element) {
            const headerOffset = document.querySelector("header").offsetHeight + 20;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }
    return (
        <div className={classNames(classes.root, className)} onClick={scrollTo}>
            {label}
        </div>
    );
}

export default AnchorScrollItem;