import IconWrapper from '../wrapper/IconWrapper';

const WishlistIconFilled = ({ size = 24, color = null, className = null, title = "verlanglijst-gevuld-icon" }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="21" height="21" viewBox="0 0 21 21">
                <title>{title}</title>
                <path id="heart" d="M12,21.35l-1.45-1.32C5.4,15.36,2,12.27,2,8.5A5.44,5.44,0,0,1,7.5,3,6.014,6.014,0,0,1,12,5.08,6.014,6.014,0,0,1,16.5,3,5.44,5.44,0,0,1,22,8.5c0,3.77-3.4,6.86-8.55,11.53Z" transform="translate(-1 -2)"/>
            </svg>
        </IconWrapper>
    );
}

export default WishlistIconFilled;
