import { Helmet } from 'react-helmet-async';

import { default as WishlistModule } from '../../../modules/account/wishlist/Wishlist';
import { t } from "../../../lib/translations";

const Wishlist = () => {
    return(
        <>
            <Helmet>
                <title>{ t('account.wishlist.page_title') }</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <WishlistModule />
        </>
    )
}

export default Wishlist;