import { useDispatch } from 'react-redux';
import { useRequest, useStorefront } from '@kega/sps-core';

import { SetWishlistItems } from '../../redux/wishlist/actions';

const useAddToWishlist = () => {
    const dispatch = useDispatch();

    const { api: { occwishlist } } = useStorefront();
    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const addToWishlist = async ({ code }) => {

        try {
            setRequest();

            const result = await occwishlist.addProductToWishlist({
                productCode: code
            });

            if (result) {
                dispatch(SetWishlistItems(result));
            }

            setSuccess();
        } catch (error) {
            console.log('[AddToWishlist]', error);
            setError(error);
        }
    };

    return {
        error,
        loading,
        addToWishlist
    };
};

export default useAddToWishlist;