import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
    items: []
};

export default createReducer(initialState, {
    [actions.SetWishlistItems]: (state, action) => {
        const { payload } = action;

        return {
            items: {
                ...payload
            }
        };
    },

});
