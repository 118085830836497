import { Drawer } from '@kega/sps-elements';
import { Stats } from 'react-instantsearch-dom';

import { t } from '../../../lib/translations';
import useUi from '../../../hooks/useUi';
import { CloseIcon } from '../../../components/icons';
import Button from '../../../components/buttons/Button';

import Filters from './Filters';

import classes from './Filters.module.css';

const MobileFilters = (props) => {

    const { isFiltersOpen, closeFilters } = useUi();

    return (
        <Drawer open={isFiltersOpen} onClose={closeFilters} direction='left' portal={false}>
            
            <header className={classes.header}>
                { t('productlist.filter.mobile_header') }
                <CloseIcon className={classes.close} onClick={closeFilters}/>
            </header>

            <Filters {...props} />
            
            <footer className={classes.footer}>
                <Button variant="outline" onClick={closeFilters}><Stats translations={{ stats: (nbHits) => `Bekijk ${nbHits} resultaten` }} /></Button>
            </footer>

        </Drawer>
    );
}

export default MobileFilters;