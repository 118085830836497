import useProductStructuredData from '../../../hooks/useProductStructuredData';

const StructuredData = ({ product }) => {
    const productData = useProductStructuredData(product);

    const data = {
        "@context": "https://schema.org/",
        ...(productData)
    };

    return (
        <>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }} />
        </>
    );
}
 
export default StructuredData;