import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
    mode: null,
    user: null,
    company: null,
    companies: null
};

export default createReducer(initialState, {

    [actions.setCompanies]: (state, action) => {
        state.companies = action.payload;
    },

    [actions.setCompany]: (state, action) => {
        state.company = action.payload;
    },

    [actions.setUser]: (state, action) => {
        state.user = action.payload;
    },

    [actions.setMode]: (state, action) => {
        state.mode = action.payload;
    },

    [actions.clear]: (state, action) => {
        return {
            ...initialState,
            mode: state.mode
        };
    },

});