import { Helmet } from 'react-helmet-async';

import { t } from '../../../lib/translations';

import { default as SummaryModule } from '../../../modules/checkout/summary/Summary';

const Summary = () => {
    return (
        <>
            <Helmet>
                <title>{ t('checkout.summery.page_title') }</title>
                <meta name="description" content={''} />
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <SummaryModule />
        </>
    )
}

export default Summary;