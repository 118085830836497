import { t } from '../../../lib/translations';
import ReadMore from '../../../components/readmore/ReadMore';

import classes from "./Classifications.module.css";

const parseValues = (featureValues, featureUnit) => {
    return featureValues.reduce((parsedvalue, { value }) => {
        parsedvalue += (parsedvalue.length > 0 ? ',  ' : '') + value + (featureUnit ? featureUnit.symbol + ' ' : '');
        return parsedvalue;
    }, '');
}

const Classifications = ({ product: { code, manufacturer , classifications = [], classificationsNoName = [] } }) => {

    return (
        <div className={classes.root} id="classifications">
            <ReadMore height={500} background="rgba(249, 249, 246, 1)">
                <h2>{t('productdetails.classifications_label')}</h2>

                <div className={classes.feature}>
                    <div className={classes.line}><strong>{t('productdetails.brand')}</strong> {manufacturer}</div>
                    <div className={classes.line}><strong>{t('productdetails.sku')}</strong> {code}</div>
                </div>

                {
                    classifications.map(({ code, name, features }) => {
                        return (
                            <div key={code} className={classes.feature}>
                                <h4>{name}</h4>
                                {
                                    features.map(({ code, name, featureValues, featureUnit }) => {
                                        return (
                                            <div key={code} className={classes.line}><strong>{name}</strong> {parseValues(featureValues, featureUnit)}</div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </ReadMore>
        </div>
    )
}

export default Classifications;