
class Translations {

    constructor() {
        this.language = 'nl';
        this.data = [];
    }

    setLanguage = async (language) => {
        this.language = language;
        
        try {
            const translation = await import(
                /* webpackChunkName: "translations" */
                "./"+language+".json");

            this.data = this.flatten(translation.default);
        } catch (error) {
            /* Empty */
        }
 

    }

    flatten = (obj, prefix = '') => {
        return Object.keys(obj).reduce((translations, k) => {
    
            const pre = prefix.length ? prefix + '.' : '';
    
            if (typeof obj[k] === 'object') { 
                Object.assign(translations, this.flatten(obj[k], pre + k))
            } else { 
                translations[pre + k] = obj[k];
            }
    
            return translations;
    
        }, {});
    }

    get = (key, params = null, lang = this.language) => {

        if (this.data.hasOwnProperty(key)) {
            
            let text = this.data[key];

            if (params) {
                Object.keys(params).forEach((key) => {
                    text = text.replace(new RegExp(`{{${key}}}`, 'g'), params[key]);
                });
                return text;
            } else {
                return text;
            }
            
        } else {
            return key;
        }
    }

}

const translations = new Translations();

const setLanguage = async (lang) => { return translations.setLanguage(lang); }
const t = (key, params = null) => { return translations.get(key, params); }

export {
    setLanguage,
    t
}