import classes from './SliderItem.module.css';

import classNames from 'classnames';

const SliderItem = ({ children, className=null }) => {

    return (
        <div className={classNames(classes.root, className)}>
            { children }
        </div>
    )
}

export default SliderItem;