import { useEffect, useState } from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';
import { Form } from '@kega/sps-elements';
import { useConfig } from '@kega/sps-core';

import { Container, Row, Col } from '../grid';
import { CookieIcon } from '../icons';

import { addCookieConsent } from '../../redux/cookieconsent/actions';
import { t } from '../../lib/translations';

import useTagManager from '../../hooks/useTagManager';

import Portal from '../portal/Portal';
import Button from '../buttons/Button';
import Checkbox from '../form/Checkbox';

import classes from './CookieConsent.module.css';

const CookieConsent = () => {
    const dispatch = useDispatch();
    const store = useStore();
    const config = useConfig();

    const { initGTM, updateConcent } = useTagManager();

    const { cookie_consent, revise } = useSelector((state) => state.cookieconsent);

    const { tagmanagerkey } = config.get('google');

    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        const state = store.getState();
        
        if (tagmanagerkey) {
            initGTM({
                id: tagmanagerkey,
                initialConsent: state.cookieconsent.permissions
            });
        }
        
    }, [store]);
    
    if (cookie_consent !== null && revise) { return null; }

    const onSubmit = async ({ values }) => {

        const permissions = Object.keys(values).reduce((permissions, key) => {
            const permission = values[key];

            permissions[key] = (permission ? 'granted' : 'denied')
            return permissions;
        }, {});

        updateConcent(permissions);
        
        dispatch(addCookieConsent({ 
            consent: true,
            revise: true,
            permissions: permissions
        }));
    
    }

    return (
        <>
            <Portal>
             
                <div className={classes.root}>
                    <Form onSubmit={onSubmit}>
                        <Container xl margins g-xs={2} className={classes.cookie_container}>
                            <Row>
                                <Col md={12}>
                                    <div className={classes.text}>
                                        <div className={classes.title_wrapper}>
                                            <CookieIcon size="30" color="black" />
                                            <h3 className={classes.title}>{ t('cookieconsent.title') }</h3>
                                        </div>

                                        {
                                            showMore
                                                ?
                                                <p>
                                                    { t('cookieconsent.text') }
                                                    <span className={classes.showtextlink} onClick={() => setShowMore(false)}>{ t('cookieconsent.show_less') }</span>
                                                </p>
                                                :
                                                <p>
                                                    { t('cookieconsent.text_short') }
                                                    <span className={classes.showtextlink} onClick={() => setShowMore(true)}>{ t('cookieconsent.show_more') }</span>
                                                </p>
                                        }
                                    </div>
                                </Col>

                                <Col md={10} xs={12} className={classes.checkbox_wrapper}>
                                    <Checkbox name="functionality_storage" label={ t('cookieconsent.functionality_storage') } checked={true} disabled={true} rootClassName={classes.checkbox}/>
                                    <Checkbox name="personalization_storage" label={ t('cookieconsent.personalization_storage') } checked={true} rootClassName={classes.checkbox}/>
                                    <Checkbox name="analytics_storage" label={ t('cookieconsent.analytics_storage') } checked={true} rootClassName={classes.checkbox}/>
                                    <Checkbox name="ad_storage" label={ t('cookieconsent.ad_storage') } checked={true}/>
                                </Col>

                                <Col md={2} xs={12}>
                                    <div className={classes.buttons_wrapper}>
                                        <Button type="submit">
                                            <span>{ t('cookieconsent.accept') }</span>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                </div>
        
            </Portal>
        </>
    );
}

export default CookieConsent;