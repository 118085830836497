import { Helmet } from 'react-helmet-async';

import { t } from '../../../../lib/translations';

import PaymentRedirect from '../../../../modules/checkout/payment/redirect/PaymentRedirect';

const Redirect = () => {
    return (
        <>
            <Helmet>
                <title>{ t('checkout.payment.page_title') }</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <PaymentRedirect />
        </>
    )
}

export default Redirect;