import IconWrapper from '../wrapper/IconWrapper';

const WishlistIcon = ({ size = 24, color = null, className = null, title = "verlanglijst-icon" }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="21" height="21" viewBox="0 0 21 21">
                <title>{title}</title>
                <path id="Path_1" data-name="Path 1" d="M0,0H21V21H0Z" fill="none"/>
                <path id="Path_2" data-name="Path 2" d="M14.707,3a5.246,5.246,0,0,0-3.944,1.833A5.246,5.246,0,0,0,6.82,3,4.775,4.775,0,0,0,2,7.824c0,3.316,2.98,6.017,7.493,10.122L10.764,19.1l1.271-1.158c4.513-4.1,7.493-6.8,7.493-10.113A4.775,4.775,0,0,0,14.707,3ZM10.851,16.639l-.088.088-.088-.088c-4.172-3.78-6.923-6.28-6.923-8.815A3,3,0,0,1,6.82,4.754a3.426,3.426,0,0,1,3.129,2.07h1.639a3.4,3.4,0,0,1,3.12-2.07,3,3,0,0,1,3.067,3.07C17.775,10.359,15.023,12.859,10.851,16.639Z" transform="translate(-0.264 -0.396)" />
            </svg>
        </IconWrapper>
    );
}

export default WishlistIcon;


