import { Helmet } from 'react-helmet-async';

import { default as EditPasswordModule } from '../../../../modules/account/profile/editpassword/EditPassword';
import { t } from '../../../../lib/translations';

const EditPassword = () => {
    return (
        <>
            <Helmet>
                <title>{ t('account.edit_password.page_title') }</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <EditPasswordModule />
        </>
    )
}

export default EditPassword;