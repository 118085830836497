import { useConfig, useRequest, useStorefront } from '@kega/sps-core';

const useProductReviewSummary = () => {
    const { api: { trustpilot } } = useStorefront();
    const { data, error, loading, setRequest, setSuccess, setError } = useRequest();
    const config = useConfig();
    const { apiKey, businessUnitId } = config.get('trustPilot') || {};

    const getReviewSummary = async (sku) => {
        try {
            setRequest();

            const result = await trustpilot.getProductReviewSummary({ sku, apiKey, businessUnitId });

            setSuccess(result);

            return result;

        } catch (error) {
            console.log('[useProductReviewSummary] ', error);
            setError(error);

            return null;
        }

    };

    return {
        summary: data,
        error,
        loading,
        getReviewSummary
    };
}

export default useProductReviewSummary;
