import { Helmet } from 'react-helmet-async';

import { default as ProfileModule } from '../../../modules/account/profile/Profile';
import { t } from '../../../lib/translations';

const Profile = () => {
    return (
        <>
            <Helmet>
                <title>{ t('account.profile.page_title') }</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <ProfileModule />
        </>
    )
}

export default Profile;