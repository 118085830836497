import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
    user: null
};

export default createReducer(initialState, {

    [actions.addUser]: (state, action) => {
        const { payload = [] } = action;
        state.user = payload;
    }

});
