import { Form } from '@kega/sps-elements';
import { useAddAddresses, useAddresses, useConfig } from '@kega/sps-core';

import { t } from '../../../../lib/translations';
import { Col, Row, Container } from '../../../../components/grid';
import { CloseIcon } from '../../../../components/icons';

import useInputModifiers from "../../../../hooks/form/useInputModifiers";

import Input from '../../../../components/form/Input';
import Radio from '../../../../components/form/Radio';
import Button from '../../../../components/buttons/Button';
import ErrorMessage from '../../../../components/messages/ErrorMessage';

import classes from './AddAddress.module.css';

const AddAddress = ({ closeModal }) => {
    const config = useConfig();
    const { addAddresses, loading: loadingAddAddress, error } = useAddAddresses();
    const { fetchAddresses, loading: loadingAddresses  } = useAddresses();
    const { zipcode } = useInputModifiers();
 
    const onSubmit = async ({ valid, values, resetForm }) => {

        if (valid) {
            const { titleCode='', firstname, middlename, lastname, city, postalcode, street, housenumber, houseNumberExtension, phonenumber='', email='' } = values;
            const processesPostalCode = zipcode(postalcode);

            await addAddresses({
                shippingAddress: true,
                country: {
                    isocode: String(config.get('country')).toUpperCase()
                },
                email: email,
                firstName: firstname,
                middleName: middlename,
                lastName: lastname,
                line1: street,
                line2: housenumber,
                houseNumberExtension: houseNumberExtension,
                phone: phonenumber,
                postalCode: processesPostalCode,
                titleCode: "",
                town: city,
                visibleInAddressBook: true
            });

            await fetchAddresses();

            resetForm();

            closeModal();
        }
    }

    return (
        <div className={classes.root}>
            <CloseIcon className={classes.close} onClick={closeModal}/>
            <Container>
                <Form onSubmit={onSubmit}>
                    <Row>
                        <Col md="12">
                            <h4>{ t('checkout.addaddress.header') }</h4>
                            {
                                (error)
                                &&
                                <ErrorMessage>
                                    { error && <span>{ error.message }</span> }
                                </ErrorMessage>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <Radio
                                id="male"
                                name="titleCode"
                                label={ t('checkout.addaddress.title_male_label') }
                                value='m'
                                rootClassName={classes.radio}
                            />
                            <Radio
                                id="female"
                                name="titleCode"
                                label={ t('checkout.addaddress.title_female_label') }
                                value='v'
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6"><Input name="firstname" type="text" placeholder={ t('form.firstname_label') } label={ t('form.firstname_label') } required /></Col>
                        <Col md="6"><Input name="middlename" type="text" placeholder={ t('form.middlename_label') } label={ t('form.middlename_label') } /></Col>
                    </Row>
                    <Row>
                        <Col md="12"><Input name="lastname" type="text" placeholder={ t('form.lastname_label') } label={ t('form.lastname_label') } required /></Col>
                    </Row>
                    <Row>
                        <Col md="6"><Input name="city" type="text" placeholder={ t('form.city_label') } label={ t('form.city_label') } required /></Col>
                        <Col md="6"><Input name="postalcode" type="text" placeholder={ t('form.postalcode_label') } label={ t('form.postalcode_label') } required /></Col>
                    </Row>
                    <Row>
                        <Col md="6"><Input name="street" type="text" placeholder={ t('form.street_label') } label={ t('form.street_label') } required /></Col>
                        <Col md="4"><Input name="housenumber" type="text" placeholder={ t('form.housenumber_label') } label={ t('form.housenumber_label') } required /></Col>
                        <Col md="2"><Input name="houseNumberExtension" type="text" placeholder={ t('form.houseNumberExtension_placeholder') } label={ t('form.houseNumberExtension_label') } /></Col>
                    </Row>
                    <Row>
                        <Col md="6"><Input name="phonenumber" type="tel" placeholder={ t('form.phonenumber_placeholder') } label={ t('form.phonenumber_label') } /> <span className={classes.bottom_label}>{ t('checkout.addaddress.phonenumber_extra_label') }</span></Col>
                        <Col md="6"><Input name="email" type="text" placeholder={ t('form.email_label') } label={ t('form.email_label') } /></Col>
                    </Row>
             
                    <Row>
                        <Col md="12">
                            <Button
                                type="submit"
                                variant="secondary"
                                loading={(loadingAddAddress || loadingAddresses)}
                            >
                                { t('checkout.addaddress.btn_send') }
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </div>
    );
}

export default AddAddress;