const EnergyLabelD = ({ className }) => {
    return (
        <div className={className}>
            <svg id="Laag_2" data-name="Laag 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128.28 60">
                <title>Energy Label D</title>
                <g id="Laag_1-2" data-name="Laag 1">
                    <g>
                        <polygon strokeWidth="0" fill="#ffeb00" points="100.77 0 0 0 0 60 100.77 60 128.28 30 100.77 0"/>
                        <path strokeWidth="0" fill="#fff" d="m36.17,16.35c8.32,0,14.06,5.78,14.06,13.45,0,8.4-5.37,14.19-14.06,14.19h-9.02v-27.63h9.02Zm-.08,23.33c5.94,0,8.86-4.06,8.86-9.88,0-5.17-3.28-9.1-8.94-9.1h-3.57v18.98h3.65Z"/>
                    </g>
                </g>
            </svg>
        </div>
    )
};

export default EnergyLabelD;
