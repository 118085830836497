import React, { useEffect } from 'react';

const TrustboxWidget = ({ className, mini = false }) => {
    useEffect(() => {
        window?.Trustpilot?.loadFromElement(document.getElementById('trustpilot-widget'));
    }, []);

    const templateID = mini ? "53aa8807dec7e10d38f59f32" : "5419b732fbfb950b10de65e5"
    const height = mini ? "154px" : "24px"

    return (
        <div
            id="trustpilot-widget"
            className={className}
            data-locale="nl-NL"
            data-template-id={templateID}
            data-businessunit-id="625053d4a2277272a56a2735"
            data-theme="light"
            data-style-height={height}
            // data-style-width="100%"
        >
            <a href="https://nl.trustpilot.com/review/hanosshop.com" target="_blank" rel="noopener noreferrer" >
                Trustpilot
            </a>
        </div>
    );
};

export default TrustboxWidget;
