import { useStorefront, useRequest } from '@kega/sps-core';

const useFetchSubscriptionStatus = () => {
    const { api: { occnewsletter } } = useStorefront();
    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const fetchSubscriptionStatus = async () => {
        setRequest();
        try {
            const result = await occnewsletter.getSubscriptionStatus();
            setSuccess();
            return result;
        } catch (error) {
            console.error('[FetchSubscriptionStatus]', error);
            setError(error);
            return null;
        }
    };

    return {
        error,
        loading,
        fetchSubscriptionStatus
    };
};

export default useFetchSubscriptionStatus;
