import { useEffect } from "react";
import { useCreateCart, useCart, useClearCart } from "@kega/sps-core";

import { useLocation } from 'react-router-dom';

import { useStore } from 'react-redux';

import useUserId from "./useUserId";

const useCheckLockedCart = () => {
    const { createCart } = useCreateCart();
    const { fetchCart } = useCart();

    const { pathname } = useLocation();

    const clearCart = useClearCart();
    const getUserId = useUserId();
    const store = useStore();

    useEffect(() => {
        const { cart } = store.getState();

        const unlockCard = async () => {

            try {

                const userId = getUserId();
                if (userId === 'anonymous') {
                    clearCart();
                } else {

                    // Create new cart
                    await createCart({ 
                        userId: userId,
                    });

                    await fetchCart('current');

                }

            } catch (error) {
                console.log(error);
            }

        }

        if (!pathname.includes('checkout') && cart?.hasOngoingPaymentSession) {
            unlockCard()
        }
        
    }, []);
    
    return null;
}

export default useCheckLockedCart;
