import { Link } from 'react-router-dom';
import { ProductImage, Price } from '@kega/sps-components';
import { useConfig, useUpdateCart, useRemoveFromCart } from '@kega/sps-core';
import { Loader } from '@kega/sps-elements';

import Counter from '../../../components/counter/Counter';
import { MinIcon, MaxIcon } from '../../../components/icons';

import { t } from '../../../lib/translations';

import useTagManager from '../../../hooks/useTagManager';
import useUserId from "../../../hooks/useUserId";
import useItemCategories from '../../../hooks/useItemCategories';

import classes from './CartItem.module.css';

const CartItem = ({ product, totalPrice, quantity, entryNumber, coupons = [], totalPriceWithoutCouponDiscount }) => {
    const { updateCart, loading: loadingUpdate } = useUpdateCart();
    const { removeItem, loading: loadingRemove } = useRemoveFromCart();
    const { ecommerceEvent } = useTagManager();

    const getUserId = useUserId();
    const config = useConfig();
    const locale = config.get('locale');
    const currency = config.get('currency');
    const storeCode = config.get('storeCode');

    const { code, name, categoryPath, stock = {}, images = [], Merk, numbercontentunits, contentUnit, packagingUnit, price, assortmentMessage, assortmentMinQuantity, orderableAums } = product;
    const stockLevel = stock.stockLevel || 9999;
    const minQuantity = assortmentMinQuantity || 1;
    const productName = product?.formattedName || product?.name;
    const itemCategories = useItemCategories(categoryPath);

    const onQtyChange = (value) => {
        updateCart({
            product: { code: code },
            quantity: value,
            entryNumber: entryNumber,
            userId: getUserId()
        });
    };

    const onRemove = () => {

        try {

            ecommerceEvent('remove_from_cart', {
                value: price?.value,
                currency: currency,
                items:[{
                    item_id: code,
                    item_name: productName,
                    item_brand: Merk ?? product?.manufacturer,
                    price: price?.value,
                    item_category: itemCategories[0],
                    item_category2: itemCategories[1],
                    item_category3: itemCategories[2],
                    item_category4: itemCategories[3],
                    item_category5: itemCategories[4],
                }]
            });
        } catch (error) {
            console.log(error)
        }

        removeItem({
            entryNumber: entryNumber,
            userId: getUserId()
        });

    }

    return (
        <div className={classes.root}>

            <div className={classes.image}>
                <ProductImage
                    src={(images[0] ? images[0].url : '/images/placeholder.png')}
                    width={256}
                    height={256}
                    alt={name}
                />
            </div>

            <div className={classes.details}>

                <div>
                    {Merk ?? product.manufacturer}
                </div>

                <div className={classes.name}>
                    <Link
                        to={`/${storeCode}${product?.url}`}
                        className={classes.product_link}
                    >
                        {productName}
                    </Link>
                </div>

                <div className={classes.unit}>
                    <strong className={classes.unit_label}>{ t('checkout.cart.item.packingunit') }</strong> { packagingUnit } { numbercontentunits } { contentUnit }
                </div>

                <div className={classes.remove_quantity_delivery}>
                    <div className={classes.remove_container}><button className={classes.remove} onClick={onRemove}>{ t('checkout.cart.item.remove') }</button></div>
                    <div className={classes.quantity}>
                        <Counter
                            name="quantity"
                            value={quantity}
                            min={minQuantity}
                            max={stockLevel}
                            step={minQuantity}
                            parentCallback={onQtyChange}
                            trigger="blur"
                            minComponent={MinIcon}
                            maxComponent={MaxIcon}
                        />

                    </div>
                    
                    {
                        assortmentMessage !== ''
                        &&
                        <div className={classes.delivery}>{ assortmentMessage }</div>
                    }
           
                </div>

                {
                    coupons.length > 0 && <div className={classes.valid_coupon}>{ t('checkout.cart.item.valid_coupon') }</div>
                }

            </div>

            <div className={classes.price}>
                {totalPriceWithoutCouponDiscount?.value !== 0
                && coupons.length > 0
                && 
                   <div className={classes.totalPriceWithoutCouponDiscount}>
                       <Price
                           price={totalPriceWithoutCouponDiscount?.value}
                           currency={currency}
                           locale={locale}
                           currencySymbol={false}
                       />
                   </div>
                
                }
                <Price
                    price={totalPrice?.value}
                    currency={currency}
                    locale={locale}
                    currencySymbol={false}
                />
                
            </div>

            {loadingUpdate || loadingRemove ? <div className={classes.loading}><Loader variant="primary" /></div> : null}

        </div>
    );
}

export default CartItem;