import { Fragment, useMemo } from 'react';

import { ChevronIcon } from '../icons';
import NavItem from '../navigation/NavItem';

import classes from './Breadcrumb.module.css';

const Breadcrumb = ({ paths = [], prefix = [], sufix = [] }) => {

    const all_paths = useMemo(() => {
        if (paths === null) { return []; }

        return [
            ...prefix,
            ...paths,
            ...sufix
        ]
    }, [paths, prefix, sufix]);

    return (
        <div className={classes.root}>
            {
                all_paths.map(({ name, url_key, type = 'STATIC_URL' }, index) => {
                    return (
                        <Fragment key={"b" + index}>
                            {index !== 0 && <span className={classes.divider}><ChevronIcon size="8" direction="right" /></span>}
                            {
                                url_key
                                    ?
                                    <NavItem url_key={url_key} type={type} className={classes.link}>{name}</NavItem>
                                    :
                                    <span className={classes.link}>{name}</span>
                            }
                        </Fragment>
                    )
                })
            }
        </div>
    );
}

export default Breadcrumb;