import { useEffect, useState, useRef } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Form } from '@kega/sps-elements';
import { useConfig } from '@kega/sps-core';

import { Col, Row } from '../../../components/grid';
import { t } from '../../../lib/translations';

import useTranslatePath from '../../../hooks/useTranslatePath';
import useRegister from '../../../hooks/useRegister';
import useOccRegister from "../../../hooks/useOccRegister";
import useInputValidation from '../../../hooks/form/useInputValidation';

import ErrorMessage from '../../../components/messages/ErrorMessage';

import Input from '../../../components/form/Input';
import Button from '../../../components/buttons/Button';
import Checkbox from '../../../components/form/Checkbox';

import classes from './UserDetails.module.css';

const UserDetails = () => {
    const config = useConfig();
    const navigate = useNavigate();
    const { setBreadcrumb  } = useOutletContext();

    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const { loading, error, validateLoginData, register } = useOccRegister();

    const { user, company, addUser, addCompany } = useRegister();
    const { zipcode } = useInputValidation();

    const { getPath } = useTranslatePath();

    const emailRef = useRef(null);
    const emailRepeatRef = useRef(null);

    const passwordRef = useRef(null);
    const passwordRepeatRef = useRef(null);

    const countryCode = String(config.get('country')).toUpperCase();

    useEffect(() => {
        if (company === null) {
            navigate(getPath('/register/companycheck'));
        }
    }, [company])

    useEffect(() => {
        setBreadcrumb([
            { name: 'Home', url_key: '/' },
            { name: 'Registratie', url_key: '/register/companycheck' },
            { name: t('register.userdetails.header') }
        ]);
    }, []);

    const { tradeName, city, houseNumber, houseNumberExtension = '', postalCode, streetName } = company ?? {};
    const { email, email_repeat, firstname, lastname, password, password_repeat, phonenumber } = user ?? {};

    const onSubmit = async ({ values }) => {
        const { email, email_repeat, password, password_repeat } = values;

        const userData = {
            email: email,
            password: password,
            firstname: values?.firstname,
            lastname: values?.lastname,
            phonenumber: values?.phonenumber,
            newsletter: values?.newsletter,
        };

        const companyData = {
            tradeName: values?.tradeName,
            city: values?.city,
            houseNumber: values?.houseNumber,
            houseNumberExtension: values?.houseNumberExtension || '',
            postalCode: values?.postalCode,
            streetName: values?.streetName,
            dossierNumber: values?.dossierNumber,
            vatNumber: values?.vatNumber,
        };

        const onSuccess = () => {
            navigate(getPath('/register/verification'));
        };

        const loginDataValid = validateLoginData(
            { email, email_repeat, password, password_repeat },
            { emailRef, emailRepeatRef, passwordRef, passwordRepeatRef }
        );
 
        const formValid = emailRef.current?.ref?.form?.checkValidity();

        if (formValid && loginDataValid) {
            addCompany(companyData);
            addUser(userData);

            register(companyData, userData, onSuccess);
        } else {
            setPasswordError(loginDataValid.passwordError);
            setEmailError(loginDataValid.emailError);
        }

    };

    const handlePostcodeInput = (e) => {
        if (countryCode === 'BE') {
            e.target.value = e.target.value.replace(/\D/g, '');
        }
    };

    return (
        <>
            {
                error !== null
                &&
                <ErrorMessage>
                    { 
                        Array.isArray(error)
                            ?
                            <>
                                {
                                    error.map(({ message }, index) => {
                                        return <span key={'e'+index}>{ message }</span>
                                    })
                                }
                            </>
                            :
                            error.message 
                    }
                </ErrorMessage>
            }

            <Form onSubmit={onSubmit}>

                <div className={classes.form}>
                    <Row>
                        <Col md="12"><h4 className={classes.header}>{ t('register.userdetails.form_details_header') }</h4></Col>
                        <Col md="6"><Input name="firstname" value={firstname} placeholder={ t('form.firstname_label') } label={ t('form.firstname_label') } required className={classes.input} /></Col>
                        <Col md="6"><Input name="lastname" value={lastname} placeholder={ t('form.lastname_label') } label={ t('form.lastname_label') } required className={classes.input} /></Col>
                        <Col md="6"><Input name="streetName" value={streetName} placeholder={ t('form.street_label') } label={ t('form.street_label') } required className={classes.input} /></Col>
                        <Col md="3"><Input name="houseNumber" value={houseNumber} placeholder={ t('form.housenumber_label') }  label={ t('form.housenumber_label') } required className={classes.input} /></Col>
                        <Col md="3"><Input name="houseNumberExtension" value={houseNumberExtension} placeholder={ t('form.houseNumberExtension_placeholder') } label={ t('form.houseNumberExtension_label') } className={classes.input} /></Col>
                        <Col md="6"><Input name="postalCode" value={postalCode} rules={[ zipcode(countryCode) ]} maxLength={countryCode === "NL" ? 7 : 4} type={countryCode === 'BE' && 'tel'} onInput={handlePostcodeInput} placeholder={ t('form.postalcode_label') } label={ t('form.postalcode_label') } required className={classes.input} /></Col>
                        <Col md="6"><Input name="city" value={city} placeholder={ t('form.city_label') } label={ t('form.city_label') } required className={classes.input} /></Col>
                        <Col md="6"><Input name="phonenumber" value={phonenumber} type="tel" placeholder={ t('form.phonenumber_placeholder') } label={ t('form.phonenumber_label') } required className={classes.input}  />
                            <div className={classes.info}><span>{t('form.phonenumber_description')} </span></div>
                        </Col>
                        <Col md="6"><Input name="tradeName" value={tradeName} placeholder={ t('form.companyname_label') } label={ t('form.companyname_label') } className={classes.input} /></Col>
                        { /* <Col md="7"><Input name="btwnumber" value={dossierNumber} label={ t('register.companydetails.btwnumber_label') } disabled className={classes.input} /></Col>*/}
                    </Row>
                    <Row>
                        <Col md="12"><h4 className={classes.header}>{ t('register.userdetails.form_login_header') }</h4></Col>
                        <Col md="6"><Input ref={emailRef} name="email" value={email} type="email" placeholder={ t('form.email_label') } label={ t('form.email_label') } required className={classes.input} />
                            {emailError && <div className={classes.error_msg}>{emailError}</div>}
                        </Col>
                        <Col md="6"><Input ref={emailRepeatRef} name="email_repeat" value={email_repeat} type="email" placeholder={ t('form.email_repeat_label') } label={ t('form.email_repeat_label') } required  className={classes.input}/></Col>
                        <Col md="6"><Input ref={passwordRef} name="password" value={password} type="password" placeholder={ t('form.password_label') } label={ t('form.password_label') } required className={classes.input} />
                            {passwordError && <div className={classes.error_msg}>{passwordError}</div>}
                        </Col>
                        <Col md="6"><Input ref={passwordRepeatRef} name="password_repeat" value={password_repeat} type="password" placeholder={ t('form.password_repeat_label') } label={ t('form.password_repeat_label') } required className={classes.input} /></Col>
                    </Row>
                </div>

                <div className={classes.form}>
                    <Row>
                        <Col md="12"><Checkbox name="terms_and_conditions" label={<span>{ t('register.details.terms_and_conditions_text') } <a href={getPath('/termsandconditions')} target="_blank" rel="noreferrer">{ t('register.details.terms_and_conditions_link') }</a> &amp; <a href={'/' + config.get('storeCode') + '/privacy'} target="_blank" rel="noreferrer">{ t('register.details.privacy_link') }</a></span>} required/></Col>
                        <Col md="12"><Checkbox name="newsletter" label={<span>{ t('register.details.newsletter_label') }</span>} /></Col>
                        <Col md="12"><Button type="submit" variant="primary" loading={loading} >{ t('register.details.btn_send') }</Button></Col>
                        <Col md="12" className={classes.legal_notice}><span>{ t('register.userdetails.legal_notice') }</span></Col>
                    </Row>
                </div>
            
            </Form>
        </>
        
    );
} 

export default UserDetails;