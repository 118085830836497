import { Helmet } from 'react-helmet-async';

import { t } from '../../../lib/translations';

import useRegister from "../../../hooks/useRegister";

import CompanyCheckModule from '../../../modules/register/companycheck/CompanyCheck';
import UserDetailsModule from '../../../modules/register/userdetails/UserDetails';
import RegisterMode from "../../../modules/register/registermode/RegisterMode";

const CompanyCheck = () => {
    const { mode, setMode } = useRegister();

    return (
        <>
            <Helmet>
                <title>{ t('register.companycheck.page_title') }</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <div>
                <h1>{ t('register.companycheck.header') }</h1>
                <RegisterMode mode={mode} setMode={setMode} />
                { mode === 'kvk' && <CompanyCheckModule /> }
                { mode === 'address' && <UserDetailsModule /> }
            </div>
        </>
    )
}

export default CompanyCheck;