import classNames from 'classnames';
import classes from './YoutubeEmbed.module.css';

const YoutubeEmbed = ({ videoUrl, classname = null }) => {
    return (
        <div className={classNames(classes.root, classname)}>
            <iframe width="560" height="315" src={videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    );
}

export default YoutubeEmbed;