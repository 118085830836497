import { t } from "../../../lib/translations"

import ReviewStars from "../reviewstars/ReviewStars";

import classes from './Average.module.css';
import classNames from "classnames";

const Average = ({ average, total, className }) => {
    return (
        <div className={classNames(classes.root, className)}>
            <div className={classes.content}>
                <ReviewStars score={average}/>
                <span>{average}/5 ({total} {t(total === 1 ? 'reviews.review' : 'reviews.reviews')})</span>
            </div>
        </div>
    )
};

export default Average;