import { useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import { useConfig, useProduct } from '@kega/sps-core';
import { Loader } from '@kega/sps-elements';

import { default as ProductDetailsModule } from '../../modules/productdetails/ProductDetails';
import useTranslatePath from '../../hooks/useTranslatePath';

import NotFound from '../notfound/NotFound';

const ProductDetails = () => {
    const config = useConfig();
    const { product_key } = useParams();
    const { state } = useLocation();
    const { getPath, urlPathWithoutStore } = useTranslatePath();

    const { product, loading, error } = useProduct({
        product_key: product_key,
        product_state: state
    });

    const baseUrl = config.get('baseUrl');
    const storeViews = config.get('storeViews');
    const productName = product?.formattedName || product?.name;

    const productDetails = useMemo(() => ({
        ...(state || {}),
        ...(product || {})
    }), [state, product]);

    if (product === null) {
        if (loading) { return <Loader />; }
        if (error) { return <NotFound />; }
        return null;
    }

    return (
        <>
            <Helmet>
                <title>{productName}</title>
                <meta name="keywords" content={product.metaKeywords} />
                <meta name="description" content={product.metaDescription} />

                <link rel="canonical" href={`${baseUrl}${getPath(product?.url)}`} />

                {
                    storeViews
                    &&
                    Object.keys(storeViews).map((storeCode) => {
                        const href = '/' + storeCode + '/' + urlPathWithoutStore;
                        const lang = storeCode.split('_')[0] === 'be' ? storeCode.split('_').reverse().join('-') : storeCode.split('_').join('-');

                        return <link key={href} rel="alternate" hrefLang={ lang } href={window.location.origin + href} />
                    })
                }

            </Helmet>

            <ProductDetailsModule product={productDetails} />
        </>
    )
}

export default ProductDetails;