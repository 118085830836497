import { useEffect, useState } from 'react';

import { Outlet } from 'react-router-dom';

import { Col, Row, Container } from '../../components/grid';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import BuilderSectionLazy from '../../modules/builder/BuilderSectionLazy';

import useRegister from '../../hooks/useRegister';
import useTranslatePath from '../../hooks/useTranslatePath';

import Progressbar from './progressbar/Progressbar';

import classes from './Register.module.css';

const Register = () => {
    const { clear } = useRegister();
    const { getPath } = useTranslatePath();

    const [breadcrumb, setBreadcrumb] = useState([]);

    useEffect(() => {
        clear();
    }, [clear]);

    return (
        <>
            <Container xl margins g-xs={2} g-md={3}>
                <Row>
                    <Col md={12}>
                        <Breadcrumb paths={breadcrumb} />
                    </Col>

                    <Col md={12}>
                        <Progressbar />
                    </Col>

                    <Col md={7}>
                        <Outlet context={{ setBreadcrumb }} />
                    </Col>

                    <Col md={5} className={classes.builder_wrapper}>
                        <BuilderSectionLazy model="register" url={getPath('/')} />
                    </Col>

                </Row>
            </Container>
        </>
    )
}

export default Register;