import { Helmet } from 'react-helmet-async';

import { default as LoginModule } from '../../../modules/account/login/Login';
import { t } from '../../../lib/translations';

const Login = () => {
    return (
        <>
            <Helmet>
                <title>{ t('account.login.page_title') }</title>
                <meta name="robots" content="index, follow" />
            </Helmet>

            <LoginModule />
        </>
    )
}

export default Login;