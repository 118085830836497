import { useDispatch, useSelector } from 'react-redux';
import { useRequest, useStorefront } from '@kega/sps-core';

import { SetWishlistItems } from '../../redux/wishlist/actions';

const useFetchWishlist = () => {
    const dispatch = useDispatch();

    const { api: { occwishlist } } = useStorefront();
    const { error, loading, setRequest, setSuccess, setError } = useRequest();
    const { items } = useSelector((state) => state.wishlist);

    const fetchWishlist = async () => {

        try {
            setRequest();

            const result = await occwishlist.getWishlist();

            if (result) {
                dispatch(SetWishlistItems(result));
            }

            setSuccess();
        } catch (error) {
            console.log('[FetchWishlist]', error);
            setError(error);
        }
    };

    return {
        error,
        loading,
        fetchWishlist,
        wishlist: items
    };
};

export default useFetchWishlist;