import { useConfig, useFetchCustomer } from '@kega/sps-core';

import { Link, NavLink } from "react-router-dom";

import { t } from '../../lib/translations';
import { Container } from '../../components/grid';
import { ProfileIcon, WishlistIcon } from '../../components/icons';

import useTranslatePath from '../../hooks/useTranslatePath';

import classNames from 'classnames';
import classes from './Header.module.css';

const MinimizedHeader = () => {
    const { getPath } = useTranslatePath();

    const config = useConfig();
    const storeCode = config.get('storeCode');

    const { customer } = useFetchCustomer();

    return (
        <header className={classNames(classes.root, classes.minimized)}>
            <div className={classes.topmenu}>
                <Container xl margins g-xs={2} g-md={3} className={classes.topmenu_container} >
                    <nav className={classes.menu}>
                        <div className={classes.logo}>
                            <Link to={'/' + storeCode} ><img className={classes.logo} height="49" width="106" src="/images/hanos-logo.png" srcSet="/images/hanos-logo.png, /images/hanos-logo2x.png 2x" alt="Hanosshop.com" /></Link>
                        </div>

                        <div className={classes.menu_actions}>
                            {
                                customer?.authenticated && customer?.name
                                    ?
                                    <>
                                        <NavLink to={getPath('/account/overview')} className={classes.iconlink}>
                                            <ProfileIcon size={20} />
                                            <span className={classes.iconlink_label}>{ customer?.name }</span>
                                        </NavLink>
                                        <NavLink to={getPath('/account/wishlist')} className={classes.iconlink}>
                                            <WishlistIcon size={20} />
                                            <span className={classes.iconlink_label}>{ t('header.link_wishlist') }</span>
                                        </NavLink>
                                    </>
                                    :
                                    <>
                                        <NavLink to={getPath('/account/login')} className={classes.iconlink}>
                                            <ProfileIcon size={20} />
                                            <span className={classes.iconlink_label}>{ t('header.link_login_register') }</span>
                                        </NavLink>
                                        <NavLink to={getPath('/wishlist')} className={classes.iconlink}>
                                            <WishlistIcon size={20} />
                                            <span className={classes.iconlink_label}>{ t('header.link_wishlist') }</span>
                                        </NavLink>
                                    </>

                            }

                        </div>
                    </nav>

                </Container>
            </div>
        </header>
    )
}

export default MinimizedHeader;