import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import { t } from '../../../lib/translations';

import useRegister from "../../../hooks/useRegister";
import useTranslatePath from "../../../hooks/useTranslatePath";

import CompanyDetails from "../companydetails/CompanyDetails";
// import UserDetails from "../userdetails/UserDetails";

const Details = () => {
    const { mode } = useRegister();
    const navigate = useNavigate();
    const { getPath } = useTranslatePath();

    useEffect(() => {
        if (mode === null) {
            navigate(getPath('/register/companycheck'));
        }
    }, [mode]);

    return (
        <>
            <Helmet>
                <title>{ t('register.details.page_title') }</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <div>
                { mode === 'kvk' && <CompanyDetails /> }
                {/*{ mode === 'address' && <UserDetails /> }*/}
            </div>
        </>
    )
}

export default Details;