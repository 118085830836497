import { t } from '../../lib/translations';

import useRecentlyViewed from "../../hooks/useRecentlyViewed";
import ProductSlider from '../../modules/sliders/ProductSlider';

const RecentlyViewed = ({ product = null }) => {
    const { products } = useRecentlyViewed(product);

    if (products.length === 0) { return false; }

    return (
        <>
            <h2>{t('productdetails.previosly_viewed_label')}</h2>
            <ProductSlider products={products} hideReviews />
        </>
    );
}

export default RecentlyViewed;