import { parseAlgoliaHitHighlight } from '@algolia/autocomplete-preset-algolia';

import classes from './Category.module.css';

const Category = ({ data, onClick }) => {
    
    const highlightedParts = parseAlgoliaHitHighlight({
        hit: data,
        attribute: 'name'
    });
    
    return (
        <div className={classes.root} onClick={onClick} >
            {
                highlightedParts.map(({ value, isHighlighted }, index) => {
                    const key = `${data.objectID}-${index}`;
                    return isHighlighted ? <strong key={key}>{ value }</strong> : <span key={key}>{ value }</span>;
                })
            }
        </div>
    )
}

export default Category;