import { useConfig, useRequest, useStorefront } from '@kega/sps-core';

const useProductReviews = () => {
    const { api: { trustpilot } } = useStorefront();
    const { data, error, loading, setRequest, setSuccess, setError } = useRequest();
    const config = useConfig();
    const { apiKey, businessUnitId, productReviewLimit } = config.get('trustPilot') || {};

    const getReviews = async (sku) => {
        try {
            setRequest();

            const result = await trustpilot.getProductReviews({ sku, apiKey, businessUnitId, limit: productReviewLimit });

            setSuccess(result);

            return result;

        } catch (error) {
            console.log('[useProductReviews] ', error);
            setError(error);

            return null;
        }

    };

    return {
        reviews: data,
        error,
        loading,
        getReviews
    };
}

export default useProductReviews;
