import { useState } from 'react';
import { NavLink } from "react-router-dom";

import useUi from '../../../../hooks/useUi';

import NavItem from '../../../../components/navigation/NavItem';

import classes from './MenuPager.module.css';

const MenuPager = ({ categories }) => {
    const [ selected, setSelected ] = useState([]);
    const { closeMobileMenu } = useUi();

    const selectedCategory = selected[selected.length-1];
    const items = selectedCategory?.children ?? categories;

    const onReset = () => {
        setSelected([]);
    }

    const onForward = (category, event) => {
        setSelected((selected) => {
            return [
                ...selected,
                category
            ]
        })

        event.preventDefault();
    }

    const onBack = ({ url_key }) => {
        const index = selected.findIndex((category) => { return category.url_key ===  url_key; });
        setSelected((selected) => {
            return selected.slice(0, index+1);
        });
    }

    const onNavigate = () => {
        closeMobileMenu();
    }

    return (
        <nav className={classes.root}>
            {
                selected.length > 0
                &&
                <ul className={classes.back_menu}>
                    <li><NavLink to="#" onClick={onReset}>Alle categorien</NavLink></li>
                    {
                        selected.slice(0, selected.length-1).map(({ name, url_key, children }, index) => {
                            return ( 
                                <li key={String('nav'+index)}>
                                    <NavLink to="#" onClick={onBack.bind(this, { name, url_key, children })}>{ name }</NavLink>
                                </li> 
                            );
                        })
                    }
                </ul>
            }

            <ul className={classes.menu}>
                {
                    items.map(({ name, type, url_key, external, children }, index) => {
                        const onClick = (children.length > 0 ? onForward : onNavigate);
                        return ( 
                            <li key={String('nav'+index)} className={classes.forward} >
                                <NavItem url_key={url_key} type={type} external={external} className={classes.link} onClick={onClick.bind(this, { name, url_key, children })}>{ name }</NavItem>
                            </li> 
                        );
                    })
                }
            </ul>
            
        </nav>
    );
}

export default MenuPager;