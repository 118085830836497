import { useDispatch, useSelector } from 'react-redux';

import { cartOpen, cartClose, mobileMenuOpen, mobileMenuClose, filtersOpen, filtersClose } from '../redux/ui/actions';

const useUi = () => {
    const dispatch = useDispatch();

    const { isCartOpen, isMobileMenuOpen, isFiltersOpen } = useSelector((state) => state.ui);

    const openCart = () => { dispatch(cartOpen()); }
    const closeCart = () => { dispatch(cartClose()); }

    const openMobileMenu = () => { dispatch(mobileMenuOpen()); }
    const closeMobileMenu = () => { dispatch(mobileMenuClose()); }

    const openFilters = () => { dispatch(filtersOpen()); }
    const closeFilters = () => { dispatch(filtersClose()); }

    return {

        isCartOpen,
        openCart,
        closeCart,

        isMobileMenuOpen,
        openMobileMenu,
        closeMobileMenu,

        isFiltersOpen,
        openFilters,
        closeFilters
    };
}

export default useUi;