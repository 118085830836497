import { Fragment, useRef } from 'react';

import classNames from 'classnames';

import { Container } from '../../../components/grid';

import NavItem from '../../../components/navigation/NavItem';

import classes from './NavigationBar.module.css';

const NavigationBar = ({ categories }) => {
    const menuRef = useRef(null);
    const timeoutRef = useRef(null);

    const renderCols = (children, colnumber) => {
        const cols = [[], [], [], []];

        children.map(({ name, type, url_key, external, children = [] }, index) => {

            cols[index % colnumber].push(
                <Fragment key={'nav' + index}>
                    <div className={classes.sub_menu}>
                        <NavItem url_key={url_key} type={type} external={external} className={classes.sub_link} onClick={closeMenu}>{name}</NavItem>
                        {
                            <ul>
                                {
                                    children.map(({ id, name, type, url_key, external }) => {
                                        return <li key={String('level2-nav' + id)}>
                                            <NavItem url_key={url_key} type={type} external={external} className={classes.sub_sub_link} onClick={closeMenu}>{name}</NavItem>
                                        </li>
                                    })
                                }
                            </ul>
                        }
                    </div>
                </Fragment>

            )
        });

        return cols.map((col, index) => {
            return <div key={'c' + index} className={classes.menu_column} >{col}</div>;
        });
    }

    const showMenu = () => {
        timeoutRef.current = setTimeout(() => {
            menuRef.current.className = classNames(classes.menu, classes.menu_hover);
        }, 150);
    }

    const closeMenu = (event) => {
        if (event.relatedTarget && event.relatedTarget.tagName === 'LI') { return; }

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        menuRef.current.className = classNames(classes.menu);
    }

    return (
        <Container xl margins g-xs={2} g-md={3} className={classes.root}>

            <ul ref={menuRef} className={classes.menu}>
                {
                    categories.map(({ name, type, url_key, external, highlight, children = [] }, index) => {
                        return (
                            <li key={String('level0-nav' + index)} onMouseEnter={showMenu} onTouchStart={showMenu} onMouseLeave={closeMenu}>
                                <NavItem
                                    url_key={url_key}
                                    type={type}
                                    external={external}
                                    className={classNames(classes.link, {
                                        [classes.highlighted]: highlight, 
                                        [classes.actie]: name.includes("Actie")
                                    })}
                                >
                                    { name }
                                </NavItem>
                                {
                                    children.length > 1
                                    &&
                                    <div className={classes.sub_menu_container}>
                                        <Container xl margins>
                                            {
                                                renderCols(children, 4)
                                            }
                                        </Container>
                                    </div>
                                }
                            </li>
                        )
                    })
                }
            </ul>

        </Container>
    )

}

export default NavigationBar;