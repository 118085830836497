import IconWrapper from '../wrapper/IconWrapper';

const ChevronIcon = ({ size = 24, color = null, direction='top', className = null }) => {
    return (
        <IconWrapper size={size} color={color} direction={direction} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12.348" viewBox="0 0 20.528 12.348">
                <path d="M12.348,2.412,10,0,0,10.264,10,20.528l2.35-2.412L4.716,10.264Z" transform="translate(20.528) rotate(90)" />
            </svg>
        </IconWrapper>
    );
}

export default ChevronIcon;