import { useDispatch, useStore } from 'react-redux';
import { addUser } from '../redux/algolia_user/actions';
import { useCustomer } from "@kega/sps-core";

const useUserToken = () => {

    const dispatch = useDispatch();
    const customer = useCustomer();
    const store = useStore();
    const { user } = store.getState();

    const get = () => {
        return user.user;
    };

    const set = (force = false) => {
        if (force || !user?.user) {
            const userToken = (customer?.authenticated && customer?.customerId)
                ? customer.customerId
                : (Date.now() + Math.floor(Math.random()));

            dispatch(addUser(userToken));

            return userToken;
        }
    };

    return {
        get,
        set
    };
}

export default useUserToken;