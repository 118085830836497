import { Link } from "react-router-dom";
import useTranslatePath from "../../../hooks/useTranslatePath";
import { ChevronIcon } from "../../../components/icons";

import classes from './BackToLink.module.css'

const BackToLink = ({ children, url_key }) => {
    const { getPath } = useTranslatePath();
    let path = url_key || '/cart';

    return(
        <Link className={classes.root} to={getPath(path)}>
            <ChevronIcon direction="left" size={12} className={classes.icon}/> { children }
        </Link>
    )
}

export default BackToLink;