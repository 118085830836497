import { Link } from "react-router-dom";
import { useConfig } from '@kega/sps-core';

import { t } from '../../lib/translations';

import Contact from "./contact/Contact";

import classNames from "classnames";
import classes from './Footer.module.css';

const MinimizedFooter = () => {
    const config = useConfig();

    const storeCode = config.get('storeCode');

    return (
        <footer className={classNames(classes.root, classes.minimized)}>
            <Contact />
            <div className={classes.metelinks}><Link to={`/${storeCode}/algemene-voorwaarden`}>{t('footer.terms_and_Conditions')}</Link><span>|</span><Link to={`/${storeCode}/privacy`}>{t('footer.privacy')}</Link><span>|</span><Link to={`/${storeCode}/cookies`}>{t('footer.cookies')}</Link></div>

            <div className={classes.copyright}>
                &copy; 2022 - {new Date().getFullYear()} - HANOS SHOP
            </div>

        </footer >
    )
}

export default MinimizedFooter;