import { useState, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useResetPassword } from '@kega/sps-core';
import { Form } from '@kega/sps-elements';

import qs from 'qs';

import { t } from '../../../lib/translations';
import { Col, Row, Container } from '../../../components/grid';

import RecentlyViewed from '../../../components/recentlyviewed/RecentlyViewed';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import Button from '../../../components/buttons/Button';
import Input from '../../../components/form/Input';

import classes from './ResetPassword.module.css';

const ResetPassword = () => {
    const navigate = useNavigate();

    const { success, error, loading, resetPassword } = useResetPassword();

    const { search } = useLocation();

    const passwordRef = useRef(null);
    const passwordRepeateRef = useRef(null);

    const params = qs.parse(search.replace('?', ''));

    const onSubmit = async ({ valid, values, resetForm }) => {

        if (valid) {
            const { password } = values;

            try {
                await resetPassword({ newPassword: password, resetToken: params?.token });
 
            } catch (error) {
                console.log(error);
            }

        }

    };

    return (
    
        <Container xl margins g-xs={2} g-md={3}>
            <Row>
                <Col md="12" className={classes.center}>
                    <div className={classes.container}>
                        <h1 className={classes.h1}>{ t('account.resetpassword.header') }</h1>
                        {
                            !success
                                ?
                                <>
                                    {
                                        error
                                        &&
                                        <ErrorMessage>{ t('account.resetpassword.error_message') }</ErrorMessage>
                                    }

                                    <Form onSubmit={onSubmit}>

                                        <Input ref={passwordRef} name="password" value={''} type="password" placeholder={ t('account.resetpassword.password1_label') } label={ t('account.resetpassword.password1_label') } required rootClassName={classes.input}/>
                                        <Input ref={passwordRepeateRef} name="password_repeate" value={''} type="password" placeholder={ t('account.resetpassword.password2_label') } label={ t('account.resetpassword.password2_label') } required rootClassName={classes.input}/>
                                        <Button
                                            type="submit"
                                            variant="secondary"
                                            loading={loading}
                                            disabled={(params?.token === undefined)}
                                        >
                                            { t('account.resetpassword.btn_reset') }
                                        </Button>

                                    </Form>
                                </>
                                :
                                <>
                                    { t('account.resetpassword.success_text') }
                                </>     

                        }
                    </div>
                </Col>
                
                <Col md="12">
                    <RecentlyViewed />
                </Col>

            </Row>

        </Container>
    )
}

export default ResetPassword;