import { Helmet } from "react-helmet-async";

import BuilderSectionLazy from "../../modules/builder/BuilderSectionLazy";

import useTranslatePath from "../../hooks/useTranslatePath";

import { t } from "../../lib/translations";

const NewsletterSubscribed = () => {
    const { getPath } = useTranslatePath();

    return (
        <>
            <Helmet>
                <title>{ t('newsletter.unsubscribed') }</title>
                <meta name="description" content={''} />
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <BuilderSectionLazy model="newsletter-unsubscribed" url={getPath('/newsletter-unsubscribed')} />
        </>
    )
}

export default NewsletterSubscribed;