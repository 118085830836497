import { Helmet } from 'react-helmet-async';
import { useCart } from '@kega/sps-core';

import { default as CheckoutModule } from '../../modules/checkout/Checkout';

const Checkout = () => {
    
    const cart = useCart();
    
    return (
        <>
            <Helmet>
                <title>Checkout</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <CheckoutModule cart={cart} />
        </>
    )
}

export default Checkout;