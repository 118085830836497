import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { useConfig } from '@kega/sps-core';
import { Loader } from '@kega/sps-elements';

import BuilderContentLazy from '../../modules/builder/BuilderContentLazy';

import NotFound from '../notfound/NotFound';

import classes from './Home.module.css';

const Home = () => {
    const config = useConfig();
    const baseUrl = config.get('baseUrl');
    const storeCode = config.get('storeCode');
    const storeViews = config.get('storeViews');

    const { pathname } = useLocation();

    return (
        <>
            <Helmet>
                <link rel="canonical" href={`${baseUrl}/${storeCode}`} />
                {
                    storeViews
                    &&
                    Object.keys(storeViews).map((storeCode) => {
                        const href = window.location.origin + '/' + storeCode;

                        const lang = storeCode.split('_')[0] === 'be' ? storeCode.split('_').reverse().join('-') : storeCode.split('_').join('-');

                        return <link key={href} rel="alternate" hrefLang={ lang } href={href} />
                    })
                }
            </Helmet>

            <div className={classes.root}>
                <BuilderContentLazy
                    model="page"
                    url={pathname}
                    renderLoading={() => <Loader />}
                    renderNotFound={() => <NotFound />}
                />
            </div>
        </>
    )
}

export default Home;