import { useDispatch } from 'react-redux';
import { useRequest, useStorefront } from '@kega/sps-core';

import { SetWishlistItems } from '../../redux/wishlist/actions';

const useRemoveFromWishlist = () => {
    const dispatch = useDispatch();

    const { api: { occwishlist } } = useStorefront();
    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const removeFromWishlist = async (itemId) => {
        try {
            setRequest();

            const result = await occwishlist.removeProductFromWishlist({
                entryNumber: itemId
            });

            if (result) {
                dispatch(SetWishlistItems(result));
            }

            setSuccess();
        } catch (error) {
            console.log('[RemoveFromWishlist]', error);
            setError(error);
        }
    };

    return {
        error,
        loading,
        removeFromWishlist
    };
};

export default useRemoveFromWishlist;