import { useEffect, useRef } from "react";
import { Helmet } from 'react-helmet-async';
import { useCart, useConfig } from "@kega/sps-core";

import { t } from '../../../lib/translations';

import useTagManager from "../../../hooks/useTagManager";

import AddressesModule from '../../../modules/checkout/addresses/Addresses';

const Addresses = () => {
    const config = useConfig();
    const { ecommerceEvent } = useTagManager();
    const { items, totals } = useCart();

    const tagManagerData = useRef({ items, totals }).current;

    useEffect(() => {
        try {
            const { items, totals } = tagManagerData;

            ecommerceEvent('begin_checkout', {
                value: totals?.total?.value,
                currency: config.get('currency'),
                items: items.map(({ quantity=0, product={}, totalPrice }) => {
                    return {
                        item_id: product?.code,
                        item_name: product?.formattedName || product?.name,
                        quantity: quantity,
                        item_brand: product?.Merk ?? product?.manufacturer,
                        price: totalPrice?.value
                    }

                })
            });
        } catch (error) {
            console.log(error)
        }

    }, [tagManagerData]);

    return (
        <>
            <Helmet>
                <title>{ t('checkout.addresses.page_title') }</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <AddressesModule />
        </>
    )
}

export default Addresses;