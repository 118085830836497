import { Button as DefaultButton } from '@kega/sps-elements';
import classNames from 'classnames';

import { ChevronIcon } from '../../components/icons';

import classes from './SliderButton.module.css';

const SliderButton = ({ type = 'next', className, ...props }) => {

    const btnClassName = classNames(classes.root, classes.btn, classes[type], className);

    return (
        <DefaultButton className={btnClassName} {...props} >
            <ChevronIcon size={16} color="#000" direction={(type === 'next' ? 'right' : 'left')} />
        </DefaultButton>
    );
}

export default SliderButton;
