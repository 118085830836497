export default ({ config, tokens }) => {

    const api = {};

    api.publicToken = (params) => {

        return new Promise((resolve, reject) => {

            const body = Object.entries(params).map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&');

            const request = new Request(config.url + '/authorizationserver/oauth/token', {
                method: 'POST',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
                body: body
            });

            fetch(request).then(async (response) => {

                const data = await response.json();

                if (response.ok) {
           
                    tokens.set('public', {
                        token: data.access_token,
                        expires_in: data?.expires_in,
                        expires_at: Date.now() + (data?.expires_in*1000)
                    });
        
                    resolve();
                } else {
                    reject({
                        error: data.error,
                        message: data.error_description
                    });
                }

            }).catch((error) => {
                reject(error);
            });
        });

    }

    return api;
};