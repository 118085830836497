import IconWrapper from '../wrapper/IconWrapper';

const ShoppingbagIcon = ({ size = 24, color = null, className = null, title = "shoppingbag-icon" }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="19" height="19" viewBox="0 0 19 19">
                <title>{title}</title>
                <g data-name="Group 961" transform="translate(-1248.233 -18.206)">
                    <path id="cart-outline" d="M16.2,17.2a1.9,1.9,0,1,1-1.9,1.9,1.9,1.9,0,0,1,1.9-1.9M1,2H4.107L5,3.9H19.05a.95.95,0,0,1,.95.95,1.2,1.2,0,0,1-.114.475l-3.4,6.146a1.91,1.91,0,0,1-1.662.979H7.745L6.89,14l-.029.114a.238.238,0,0,0,.238.238h11v1.9H6.7a1.9,1.9,0,0,1-1.9-1.9,1.936,1.936,0,0,1,.228-.912L6.32,11.11,2.9,3.9H1V2M6.7,17.2a1.9,1.9,0,1,1-1.9,1.9,1.9,1.9,0,0,1,1.9-1.9m8.55-6.65L17.891,5.8H5.883l2.242,4.75Z" transform="translate(1247.233 16.206)" />
                </g>
            </svg>
        </IconWrapper>
    );
}

export default ShoppingbagIcon;