import { t } from '../../../../../lib/translations';

import classes from './Detail.module.css';
import classNames from "classnames";

const Detail = ({ stars, amount = 0, total = 0, className }) => {
    return (
        <div className={classNames(classes.root, className)}>
            <div className={classes.stars} data-label={`${stars} ${t('reviews.stars')}`}>
                <span>{stars} {t(stars === 1 ? 'reviews.star' : 'reviews.stars')}</span>
            </div>
            <progress className={classNames(classes.progress, classes[`progress_stars_${stars}`])} value={amount} max={total}></progress>
            <div className={classes.reviews} data-label={`${amount} ${t('reviews.reviews')}`}>
                <span>{amount} {t(amount === 1 ? 'reviews.review' : 'reviews.reviews')}</span>
            </div>
        </div>
    )
};

export default Detail;
