export default ({ client, tokens }) => {
    const api = {};

    api.getSubscriptionStatus = async () => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }

            client.get('/hanosUsers/current/subscription', null, headers).then((response) => {
                resolve(response);
            }).catch((error) => {
                console.error(error);
                reject(error);
            });
        });
    };

    api.updateSubscriptionStatus = async (updatedData) => {
        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }

            client.post('/hanosUsers/current/subscription', updatedData, headers).then((response) => {
                resolve(response);
            }).catch((error) => {
                console.error(error);
                reject(error);
            });
        });
    };

    return api;
};
