import IconWrapper from '../wrapper/IconWrapper';

const CouponIcon = ({ size = 24, color = null, className = null }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path id="Path_472" data-name="Path 472" d="M0,0H24V24H0Z" fill="none"/>
                <path id="Path_473" data-name="Path 473" d="M20,6H17.82A2.993,2.993,0,0,0,12.5,3.35l-.5.67-.5-.68A2.994,2.994,0,0,0,6.18,6H4A1.985,1.985,0,0,0,2.01,8L2,19a1.993,1.993,0,0,0,2,2H20a1.993,1.993,0,0,0,2-2V8A1.993,1.993,0,0,0,20,6ZM15,4a1,1,0,1,1-1,1A1,1,0,0,1,15,4ZM9,4A1,1,0,1,1,8,5,1,1,0,0,1,9,4ZM20,19H4V17H20Zm0-5H4V8H9.08L7,10.83,8.62,12,12,7.4,15.38,12,17,10.83,14.92,8H20Z" fill="#ffa50a"/>
            </svg>
        </IconWrapper>
    );
}

export default CouponIcon;


