import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useConfig } from '@kega/sps-core';

import paths from '../../../config/paths';

import classes from './Progressbar.module.css';

const Progressbar = () => {
    const [ current, setCurrent ] = useState(1);
    const location = useLocation();
    const config = useConfig();
    const storeCode  = config.get('storeCode');

    const steps = {
        '/register/companycheck': 1,
        '/register/selectcompany': 2,
        '/register/details': 3,
        '/register/verification': 4
    }

    useEffect(() => {
        let path = location.pathname.replace('/'+storeCode, '');
        let routePath = Object.keys(paths[storeCode]).find((key) => {
            return paths[storeCode][key] === path;
        })
 
        const step = steps[routePath];
        
        if (step) {
            setCurrent(step);
        }

    }, [location]);

    return (
        <div className={classes.root}>
            <progress className={classes.progress} max="6" value={current}></progress>
        </div>
    );
}

export default Progressbar;