import { Link } from 'react-router-dom';
import { useConfig } from '@kega/sps-core';

import { t } from '../../../lib/translations';

import classes from './Contact.module.css';

const Contact = () => {
    const config = useConfig();

    const storeCode = config.get('storeCode');

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                {t('footer.contact_title')}
            </div>
            <div>
                {t('footer.contact_phrase')}
            </div>
            {<Link to={`/${storeCode}/customer-service`}>{t('footer.customer_service')}</Link>}
        </div>
    );
}

export default Contact;