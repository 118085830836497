import IconWrapper from '../wrapper/IconWrapper';

const LinkedinIcon = ({ size = 24, color = null, className = null, title = "linkedin-icon" }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24.114" height="24.114" viewBox="0 0 24.114 24.114">
                <title>{title}</title>
                <path id="linkedin" d="M24.434,3a2.679,2.679,0,0,1,2.679,2.679V24.434a2.679,2.679,0,0,1-2.679,2.679H5.679A2.679,2.679,0,0,1,3,24.434V5.679A2.679,2.679,0,0,1,5.679,3H24.434m-.67,20.764v-7.1A4.367,4.367,0,0,0,19.4,12.3a3.882,3.882,0,0,0-3.108,1.742V12.552H12.552V23.764h3.738v-6.6a1.869,1.869,0,1,1,3.738,0v6.6h3.738M8.2,10.448A2.257,2.257,0,1,0,5.934,8.2,2.251,2.251,0,0,0,8.2,10.448M10.06,23.764V12.552H6.349V23.764Z" transform="translate(-3 -3)" fill="#fff"/>
            </svg>
        </IconWrapper>
    );
}

export default LinkedinIcon;


