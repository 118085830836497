import { useEffect, useState } from "react";
import { useCustomer } from "@kega/sps-core";
import { Button as DefaultButton } from '@kega/sps-elements';

import LinkButton from "./LinkButton";
import { WishlistIcon, WishlistIconFilled } from '../icons';

import useTranslatePath from "../../hooks/useTranslatePath";
import useFetchWishlist from "../../hooks/wishlist/useFetchWishlist";
import useAddToWishlist from "../../hooks/wishlist/useAddToWishlist";
import useRemoveFromWishlist from "../../hooks/wishlist/useRemoveFromWishlist";

const WishlistButton = ({ code = null, ...props }) => {
    const [active, addWishlist] = useState(false);
    const { style, className } = props;
    const customer = useCustomer();
    const authenticated = customer.authenticated;

    const { loading: loadingAdd, error: errorAdd, addToWishlist } = useAddToWishlist();
    const { getPath } = useTranslatePath();
    const { wishlist, fetchWishlist } = useFetchWishlist();
    const { loading: loadingRemove, error: errorRemove, removeFromWishlist } = useRemoveFromWishlist();

    useEffect(() => {
        let productKey = code;
        if (Object.entries(wishlist).length > 0) {
            addWishlist(Object.keys(wishlist).includes(productKey));
        }
        return () => {
            addWishlist(false);
        };
    })

    const handleOnClick = async () => {
        if (code) {
            if (Object.keys(wishlist).includes(code)) {
                const { entryNumber } = wishlist[code];
                try {
                    await removeFromWishlist(entryNumber);
                    addWishlist(false);
                } catch (errorRemove) {
                    console.log(errorRemove);
                }
            } else {
                try {
                    await addToWishlist({ code });
                    addWishlist(true);
                } catch (errorAdd) {
                    console.log(errorAdd);
                }
            }
            await fetchWishlist();
        }
    }

    return (
        authenticated
            ?
            <DefaultButton
                onClick={() => handleOnClick()}
                className={className}
                loading={loadingAdd ? loadingAdd : loadingRemove}
                {...props}>
                {
                    active
                        ?
                        <WishlistIconFilled size={30} />
                        :
                        <WishlistIcon size={30}/>
                }
            </DefaultButton>
            :
            <LinkButton
                to={getPath('/account/wishlist')}
                className={className}
                style={style}>
                <WishlistIcon size={30}/>
            </LinkButton>
    );
}

export default WishlistButton;