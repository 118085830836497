import { Helmet } from 'react-helmet-async';

import { t } from '../../../lib/translations';

import { default as CompanyDetailsModule } from '../../../modules/register/companydetails/CompanyDetails';

const CompanyDetails = () => {
    return (
        <>
            <Helmet>
                <title>{ t('register.companydetails.page_title') }</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <CompanyDetailsModule />
        </>
    )
}

export default CompanyDetails;