import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

const useCurrentCategory = (url_key) => {
    const [match, setMatch] = useState(null);
    const navigationState = useSelector((state) => state.navigation.navigation);

    const root_category = useMemo(() => {
        return Object.values(navigationState).length > 1 ? navigationState['HS_AssortmentNavBarComponent'].find((category) => category.id === 'HS_Assortment1NonFoodNavNode').children : null;
    }, [navigationState]);

    const searchTree = (fn) => (obj) =>
        Array.isArray(obj)
            ? obj.length === 0
                ? null
                : searchTree(fn)(obj[0]) || searchTree(fn)(obj.slice(1))
            : fn(
                obj,
                () => searchTree(fn)(obj.children || []),
                () => obj
            )

    const findCategory = (target, obj) => searchTree(
        (node, next, found) => node['url_key'] === target ? found() : next(),
    )(root_category)


    useEffect(() => {
        if (root_category) {
            setMatch(findCategory(url_key, root_category))
        }

    }, [url_key, root_category]);

    return {
        match
    };
}

export default useCurrentCategory;