export default ({ client, tokens }) => {

    const api = {};

    api.getCompanyInfo = async ({ dossierNumber=null, houseNumber=null, postalCode=null, vatNumber=null, recaptchaToken }) => {
        
        const { token='' } = await tokens.get('public');

        return new Promise((resolve, reject) => {

            let urlparams = '';
            if (dossierNumber !== null) {
                urlparams = `?dossierNumber=${dossierNumber}&fields=FULL`;
            } else if (postalCode !== null && houseNumber !== null) {
                urlparams = `?houseNumber=${houseNumber}&postalCode=${postalCode}&fields=FULL`;
            } else if (vatNumber !== null) {
                urlparams = `?vatNumber=${vatNumber}&fields=FULL`;
            }

            const headers = {
                'Authorization': 'Bearer ' + token,
                'recaptcha-response': recaptchaToken
            }

            client.get('/getCompanyInfo'+urlparams, null, headers).then((response) => {
                resolve(response.companies);
            }).catch((error) => {
                reject(error);
            });

        });
       
    }

    api.register = async ({ payload, recaptchaToken, confirmationCode=null }) => {

        const { token='' } = await tokens.get('public');

        return new Promise((resolve, reject) => {

            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'recaptcha-response': recaptchaToken
            }

            if (confirmationCode !== null) {
                headers['confirmation-code'] = confirmationCode;
            }

            client.post('/orgCustomers/register', payload, headers).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });

        });

    }

    return api;
};