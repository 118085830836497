import { useState, useRef, useEffect, useMemo, Fragment } from 'react';
import { useLocation } from 'react-router-dom';

import { ProductImage } from '@kega/sps-components';

import classNames from 'classnames';

import YoutubeEmbed from '../youtubeembed/YoutubeEmbed';
import { ChevronIcon, YoutubeIcon } from '../icons';

import classes from './MediaSlider.module.css';

const MediaSlider = ({ images = [], thumbnails = true, maxThumbs = 6, imageWidth = 400, imageHeight = 400, onMore = () => { }, onImageClick = () => { } }) => {
    const [active, setActive] = useState(0);
    const [showVideo, setShowVideo] = useState(0);
    const imagesRef = useRef(null);
    const location = useLocation();

    let startClientX = 0;

    useEffect(() => {
        imagesRef.current.style.transition = 'none';
        imagesRef.current.style.transform = 'translate3d(0px, 0px, 0px)';

        setTimeout(() => {
            imagesRef.current.style.transition = 'all cubic-bezier(.795, -.035, 0, 1) .5s';
        }, 10); 
        setActive(0);
    }, [location.pathname]);

    useEffect(() => {
        imagesRef.current.style.transform = `translate3d(${-(active * 100)}%, 0px, 0px)`;
    }, [active]);

    const next = () => {
        if ((active + 1) < images.length) {
            setActive((active) => { return (active + 1) })
        }
    }

    const prev = () => {
        if (active > 0) {
            setActive((active) => { return (active - 1) });
        }
    }

    const moveTo = (index) => {
        setActive(index);
    }

    const onTouchStart = (event) => {
        startClientX = event.touches[0].clientX;
    }

    const onTouchEnd = (event) => {
        const stopClientX = event.changedTouches[0].clientX
        if ((stopClientX - startClientX) > 0) {
            prev();
        } else if ((stopClientX - startClientX) < 0) {
            next();
        }
    }

    const onClickMore = (event) => {
        event.stopPropagation();
        onMore();
    }

    const activateVideo = (index) => {
        setShowVideo(index);
    }

    const thumbnailImages = useMemo(() => {
        return images.slice(0, maxThumbs);
    }, [images, maxThumbs]);

    return (
        <div className={classes.root}>
            <div className={classes.largeimage}>

                <div className={classes.viewport} style={{ maxWidth: imageWidth }} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} >
                    <div ref={imagesRef} className={classes.images}>
                        {
                            images.map((productimg, index) => {
                                const { image, alt, video_url = '' } = productimg;
                                return (
                                    <Fragment key={'pi' + index}>
                                        {
                                            video_url !== ''
                                                ?
                                                <div className={classes.image}>
                                                    <div className={classNames(classes.video_image, index === showVideo ? classes.video_active : null)} onClick={() => activateVideo(index)}>
                                                        <div className={classes.youtube_overlay}>
                                                            <YoutubeIcon size={60} />
                                                        </div>
                                                        <ProductImage
                                                            src={image}
                                                            alt={alt}
                                                            width={imageWidth}
                                                            height={imageHeight}
                                                            loading={(index === active || index === active + 1 ? "eager" : "lazy")}
                                                        />
                                                    </div>
                                                    <YoutubeEmbed classname={classes.video} videoUrl={video_url} />
                                                </div>
                                                :
                                                <div className={classes.image} onClick={onImageClick.bind(this, index, productimg)}>
                                                    <ProductImage
                                                        src={image}
                                                        alt={alt}
                                                        width={imageWidth}
                                                        height={imageHeight}
                                                        loading={(index === active || index === active + 1 ? "eager" : "lazy")}
                                                    />
                                                </div>
                                        }
                                    </Fragment>


                                )
                            })
                        }
                    </div>

                </div>

                {
                    (active > 0)
                    &&
                    <button className={classNames(classes.button, classes.prev)} onClick={prev}>
                        <ChevronIcon direction='left' size={30} />
                    </button>
                }

                {
                    ((active + 1) < images.length)
                    &&
                    <button className={classNames(classes.button, classes.next)} onClick={next}>
                        <ChevronIcon direction='right' size={30} />
                    </button>
                }

            </div>

            {
                thumbnails
                &&
                <div className={classes.thumbnails}>

                    {
                        thumbnailImages.map(({ thumbnail, alt, video_url = '' }, index) => {
                            return (
                                <div key={'tm' + index} className={classNames(classes.thumbnail, (active === index && classes.thumbnail_active))} onClick={moveTo.bind(this, index)}>
                                    {
                                        video_url !== '' &&
                                        <div className={classes.youtube_overlay}>
                                            <YoutubeIcon size={25} />
                                        </div>
                                    }

                                    <ProductImage
                                        src={thumbnail}
                                        alt={alt}
                                        width={imageWidth}
                                        height={imageHeight}
                                        loading={(index === active || index === active + 1 ? "eager" : "lazy")}
                                    />
                                    {
                                        (images.length > maxThumbs && index === thumbnailImages.length - 1)
                                        &&
                                        <div className={classes.thumbnails_more} onClick={onImageClick.bind(this, index, thumbnail)}>+{images.length - maxThumbs}</div>
                                    }
                                </div>
                            )
                        })
                    }

                </div>
            }

        </div>
    );
}

export default MediaSlider;