import { t } from "../../../lib/translations";

import { Col, Container, Row } from "../../../components/grid";
import Summary from "./summary/Summary";
import Average from "../../../components/reviews/average/Average";

import classes from './Reviews.module.css';
import ReviewSlider from "./reviewslider/ReviewSlider";
const Reviews = ({ summary, reviews }) => {
    if (!summary?.numberOfReviews?.total || !summary?.starsAverage) {
        return null;
    }

    return (
        <div className={classes.root} id="reviews">
            <Container xl margins g-xs={2} g-md={3}>
                <Row>
                    <Col md={3} >
                        <h2>{t('productdetails.reviews.average')}</h2>
                        <Average average={summary.starsAverage} total={summary.numberOfReviews.total} className={classes.average}/>
                        <Summary summary={summary.numberOfReviews} className={classes.summary} />
                    </Col>
                    <Col md={9} >
                        <ReviewSlider reviews={reviews}/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Reviews;
