import { useCustomer, useCart } from "@kega/sps-core";

const useScarabQueue = () => {
    const { authenticated, orgUnit } = useCustomer();
    const { items } = useCart(); 

    const isScarabQueueAvailable = typeof window.ScarabQueue !== 'undefined';
    const customerB2Buid = orgUnit?.uid;

    const pushScarabCustomerId = () => {
        if (authenticated && customerB2Buid && isScarabQueueAvailable) {
            window.ScarabQueue.push(['setCustomerId', customerB2Buid]);
        }
    };

    const pushScarabGo = () => {
        if (isScarabQueueAvailable) {
            window.ScarabQueue.push(['go']);
        }
    };

    const pushCartData = () => {
        if (items) {
            const cartItems = items.map((item) => ({
                item: item.product.code, 
                price: item.totalPrice.value, 
                quantity: item.quantity
            }));
            window.ScarabQueue.push(['cart', cartItems]);
        }
    };

    const pushCartInteraction = () => {
        if (isScarabQueueAvailable) {
            pushScarabCustomerId(); 
            pushCartData();
            pushScarabGo(); 
        }
    };

    const pushScarabProduct = (productId) => {
        if (isScarabQueueAvailable) {
            pushScarabCustomerId();
            window.ScarabQueue.push(['view', productId]);
            pushCartData();
            pushScarabGo();
        }
    };

    const pushScarabCategory = (categoryPath) => {
        if (isScarabQueueAvailable) {
            pushScarabCustomerId();
            window.ScarabQueue.push(['category', categoryPath]);
            pushCartData(); 
            pushScarabGo();
        }
    };

    const pushScarabPurchase = (orderId) => {
        if (isScarabQueueAvailable) {
            pushScarabCustomerId();

            const purchaseItems = items.map((item) => ({
                item: item.product.code,
                price: item.basePrice.value,
                quantity: item.quantity
            }));
            window.ScarabQueue.push(['purchase', {
                orderId: orderId,
                items: purchaseItems
            }]);

            // Push empty cart, will be cleared by useClearCart afterward
            window.ScarabQueue.push(['cart', []]);

            pushScarabGo();
        }
    };

    return {
        pushScarabProduct,
        pushScarabCategory,
        pushScarabPurchase,
        pushCartInteraction 
    };
};

export default useScarabQueue;
