import { t } from '../../../../lib/translations';

import Detail from "./detail/Detail";

import classes from './Summary.module.css';

const Summary = ({ summary = {} }) => {
    const { total, oneStar, twoStars, threeStars, fourStars, fiveStars } = summary;

    return (
        <div className={classes.root}>
            <h3>{t('productdetails.reviews.summary')}</h3>
            <div className={classes.summary}>
                <Detail stars={5} amount={fiveStars} total={total}/>
                <Detail stars={4} amount={fourStars} total={total}/>
                <Detail stars={3} amount={threeStars} total={total}/>
                <Detail stars={2} amount={twoStars} total={total}/>
                <Detail stars={1} amount={oneStar} total={total}/>
            </div>
        </div>
    )
};

export default Summary;
