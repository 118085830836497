import classNames from 'classnames';
import classes from './Progressbar.module.css';

const Step = ({ children, value, active, complete }) => {
    
    const stepClassName = classNames(classes.step, {
        [classes.active]: active,
        [classes.complete]: complete
    });

    return (
        <div className={stepClassName} style={{ left: `${value}%` }}>
            { children }
        </div>
    );
}

export default Step;