import { useMemo  } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useCheckout } from '@kega/sps-core';

import { t } from '../../../lib/translations';

import useTranslatePath from '../../../hooks/useTranslatePath';

import { ChevronIcon } from '../../../components/icons';
import { Col, Container, Row } from "../../../components/grid";
import Button from '../../../components/buttons/Button';
import Address from '../../../components/address/Address';

import classes from './Summary.module.css';
import useMediaQuery from "../../../hooks/useMediaQuery";

const Summary = () => {
    const { getPath } = useTranslatePath();
    const { shippingAddress, billingAddress, shippingMethod, shippingMethods } = useCheckout();
    const navigate = useNavigate();
    const { match: matchMobile } = useMediaQuery('(max-width: 768px)');

    const method = useMemo(() => {
        
        const method = shippingMethods.find(({ code }) => code === shippingMethod);
        
        return method || null;
    }, [shippingMethod, shippingMethods]);

    const onClick = () => {
        navigate(getPath('/checkout/payment'));
    }

    return (
        <div>
            <div className={classes.header}>
                <h1>{ t('checkout.summary.header') }</h1>
                <Button variant="secondary" onClick={onClick}>
                    { t('checkout.summary.btn_next') }
                </Button>
            </div>

            <Container xl g-xs={0}>
                <Row>
                    <Col md={6}>
                        <h4>{ t('checkout.summary.delivery_adrress_header') }</h4>
                        <Address address={shippingAddress} />
                        <Link to={getPath('/checkout/addresses')} className={classes.edit}><ChevronIcon size={9} direction='right' color='#FFA50A' /> { t('checkout.summary.link_edit_addresses') }</Link>
                        {
                            matchMobile && <hr className={classes.separator} />
                        }
                    </Col>
                    <Col md={6}>
                        <h4>{ t('checkout.summary.payment_adrress_header') }</h4>
                        <Address address={billingAddress} />
                    </Col>
                </Row>
            </Container>

            <hr className={classes.separator} />

            <h4>{ t('checkout.summary.delivery_header') }</h4>
            <div>
                { method?.name } <br />
            </div>
            <Link to={getPath('/checkout/shipment')} className={classes.edit}><ChevronIcon size={9} direction='right' color='#FFA50A' /> { t('checkout.summary.link_edit_shipment') }</Link>

            <div className={classes.next}>
                <Button variant="secondary" onClick={onClick}>
                    { t('checkout.summary.btn_next') }
                </Button>
            </div>

        </div>
    )
}

export default Summary;