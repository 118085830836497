import { t } from '../../../lib/translations';
import { Col, Row, Container } from '../../../components/grid';

import useTranslatePath from '../../../hooks/useTranslatePath';

import BuilderSectionLazy from '../../../modules/builder/BuilderSectionLazy';
import LoginForm from '../../../components/login/Login';

import classes from './Login.module.css';

const Login = () => {
    const { getPath } = useTranslatePath();

    return (
        <Container xl margins g-xs={2} g-md={3}>
            <Row className={classes.row}>

                <Col md="5" className={classes.col_left}>
                    <div className={classes.login}>
                        <h1>{ t('account.login.header') }</h1>
                        <LoginForm />
                    </div>
                </Col>
                <Col md="2" className={classes.col_middle} >
                    <div className={classes.line} />
                </Col>
                <Col md="5" className={classes.col_right}>
                    <div>
                        <h1>{ t('account.login.right_header') }</h1>
                        <BuilderSectionLazy model="login" url={getPath('/')} />
                    </div>
                </Col>

            </Row>

        </Container>
    )
}

export default Login;