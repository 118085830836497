import { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Form } from '@kega/sps-elements';
import { useConfig } from '@kega/sps-core';

import { Col, Row } from '../../../components/grid';
import { t } from '../../../lib/translations';

import useTranslatePath from '../../../hooks/useTranslatePath';
import useRegister from '../../../hooks/useRegister';
import useOccRegister from "../../../hooks/useOccRegister";

import ErrorMessage from '../../../components/messages/ErrorMessage';

import Input from '../../../components/form/Input';
import Button from '../../../components/buttons/Button';
import Checkbox from '../../../components/form/Checkbox';

import classes from './CompanyDetails.module.css';

const CompanyDetails = () => {
    const config = useConfig();
    const navigate = useNavigate();
    const { setBreadcrumb } = useOutletContext();

    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    
    const { loading, error, validateLoginData, register } = useOccRegister();

    const { mode, user, company, addUser } = useRegister();
    const { getPath } = useTranslatePath();

    const emailRef = useRef(null);
    const emailRepeatRef = useRef(null);

    const passwordRef = useRef(null);
    const passwordRepeatRef = useRef(null);

    useEffect(() => {
        if (company === null || mode === null) {
            navigate(getPath('/register/companycheck'));
        }
    }, [company, mode]);

    useEffect(() => {
        setBreadcrumb([
            { name: 'Home', url_key: '/' },
            { name: 'Registratie', url_key: '/register/companycheck' },
            { name: t('register.userdetails.header') }
        ]);
    }, []);

    const { tradeName, city, houseNumber, houseNumberExtension = ' ', postalCode, streetName } = company ?? {};
    const { email, email_repeat, firstname, lastname, password, password_repeat, phonenumber } = user ?? {};

    const onSubmit = async ({ values }) => {
        const { email, email_repeat, password, password_repeat } = values;

        const userData = {
            email: email,
            password: password,
            firstname: values?.firstname,
            lastname: values?.lastname,
            phonenumber: values?.phonenumber,
            newsletter: values?.newsletter,
        };

        const onSuccess = () => {
            navigate(getPath('/register/verification'));
        };

        const loginDataValid = validateLoginData(
            { email, email_repeat, password, password_repeat },
            { emailRef, emailRepeatRef, passwordRef, passwordRepeatRef }
        );
        const formValid = emailRef.current?.ref?.form?.checkValidity();

        if (formValid && loginDataValid) {
            addUser(userData);

            register(company, userData, onSuccess);
        } else {
            setPasswordError(loginDataValid.passwordError);
            setEmailError(loginDataValid.emailError);
        }

    };

    return (
        <>
            <h1>{t('register.userdetails.header')}</h1>

            {
                error !== null
                &&
                <ErrorMessage>
                    {
                        Array.isArray(error)
                            ?
                            <>
                                {
                                    error.map(({ message }, index) => {
                                        return <span key={'e' + index}>{message}</span>
                                    })
                                }
                            </>
                            :
                            error.message
                    }
                </ErrorMessage>
            }

            <Form onSubmit={onSubmit}>

                <div className={classes.form}>
                    <Row>
                        <Col md="12"><h4 className={classes.header}>{t('register.companydetails.form_company_header')}</h4></Col>
                        <Col md="12"><Input name="tradeName" value={tradeName} label={t('form.companyname_label')} disabled className={classes.input} /></Col>
                        <Col md="6"><Input name="streetName" value={streetName} label={t('form.street_label')} disabled className={classes.input} /></Col>
                        <Col md="3"><Input name="houseNumber" value={houseNumber} label={t('form.housenumber_label')} disabled className={classes.input} /></Col>
                        <Col md="3"><Input name="houseNumberExtension" value={houseNumberExtension} label={t('form.houseNumberExtension_label')} disabled className={classes.input} /></Col>
                        <Col md="6"><Input name="postalCode" value={postalCode} label={t('form.postalcode_label')} disabled className={classes.input} /></Col>
                        <Col md="6"><Input name="city" value={city} label={t('form.city_label')} disabled className={classes.input} /></Col>
                        { /* <Col md="7"><Input name="btwnumber" value={dossierNumber} label={ t('register.companydetails.btwnumber_label') } disabled className={classes.input} /></Col>*/}
                    </Row>
                </div>

                <div className={classes.form}>
                    <Row>
                        <Col md="12"><h4 className={classes.header}>{t('register.companydetails.form_personal_header')}</h4></Col>
                        <Col md="6"><Input name="firstname" value={firstname} placeholder={t('form.firstname_label')} label={t('form.firstname_label')} required className={classes.input} /></Col>
                        <Col md="6"><Input name="lastname" value={lastname} placeholder={t('form.lastname_label')} label={t('form.lastname_label')} required className={classes.input} /></Col>
                        <Col md="6"><Input name="phonenumber" value={phonenumber} type="tel" placeholder={t('form.phonenumber_placeholder')} required label={t('form.phonenumber_label')} className={classes.input} />
                            <div className={classes.info}><span>{t('form.phonenumber_description')} </span></div>
                        </Col>
                    </Row>
                </div>

                <div className={classes.form}>
                    <Row>
                        <Col md="12"><h4 className={classes.header}>{t('register.details.form_login_header')}</h4></Col>
                        <Col md="6"><Input ref={emailRef} name="email" value={email} type="email" placeholder={t('form.email_label')} label={t('form.email_label')} required className={classes.input} />
                            {emailError && <div className={classes.error_msg}>{emailError}</div>}
                        </Col>
                        <Col md="6"><Input ref={emailRepeatRef} name="email_repeat" value={email_repeat} type="email" placeholder={t('form.email_repeat_label')} label={t('form.email_repeat_label')} required className={classes.input} /></Col>
                        <Col md="6"><Input ref={passwordRef} name="password" value={password} type="password" placeholder={t('form.password_label')} label={t('form.password_label')} required className={classes.input} />
                            {passwordError && <div className={classes.error_msg}>{passwordError}</div>}
                        </Col>
                        <Col md="6"><Input ref={passwordRepeatRef} name="password_repeat" value={password_repeat} type="password" placeholder={t('form.password_repeat_label')} label={t('form.password_repeat_label')} required className={classes.input} /></Col>
                    </Row>
                </div>

                <div className={classes.form}>
                    <Row>
                        { /* <Col md="12"><h4 className={classes.header}>Persoonlijke gegevens</h4></Col> */}
                        <Col md="12"><Checkbox name="terms_and_conditions" label={<span>{t('register.details.terms_and_conditions_text')} <a href={getPath('/termsandconditions')} target="_blank" rel="noreferrer">{t('register.details.terms_and_conditions_link')}</a> &amp; <a href={'/' + config.get('storeCode') + '/privacy'} target="_blank" rel="noreferrer">{t('register.details.privacy_link')}</a></span>} required /></Col>
                        <Col md="12"><Checkbox name="newsletter" label={<span>{t('register.details.newsletter_label')}</span>} /></Col>
                        <Col md="12"><Button type="submit" variant="primary" loading={loading} >{t('register.details.btn_send')}</Button></Col>
                    </Row>
                </div>

            </Form>
        </>

    );
}

export default CompanyDetails;