import { Col, Row, Container } from '../../../components/grid';
import { t } from '../../../lib/translations';

const CartEmpty = () => {
    return (
        <Container xl margins g-xs={2} g-md={3}>
            <Row>
                <Col md={12}>
                    <h1>{t('checkout.cart.empty_title')}</h1>
                    <p>{t('checkout.cart.empty_cart')}</p>
                </Col>
            </Row>
        </Container>
    );
}

export default CartEmpty;