import { useMemo } from 'react';

const flattenCategoryPath = (categoryPath) => categoryPath.flat().map((category) => category.name || '').slice(0, 5);

const useItemCategories = (categoryPath) => {
    return useMemo(() => {
        if (Array.isArray(categoryPath) && categoryPath.length > 0) {
            return flattenCategoryPath(categoryPath);
        }
        return [];
    }, [categoryPath]);
};

export default useItemCategories;
