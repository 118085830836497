import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
    isCartOpen: false,
    isMobileMenuOpen: false,
    isFiltersOpen: false
};

export default createReducer(initialState, {

    [actions.cartOpen]: (state, action) => {
        state.isCartOpen = true;
    },

    [actions.cartClose]: (state, action) => {
        state.isCartOpen = false;
    },

    [actions.mobileMenuOpen]: (state, action) => {
        state.isMobileMenuOpen = true;
    },

    [actions.mobileMenuClose]: (state, action) => {
        state.isMobileMenuOpen = false;
    },

    [actions.filtersOpen]: (state, action) => {
        state.isFiltersOpen = true;
    },

    [actions.filtersClose]: (state, action) => {
        state.isFiltersOpen = false;
    }

});
