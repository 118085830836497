import { Container } from '../../components/grid';
import MetaNavigation from '../../components/navigation/meta/MetaNavigation';

import useMediaQuery from '../../hooks/useMediaQuery';
import useTranslatePath from '../../hooks/useTranslatePath';

import BuilderSectionLazy from '../builder/BuilderSectionLazy';

import classNames from "classnames";
import classes from './UspMenu.module.css';

const UspMenu = () => {
    const { match } = useMediaQuery('(min-width: 768px)');
    const { getPath } = useTranslatePath();

    if (!match) { return null; }

    return (
        <Container xl margins g-xs={2} g-md={3}>
            <div className={classes.root}>
                <div className={classNames(classes.usp_container, 'usp_container')}>
                    <BuilderSectionLazy model="usp" url={getPath('/')} />
                </div>

                <MetaNavigation className={classes.menu} />

            </div>
        </Container>
    )

}

export default UspMenu;