import { useEffect, useMemo, useState } from 'react';

import { Highlight, connectRefinementList } from 'react-instantsearch-dom';

import {  AccordionItem, AccordionHeader, AccordionContent, AccordionIcon } from '@kega/sps-elements';
import { SearchIcon } from '../../../../../components/icons';
import { t } from '../../../../../lib/translations';

import Input from '../../../../../components/form/Input';
import Checkbox from '../../../../../components/form/Checkbox';


import classes from './MultiFilter.module.css';
import { useLocation } from 'react-router-dom';

const MultiFilter = ({
    headingText,
    searchText,
    searchable,
    items,
    isFromSearch,
    refine,
    searchForItems,
    createURL,
    limit,
    showMore,
    showMoreLimit,
    attribute,
    shouldOpen
}) => {

    const { pathname } = useLocation();
    const [itemCount, setItemCount] = useState(limit);

    const filteredItems = useMemo(() => {
        return items.slice(0, itemCount);
    }, [items, itemCount])

    const showMoreItems = () => {
        setItemCount(showMoreLimit);
    }

    const showLessItems = () => {
        setItemCount(limit);
    }

    useEffect(() => {
        setItemCount(limit);
    }, [pathname]);

    const capitalLetter = (att) => {
        return att.charAt(0).toUpperCase() + att.slice(1);
    }   

    const withoutDashes = attribute.replace(/-/g, ' ');

    const cleanedAttribute = capitalLetter(withoutDashes);

    const isOnlyNeeForActie = useMemo(() => {
        return attribute === "Actie" && items.length === 1 && items[0].label === "false";
    }, [attribute, items])

    if (filteredItems.length === 0 || isOnlyNeeForActie) { return null; }

    return (
        <AccordionItem className={classes.filter} resizeOnOpen isOpen={shouldOpen}>
            <AccordionHeader className={classes.heading}>{cleanedAttribute}<AccordionIcon color="#000" /></AccordionHeader>
            <AccordionContent>
                <div className={classes.root}>
                    {
                        headingText &&
                        <div className={classes.heading}>
                            {headingText}
                        </div>
                    }
                    {
                        searchable &&
                        <div className={classes.search}>
                            <Input
                                type="search"
                                className={classes.search_input}
                                placeholder={searchText}
                                onChange={(event) => searchForItems(event.currentTarget.value)}
                            />
                            <SearchIcon size={18} className={classes.search_icon} />
                        </div>
                    }
                    {
                        filteredItems.length
                        &&
                        <ul className={classes.values}>
                            {
                                filteredItems.map((item) => {
                                    return (
                                        <li key={item.label}>
                                            <a className={classes.link}
                                                href={createURL(item.value)}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    refine(item.value);
                                                }}
                                            >   
                                                <Checkbox
                                                    name={item.label}
                                                    className={classes.checkbox}
                                                    checked={item.isRefined ? 'checked' : ''}
                                                />
                                                {isFromSearch ? <Highlight attribute="label" hit={item} /> : <span className={classes.value}>{item.label.includes('true')  ? 'Ja' : item.label.includes('false') ? 'Nee' : item.label} </span>}
                                                <span className={classes.count}>({item.count})</span>
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    }
                    { (showMore && filteredItems.length === limit && items.length > limit) && <button className={classes.showmoreless} onClick={showMoreItems}>{ t('productlist.filter.showmore') }</button> }

                    { (showMore && filteredItems.length > limit) && <button className={classes.showmoreless} onClick={showLessItems}>{ t('productlist.filter.showless') }</button> }
                </div>
            </AccordionContent>
        </AccordionItem>
    );
}

export default connectRefinementList(MultiFilter);