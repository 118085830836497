import { useMemo } from 'react';
import { useConfig, useFetchCustomer, useCart, useNavigation } from '@kega/sps-core';

import { Link, NavLink } from "react-router-dom";

import { t } from '../../lib/translations';
import { Container } from '../../components/grid';
import { ProfileIcon, WishlistIcon, ShoppingbagIcon, HamburgerIcon } from '../../components/icons';

import useMediaQuery from '../../hooks/useMediaQuery';
import useUi from '../../hooks/useUi';
import useTranslatePath from '../../hooks/useTranslatePath';

import MiniCart from '../minicart/MiniCart';
import Wishlist from '../header/wishlist/Wishlist';

import NavigationBar from './navigation/NavigationBar';
import ShowCollection from './showcollection/ShowCollection';
import Search from './search/Search';
import LanguageSwitcher from './langswitcher/LanguageSwitcher';

import MobilePayoff from './mobilepayoff/MobilePayoff';
import MobileMenu from './mobilemenu/MobileMenu';

import classes from './Header.module.css';

const Header = () => {
    const { match } = useMediaQuery('(min-width: 768px)');
    const { openMobileMenu, openCart } = useUi();
    const { getPath } = useTranslatePath();

    const { quantity } = useCart();

    const categories = useNavigation('HS_AssortmentNavBarComponent', {
        catalogCode: 'nonfood-nlContentCatalog'
    });
    
    const config = useConfig();
    const storeCode = config.get('storeCode');

    const { customer } = useFetchCustomer();

    const root_category = useMemo(() => {
        return categories ? categories.find((category) => category.id === 'HS_Assortment1NonFoodNavNode') : null;
    }, [categories]);

    const onShoppingBagClick = () => {
        openCart();
    }

    return (
        <header className={classes.root}>

            {
                !match
                &&
                <MobilePayoff />
            }

            {
                !match
                &&
                <MobileMenu categories={root_category ? root_category.children : []} />
            }

            <div className={classes.topmenu}>
                <Container xl margins g-xs={2} g-md={3} className={classes.topmenu_container} >

                    {
                        !match
                        &&
                        <div className={classes.hamburger}>
                            <HamburgerIcon onClick={openMobileMenu}/>
                        </div>
                    }

                    <div className={classes.logo}>
                        <Link to={'/'+storeCode} ><img className={classes.logo} height="49" width="106" src="/images/hanos-logo.png" srcSet="/images/hanos-logo.png, /images/hanos-logo2x.png 2x" alt="Hanosshop.com" /></Link>
                        { match  && <ShowCollection categories={root_category ? root_category.children : []} /> }
                    </div>
                        
                    {
                        match
                        &&
                        <div className={classes.search}>
                            <Search />
                        </div>
                    }

                    <nav className={classes.menu}>

                        <LanguageSwitcher />

                        {
                            (customer?.authenticated && customer?.name)
                                ?
                                <>
                                    <NavLink to={getPath('/account/overview')} className={classes.iconlink}>
                                        <ProfileIcon size={20} />
                                        <span className={classes.iconlink_label}>{ customer?.name }</span>
                                    </NavLink>
                                    <Wishlist loggedIn={true} />
                                </>
                                :
                                <>
                                    <NavLink to={getPath('/account/login')} className={classes.iconlink}>
                                        <ProfileIcon size={20} />
                                        <span className={classes.iconlink_label}>{ t('header.link_login_register') }</span>
                                    </NavLink>
                                    <Wishlist loggedIn={false} />
                                </>
                        }

                        <button type="button" onClick={onShoppingBagClick} className={classes.iconlink}>
                            <ShoppingbagIcon size={20} />
                            <span className={classes.iconlink_label}>{ t('header.link_cart') }</span>
                            {
                                quantity > 0
                                &&
                                <span className={classes.iconlink_quantity}>{ quantity }</span>
                            }
                        </button>
                        
                    </nav>

                    <MiniCart />

                </Container>
            </div>

            <div className={classes.bottommenu}>
                {
                    match
                        ?
                        <NavigationBar categories={root_category ? root_category.children : []} />
                        :
                        <div className={classes.search}>
                            <Search />
                        </div>
                }
            </div>
    
        </header>
    )
}

export default Header;