import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
    cookie_consent: null,
    revise: false, // this will be used to show cookie banner again to the users that already consented cookies
    permissions: {
        functionality_storage: 'denied',
        personalization_storage: 'denied', 
        analytics_storage: 'denied',
        ad_storage: 'denied',
    }
};

export default createReducer(initialState, {
    [actions.addCookieConsent]: (state, action) => {
        const { payload } = action;
        const { consent, revise, permissions={} } = payload;

        return {
            ...state,
            cookie_consent: consent,
            permissions: permissions,
            revise: revise
        }
    }
});