import { Form } from '@kega/sps-elements';
import { useCustomer } from '@kega/sps-core';

import { t } from '../../../../lib/translations';
import { Col, Row } from '../../../../components/grid';

import Input from '../../../../components/form/Input';
import Button from '../../../../components/buttons/Button';

import classes from './EditProfile.module.css';

const EditProfile = () => {
    const customer = useCustomer();

    const { displayUid, firstName, lastName } = customer;

    return (
        <Row>
            <Col md="12">
                <h1 className={classes.h1}>{ t('account.edit_profile.header') }</h1>
            </Col>
            <Col md="12">
                <h2>{ t('account.edit_profile.edit_info') }</h2>
                <Form className={classes.form}>
                    <Input name="email" type="text" label={ t('form.email_label') } value={displayUid} required className={classes.input} />
                    <Input name="firstname" type="text" label={ t('form.firstname_label') } value={firstName} required className={classes.input} />
                    <Input name="lastname" type="text" label={ t('form.lastname_label') } value={lastName} required className={classes.input} />

                    <Button
                        type="submit"
                        variant="primary"
                        loading={false}
                    >
                        { t('account.edit_profile.save') }
                    </Button>
                </Form>
            </Col>
            <Col md="12">
                <h2>{ t('account.edit_profile.edit_password') }</h2>
                <Form className={classes.form}>
                    <Input name="password" type="text" label={ t('account.edit_profile.password1_label') } required className={classes.input} />
                    <Input name="password2" type="text" label={ t('account.edit_profile.password2_label') } required className={classes.input} />

                    <Button
                        type="submit"
                        variant="primary"
                        loading={false}
                    >
                        { t('account.edit_profile.save') }
                    </Button>
                </Form>
            </Col>
        </Row>
    )
}

export default EditProfile;