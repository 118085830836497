import { Helmet } from 'react-helmet-async';

import { t } from '../../../lib/translations';

import { default as SelectCompanyModule } from '../../../modules/register/selectcompany/SelectCompany';

const SelectCompany = () => {
    return (
        <>
            <Helmet>
                <title>{ t('register.selectcompany.page_title') }</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <SelectCompanyModule />
        </>
    )
}

export default SelectCompany;