import { useEffect } from "react";
import { Helmet } from 'react-helmet-async';

import { t } from '../../../lib/translations';

import useTranslatePath from '../../../hooks/useTranslatePath';
import useCheckoutData from "../../../hooks/checkout/useCheckoutData";

import BuilderSectionLazy from '../../../modules/builder/BuilderSectionLazy';

const Success = () => {
    const { getPath } = useTranslatePath();
    const { clear } = useCheckoutData();

    useEffect(() => {
        clear();
    }, []);

    return (
        <>
            <Helmet>
                <title>{ t('checkout.success.page_title') }</title>
                <meta name="description" content={''} />
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <BuilderSectionLazy model="success-page" url={getPath('/checkout/success')} />
        </>
    )
}

export default Success;