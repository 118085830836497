const EnergyLabelC = ({ className }) => {
    return (
        <div className={className}>
            <svg id="Laag_2" data-name="Laag 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128.28 60">
                <title>Energy Label C</title>
                <g id="Laag_1-2" data-name="Laag 1">
                    <g>
                        <polygon strokeWidth="0" fill="#c2d100" points="100.77 0 0 0 0 60 100.77 60 128.28 30 100.77 0"/>
                        <path strokeWidth="0" fill="#fff" d="m47.28,22.54c-2.34-1.35-4.1-1.8-6.89-1.8-4.88,0-8.82,3.57-8.82,9.43s3.73,9.39,9.1,9.39c2.34,0,4.88-.74,6.64-2.05v5.17c-2.09,1.35-4.96,1.64-7.13,1.64-8.04,0-14.15-5.49-14.15-14.15s5.86-14.19,14.15-14.19c2.25,0,4.96.25,7.09,1.52v5.04Z"/>
                    </g>
                </g>
            </svg>
        </div>
    )
};

export default EnergyLabelC;
