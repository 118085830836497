import { useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Drawer } from '@kega/sps-elements';
import { CartItems } from '@kega/sps-cart';
import { useCart, useClearCart } from '@kega/sps-core';

import { t } from '../../lib/translations';

import useUi from '../../hooks/useUi';
import useScrollLock from '../../hooks/useScrollLock';
import useTranslatePath from '../../hooks/useTranslatePath';
import useUserId from "../../hooks/useUserId";
import useScarabQueue from '../../hooks/useScarabQueue';

import { CloseIcon } from '../../components/icons';
import Button from '../../components/buttons/Button';
import CartItem from './item/CartItem';
import CartTotals from './totals/CartTotals';

import classes from './MiniCart.module.css';

const MiniCart = () => {
    const navigate = useNavigate();
    const { isCartOpen, closeCart } = useUi();
    const { cartId, items, totals, fetchCart } = useCart();
    const { getPath } = useTranslatePath();

    const { pushCartInteraction } = useScarabQueue();

    const getUserId = useUserId();
    const clearCart = useClearCart();

    const initialCartId = useRef(cartId);

    const itemsIdentifier = useMemo(() => items?.map((item) => `${item.product?.code}:${item.quantity}`).join('|'), [ items ]);

    useScrollLock(isCartOpen);

    useEffect(() => {
        pushCartInteraction();
    }, [itemsIdentifier]);

    useEffect(() => {

        const load = async () => {
            if (getUserId() === 'anonymous') {
                const cart = await fetchCart(initialCartId.current, { userId: getUserId() });
                if (cart?.hasOngoingPaymentSession) {
                    clearCart();
                }
            } else {
                await fetchCart('current');
            }
        }

        if (initialCartId.current !== null) {
            load();
        }
    }, [initialCartId])

    const onEditCart = () => {
        navigate(getPath('/cart'));
        closeCart();
    }

    return (
        <Drawer open={isCartOpen} onClose={closeCart} drawerClassName={classes.drawer}>

            <header className={classes.header}>
                {t('checkout.cart.title')}
                <CloseIcon className={classes.close} onClick={closeCart} />
            </header>

            {
                items.length
                    ?
                    <CartItems
                        className={classes.items}
                        itemComponent={CartItem}
                    />
                    :
                    <div className={classes.empty}>
                        {t('checkout.cart.empty_cart')}
                    </div>
            }

            {
                totals &&
                <footer className={classes.footer}>
                    <CartTotals total={totals.total} subTotal={totals.subTotal} />
                    <div className={classes.buttons}>
                        <Button variant="link" onClick={onEditCart}>{t('checkout.cart.edit_cart')}</Button>
                        <Button variant="secondary" onClick={onEditCart}>{t('checkout.cart.order')}</Button>
                    </div>
                </footer>
            }

        </Drawer>
    )
}

export default MiniCart;