import { useCustomer } from "@kega/sps-core";

const useUserId = () => {
    const { authenticated = false } = useCustomer();

    const getUserId = () => {
        return authenticated ? 'current' : 'anonymous';
    };

    return getUserId;
};

export default useUserId;