import { Container } from '../../grid';

import classes from './Header.module.css';

const Header = () => {
    return (
        <header className={classes.root}>
            <div className={classes.topmenu}>
                <Container xl margins g-xs={2} g-md={3} className={classes.topmenu_container}>
                    <nav className={classes.menu}>
                        <div className={classes.logo}>
                            <img height="49" width="106" src="/images/hanos-logo.png" srcSet="/images/hanos-logo.png, /images/hanos-logo2x.png 2x" alt="Hanosshop.com" />
                        </div>
                    </nav>
                </Container>
            </div>
        </header>
    )
}

export default Header;