export const filterVisibleFacets = (items, { results }) => {
    const rawResults = results?._rawResults || [];
    const visibleFacets = rawResults[0]?.facets || {};
    const visibleItems = [];

    items.map((attribute) => {
        const facet = visibleFacets[attribute] || {};
        const facetOptions = Object.keys(facet) || [];

        if (attribute === 'Prijs' || attribute === 'Categorieën' || attribute === 'inPromotion') {
            // Range, toggle and category level facets are always visible
            visibleItems.push(attribute);
        } else {
            const hideAction = (attribute === "Actie" && facetOptions.length === 1 && facetOptions[0] === "false");

            if (facetOptions.length > 0 && !hideAction) {
                visibleItems.push(attribute);
            }
        } 
    });

    return visibleItems;
};
