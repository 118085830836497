import { useCallback } from 'react';
import { useStorefront, useConfig } from '@kega/sps-core';

const usePublicToken = () => {
    const config = useConfig();

    const { api: { occ } } = useStorefront();

    const authentication = config.get('authentication');

    const getPublicToken = useCallback( async () => {

        try {
            await occ.publicToken({
                client_id: authentication.client_id,
                client_secret: authentication.client_secret,
                grant_type: 'client_credentials'
            });

        } catch (error) {
            console.log(error);
        }
        
    }, []);

    return {
        getPublicToken
    }
}

export default usePublicToken;