const useAlgoliaProductData = () => {
    const parseProduct = (data) => {
        let images = [];

        if (data['img-297Wx297H']) {
            images = [
                {
                    altText: data.name,
                    format:"store",
                    galleryIndex:0,
                    imageType:"GALLERY",
                    url: data['img-297Wx297H']
                },{
                    altText: data.name,
                    format: "thumbnail",
                    galleryIndex: 0,
                    imageType:"GALLERY",
                    url: data['img-297Wx297H']
                }
            ]
        }

        let price = null;

        if (data.price) {
            price = {
                currencyIso:"EUR",
                dynamic:[],
                dynamicDiscount:false,
                formattedValue: String(data.price),
                priceType:"BUY",
                value: data.price,
                oldPrice: null,
                promotion: false
            }
        }

        if (price && data.Actie && data.promotionFromPrice) {
            price.oldPrice = data.promotionFromPrice;
            price.promotion = data.Actie;
        }

        if (price && data.strikeThroughPrice) {
            price.strikeThroughPrice = data.strikeThroughPrice;
        }

        return {
            ...data,
            images: images,
            price: price,
            stock: {
                stockLevel: data.stockLevel,
                stockLevelStatus: data.stockLevelStatus
            }
        };
    };

    return {
        parseProduct
    };
};

export default useAlgoliaProductData;