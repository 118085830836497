import { useState, useEffect, useRef } from "react";
import { useConfig } from '@kega/sps-core';

import { WorldIcon } from "../../../components/icons"

import classes from './LanguageSwitcher.module.css';
import classNames from "classnames";

const LanguageSwitcher = () => {
    const [openDropDown, setOpenDropDown] = useState(false);
    const config = useConfig();

    const storeviews = config.get('storeViews');
    const country = config.get('country');
    const storeCode = config.get('storeCode');

    const currentCountryName = storeviews[storeCode].name.split(" ")[0];

    const onClick = () => {
        setOpenDropDown((prevValue) => !prevValue);
    }

    const dropdown = useRef();

    useEffect(() => {
        const handler = (e) => {
            if (dropdown.current && !dropdown.current.contains(e.target)) {
                setOpenDropDown(false);
            }
        }

        document.addEventListener("mousedown", handler)

        return () => { document.removeEventListener("mousedown", handler) }
    }, []);

    return (
        <div onClick={onClick} className={classes.iconlink} ref={dropdown}>
            <WorldIcon size={20} />
            <span className={classes.name}>{currentCountryName}</span>

            {openDropDown && (
                <div className={classes.dropdown}>
                    {Object.keys(storeviews).map((view) => {
                        const countryStoreViews = storeviews[view];
                        const countryName = countryStoreViews.name.split(" ")[0];
                        const isActive = countryStoreViews.country === country;

                        return (
                            <div className={classes.hover} key={view.country}>
                                <a
                                    href={isActive ? '#' : `/${view}`}
                                    className={classNames(classes.item, isActive && classes.disabled)}
                                >
                                    <img
                                        src={`/images/${countryStoreViews.country}.svg`}
                                        alt={countryName}
                                        className={classes.flag}
                                    />
                                    {countryName}
                                </a>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    )
}

export default LanguageSwitcher
