import '@kega/sps-elements/dist/styles.css';
import '@kega/sps-components/dist/styles.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import { HelmetProvider } from 'react-helmet-async';
import { Storefront } from '@kega/sps-core';

import configureStore from './redux/store';
import config from './config/config';

import configureApi from './storefront';

import App from './App';

import { setLanguage } from './lib/translations';
import UncaughtErrorFallback from './components/error/UncaughtErrorFallback';

import './css/variables.css';
import './css/main.css';
import './css/algolia.css';

if (config.bugsnag.key !== null) {
    Bugsnag.start({
        apiKey: '4bb32a82c1bd6ec0a4d1413c93e57ac2',
        plugins: [new BugsnagPluginReact()],
        releaseStage: process.env.NODE_ENV,
        enabledReleaseStages: [ 'production' ]
    });
}

// Get storeCode from url, storeview change needs a refresh
const [ storeCode ] = window.location.pathname.substring(1).split('/');

const { store, persistor } = configureStore();
const api = configureApi(config, storeCode);

const onBeforeStart = async ({ storeCode, config }) => {

    return new Promise(async (resolve) => {
        const locale = config.locale;

        await setLanguage(locale);

        resolve();
    }); 

}

ReactDOM.render(
    <React.StrictMode>
        <Storefront 
            store={store} 
            persistor={persistor}
            config={config} 
            api={api} 
            storeCode={storeCode}
            onBeforeStart={onBeforeStart}
        >
            <HelmetProvider>
                <ErrorBoundary
                    FallbackComponent={UncaughtErrorFallback}
                >
                    <App />
                </ErrorBoundary>
            </HelmetProvider>
        </Storefront>
    </React.StrictMode>,
    document.getElementById('root')
);