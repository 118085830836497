
import { useRequestResetPassword } from '@kega/sps-core';
import { Form } from '@kega/sps-elements';

import { t } from '../../../lib/translations';
import { Col, Row, Container } from '../../../components/grid';

import RecentlyViewed from '../../../components/recentlyviewed/RecentlyViewed';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import Button from '../../../components/buttons/Button';
import Input from '../../../components/form/Input';

import classes from './ForgotPassword.module.css';

const ForgotPassword = () => {
    const { data, success, error, loading, requestResetPassword } = useRequestResetPassword();

    const onSubmit = async ({ valid, values, resetForm }) => {

        if (valid) {
            const { userId } = values;
            try {
                await requestResetPassword({ userId: userId.toLowerCase() });
            } catch (error) {
                console.log(error);
            }
        }

    };

    return (
        <Container xl margins g-xs={2} g-md={3}>
            <Row>
                <Col md="12" className={classes.center}>
                    <div className={classes.container}>
                 
                        {
                            !success
                                ?
                                <>
                                    <h1 className={classes.h1}>{ t('account.forgotpassword.header') }</h1>
                                    <p>{ t('account.forgotpassword.text') }</p>
                                    {
                                        error
                                        &&
                                        <ErrorMessage>{ t('account.forgotpassword.error_message') }</ErrorMessage>
                                    }

                                    <Form onSubmit={onSubmit}>

                                        <Input name="userId" type="email" label={t('form.username_label')} required errorMessage={t('form.error_not_empty')} rootClassName={classes.input} />

                                        <Button
                                            type="submit"
                                            variant="secondary"
                                            loading={loading}
                                            className={classes.button}
                                        >
                                            {t('account.forgotpassword.btn_send')}
                                        </Button>

                                    </Form>
                                </>
                                :
                                <>
                                    <h1 className={classes.h1}>{ t('account.forgotpassword.success_header') }</h1>
                                    <p>{ t('account.forgotpassword.success_text_1', { email: data?.userId }) }</p>
                                    <p>{ t('account.forgotpassword.success_text_2') }</p>
                                </>     
                        }

                    </div>
                </Col>

                <Col md="12">
                    <RecentlyViewed />
                </Col>

            </Row>

        </Container>
    )
}

export default ForgotPassword;