import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Loader } from "@kega/sps-elements";

import useTranslatePath from "../../../../hooks/useTranslatePath";
import useFetchSubscriptionStatus from "../../../../hooks/useNewsletter/useNewsletterFetchStatus";
import useUpdateSubscriptionStatus from "../../../../hooks/useNewsletter/useNewsletterUpdateStatus";

import { t } from "../../../../lib/translations";
import { Col } from "../../../../components/grid";
import Checkbox from "../../../../components/form/Checkbox";
import Button from "../../../../components/buttons/Button";

import classes from './Newsletter.module.css'

const Newsletter = () => {
    const navigate = useNavigate();
    const { getPath } = useTranslatePath();

    const [isSubscribed, setIsSubscribed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [objectID, setObjectID] = useState(null);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [subscriptionMessage, setSubscriptionMessage] = useState('');
    const [showNewsletter, setShowNewsletter] = useState(true);

    const { fetchSubscriptionStatus } = useFetchSubscriptionStatus();
    const { updateSubscriptionStatus } =  useUpdateSubscriptionStatus();

    useEffect(() => {
        const initFetchSubscription = async () => {
            setLoading(true);
            setShowNewsletter(false);
            try {
                const result = await fetchSubscriptionStatus();
                if (result) {
                    setIsSubscribed(!!result.subscriptionConsentNonfood);
                    setObjectID(result.objectID || '');
                    setShowNewsletter(!!result && !!result.objectID);
                    setSubscriptionMessage(result.subscriptionConsentNonfood ? t('account.profile.newsletter_already_subscribed') : '');
                }
            } catch (error) {
                console.error('Error fetching subscription status:', error);
            } finally {
                setLoading(false);
            }
        };

        initFetchSubscription();
    }, []);

    const handleSubscribedStatus = (subscribedStatus) => {
        setIsSubscribed(subscribedStatus);
        setIsButtonEnabled(!isButtonEnabled);
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await updateSubscriptionStatus(objectID, isSubscribed); 
            navigate(getPath(isSubscribed ? '/newsletter-unsubscribed' : '/newsletter-subscribed'));

            if (isSubscribed) {
                navigate(getPath('/newsletter-subscribed'));
            } else {
                navigate(getPath('/newsletter-unsubscribed'));
            }
            
        } catch (error) {
            console.error('Error status', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading && !showNewsletter ?
                <Loader variant="primary"/>
                : showNewsletter &&
            <Col md="12" className={classes.newsletter}>
                <Form onSubmit={handleSubmit} className={classes.newsletter_form}>
                    <div className={classes.left_content}>
                        <div className={classes.inner_content}>
                            <strong>{t('account.profile.newsletter')}</strong>                        
                            <div className={classes.newsletter_subscription}>
                                <div>
                                    {
                                        loading ? 
                                            <Loader variant="primary"/>
                                            : subscriptionMessage && 
                                            <Col md="12">
                                                <p>{subscriptionMessage}</p>
                                            </Col>
                                    }

                                    <Checkbox
                                        name={'subscribe'}
                                        checked={false}
                                        disabled={subscriptionMessage}
                                        className={classes.checkbox}
                                        onChange={() => handleSubscribedStatus(true)}
                                        label={t('account.profile.subscribe')}
                                    />
                                </div>
                                <div>
                                    <Checkbox
                                        disabled={!subscriptionMessage}
                                        checked={false}
                                        name={'unsubscribe'}
                                        onChange={() => handleSubscribedStatus(false)}
                                        className={classes.checkbox}
                                        label={t('account.profile.unsubscribe')}
                                    />
                                </div>
                            </div>
                            <div>
                                <Button type="submit" variant="primary" disabled={!isButtonEnabled || loading}>
                                    {t('account.profile.newsletter_submit')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>                       
                <div className={classes.right_content}>
                    <img src='/images/newsletter_content.png' alt={"newsletter_content"}/>   
                </div>
            </Col>
            }
        </>
    );
}

export default Newsletter;