import useI18n from "../../hooks/useI18n";

import classNames from 'classnames';
import classes from './Address.module.css';

const Address = ({ className, address }) => {
    const { getCountryNameByCode } = useI18n();

    if (!address) { return null; }

    const rootClassName = classNames(classes.root, className);
    const country = address.country?.name || getCountryNameByCode(address.country?.isocode) || '';

    return (
        <address className={rootClassName}>
            {address.companyName && <><strong>{address.companyName}</strong> <br /></>}
            {address.firstName} {address.middleName} {address.lastName} <br />
            {address.line1} {address.line2} {address.houseNumberExtension} <br />
            {address.postalCode} {address.town} <br />
            {country} <br />

            {(address.phone || address.email) ? <br /> : null}
            {address.phone ? <>{address.phone}<br /></> : null}
            {address.email ? <>{address.email}<br /></> : null}
        </address>
    );
}

export default Address;