import { Helmet } from 'react-helmet-async';

import { default as RegisterModule } from '../../modules/register/Register';

const Register = () => {
    
    return (
        <>
            <Helmet>
                <title>Register</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <RegisterModule />
        </>
    )
}

export default Register;