import { useEffect, useCallback, useReducer } from 'react';


const initialState = {
    success: false,
    error: null,
    loading: false,
    token: null
};

const reducer = (state, action) => {
    switch (action.type) {
    case 'request':
        return  {
            ...state,
            error: null,
            loading: true,
            success: false
        };
    case 'success':
        return {
            ...state,
            token: action.token,
            error: null,
            loading: false,
            success: true
        };
    case 'error':
        return {
            ...state,
            error: action.error,
            loading: false,
            success: false
        };
    default:
        throw new Error();
    }
}

const useReCaptcha = ({ key, action='homepage', language=null, autoLoad=true }) => {
    const [ state, dispatch ] = useReducer(reducer, initialState);

    const clean = useCallback(() => { 

        const nodeBadge = document.querySelector('.grecaptcha-badge');
        if (nodeBadge && nodeBadge.parentNode) {
            document.body.removeChild(nodeBadge.parentNode);
        }

        const script = document.querySelector(`#recaptcha`);
        if (script) { 
            script.remove(); 
        }

        const recaptchascript = document.querySelector(`script[src^='https://www.gstatic.com/recaptcha/releases']`);
        if (recaptchascript) { 
            recaptchascript.remove(); 
        }

    }, []);

    const loadToken = useCallback(() => { 

        return new Promise(async (resolve, reject) => {

            if (window.grecaptcha) {
                
                dispatch({ type: 'request' });

                window.grecaptcha.ready(async () => {
                    try {
                        const token = await window.grecaptcha.execute(key, { action: action });
                        dispatch({ type: 'success', token: token });
                        resolve(token);
                    } catch (error) {
                        dispatch({ type: 'error', error: error });
                        reject(error)
                    }
                });
            }
           
        });
    }, [dispatch, key, action]);

    useEffect(() => {
        const script = document.createElement("script")
        script.src = "https://www.google.com/recaptcha/api.js?render=" + key + (language ? '&hl='+ language : '' )
        script.id = 'recaptcha';
        if (autoLoad) {
            script.addEventListener("load", loadToken);
        }
        document.body.appendChild(script);

        return () => {
            clean();
        }
    }, [key, clean, loadToken, language, autoLoad]);

    return {
        token: state.token,
        error: state.error,
        loading: state.loading,
        loadToken: loadToken
    }

}

export default useReCaptcha;