const ShowroomModel = ({ className }) => {
    return (
        <div className={className}>
            <svg id="Laag_2" data-name="Laag 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 425.83 60">
                <title>Showroom Model</title>
                <g id="Laag_1-2" data-name="Laag 1">
                    <g>
                        <rect fill="#f6a31c" strokeWidth="0" width="425.83" height="60" rx="6" ry="6"/>
                        <g>
                            <path fill="#fff" strokeWidth="0" d="m43.51,23.14c-1.84-1.11-4.3-1.84-6.56-1.84-2.62,0-4.1,1.31-4.1,3.08,0,1.97,2.09,3.03,4.67,4.18,3.49,1.52,7.3,3.36,7.3,8.32s-3.94,8.08-9.59,8.08c-2.79,0-5.74-.62-7.75-1.76v-5.29c2.38,1.72,5.08,2.54,7.71,2.54,2.46,0,4.18-1.27,4.18-3.4s-2.38-3.12-5.37-4.47c-3.2-1.48-6.72-3.32-6.72-8s3.77-7.79,9.51-7.79c2.17,0,4.67.37,6.72,1.35v5Z"/>
                            <path fill="#fff" strokeWidth="0" d="m71.11,16.99v27.63h-5.21v-11.6h-11.36v11.6h-5.17v-27.63h5.17v11.56h11.36v-11.56h5.21Z"/>
                            <path fill="#fff" strokeWidth="0" d="m88.78,16.5c8.08,0,13.08,5.66,13.08,14.23s-5.08,14.19-13.04,14.19-13.08-5.66-13.08-14.19,5.08-14.23,13.04-14.23Zm.04,23.66c4.67,0,7.5-3.61,7.5-9.43s-2.83-9.47-7.54-9.47-7.5,3.65-7.5,9.47,2.95,9.43,7.54,9.43Z"/>
                            <path fill="#fff" strokeWidth="0" d="m125.47,17.4l2.83,9.8c1.72,6.03,3.28,11.89,3.28,11.89,0,0,1.03-5.7,2.54-11.93l2.5-10.17h5.25l-7.05,27.63h-5.99l-2.5-8.73c-2.3-7.83-2.91-10.66-2.91-10.66,0,0-.57,2.87-2.87,10.66l-2.58,8.73h-5.95l-7.38-27.63h5.45l2.58,10.21c1.52,6.19,2.62,11.81,2.62,11.81,0,0,1.31-5.17,3.2-11.81l2.79-9.8h4.18Z"/>
                            <path fill="#fff" strokeWidth="0" d="m153.76,34.54h-2.83v10.09h-5.17v-27.63h8.53c6.35,0,10.09,3.53,10.09,8.73-.04,4.06-2.13,6.72-5.25,8l7.09,10.91h-6.19l-6.27-10.09Zm-2.83-4.26h3.44c3.28,0,4.76-1.8,4.76-4.59s-1.72-4.43-4.84-4.43h-3.36v9.02Z"/>
                            <path fill="#fff" strokeWidth="0" d="m181.85,16.5c8.08,0,13.08,5.66,13.08,14.23s-5.08,14.19-13.04,14.19-13.08-5.66-13.08-14.19,5.08-14.23,13.04-14.23Zm.04,23.66c4.67,0,7.5-3.61,7.5-9.43s-2.83-9.47-7.54-9.47-7.5,3.65-7.5,9.47,2.95,9.43,7.54,9.43Z"/>
                            <path fill="#fff" strokeWidth="0" d="m211.49,16.5c8.08,0,13.08,5.66,13.08,14.23s-5.08,14.19-13.04,14.19-13.08-5.66-13.08-14.19,5.08-14.23,13.04-14.23Zm.04,23.66c4.67,0,7.5-3.61,7.5-9.43s-2.83-9.47-7.54-9.47-7.5,3.65-7.5,9.47,2.95,9.43,7.54,9.43Z"/>
                            <path fill="#fff" strokeWidth="0" d="m230.92,16.99h5.9l3.77,10.04c2.17,5.74,3.44,10.09,3.44,10.09,0,0,1.27-4.26,3.53-10.09l3.85-10.04h5.9l2.71,27.63h-5.17l-.98-10.78c-.49-5.45-.74-9.68-.74-9.68,0,0-1.48,4.22-3.53,9.68l-3.85,10.37h-3.53l-3.77-10.37c-2.17-6.07-3.24-9.55-3.24-9.55,0,1.64-.25,5.49-.53,9.55l-.82,10.78h-5.17l2.21-27.63Z"/>
                            <path fill="#fff" strokeWidth="0" d="m267,16.99h5.9l3.77,10.04c2.17,5.74,3.44,10.09,3.44,10.09,0,0,1.27-4.26,3.53-10.09l3.85-10.04h5.9l2.71,27.63h-5.17l-.98-10.78c-.49-5.45-.74-9.68-.74-9.68,0,0-1.48,4.22-3.53,9.68l-3.85,10.37h-3.53l-3.77-10.37c-2.17-6.07-3.24-9.55-3.24-9.55,0,1.64-.25,5.49-.53,9.55l-.82,10.78h-5.17l2.21-27.63Z"/>
                            <path fill="#fff" strokeWidth="0" d="m313.29,16.5c8.08,0,13.08,5.66,13.08,14.23s-5.08,14.19-13.04,14.19-13.08-5.66-13.08-14.19,5.08-14.23,13.04-14.23Zm.04,23.66c4.67,0,7.5-3.61,7.5-9.43s-2.83-9.47-7.54-9.47-7.5,3.65-7.5,9.47,2.95,9.43,7.54,9.43Z"/>
                            <path fill="#fff" strokeWidth="0" d="m340.02,16.99c8.32,0,14.06,5.78,14.06,13.45,0,8.41-5.37,14.19-14.06,14.19h-9.02v-27.63h9.02Zm-.08,23.33c5.94,0,8.86-4.06,8.86-9.88,0-5.17-3.28-9.1-8.94-9.1h-3.57v18.98h3.65Z"/>
                            <path fill="#fff" strokeWidth="0" d="m375.16,40.4v4.22h-16.48v-27.63h16.32v4.22h-11.11v7.5h9.88v4.18h-9.88v7.5h11.28Z"/>
                            <path fill="#fff" strokeWidth="0" d="m396.27,40.03v4.59h-15.95v-27.63h5.17v23.04h10.78Z"/>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
};

export default ShowroomModel;
