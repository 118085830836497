export default {
    'nl_nl': {
        '/p/:product_key/*': '/p/:product_key/*',
        '/c/:category_key/*': '/c/:category_key/*',
        '/account': '/account',
        '/account/login': '/account/login',
        '/account/forgotpassword': '/account/forgotpassword',
        '/account/resetpassword': '/account/resetpassword',
        '/account/overview': '/account/overview',
        '/account/orders': '/account/orders',
        '/account/profile': '/account/profile',
        '/account/profile/edit': '/account/profile/edit',
        '/account/profile/editpassword': '/account/profile/editpassword',
        '/cart': '/cart',
        '/search': '/search',
        '/promotions': '/acties',
        '/checkout': '/checkout',
        '/checkout/addresses': '/checkout/addresses',
        '/checkout/shipment': '/checkout/shipment',
        '/checkout/summary': '/checkout/summary',
        '/checkout/payment': '/checkout/payment',
        '/checkout/payment/callback': '/checkout/payment/callback',
        '/checkout/success': '/checkout/success',
        '/register': '/register',
        '/register/companycheck': '/register/companycheck',
        '/register/selectcompany': '/register/selectcompany',
        '/register/details': '/register/details',
        '/register/verification': '/register/verification',
        '/register/success': '/register/success',
        '/termsandconditions':  '/algemene-voorwaarden',
    },
    'be_nl': {
        '/p/:product_key/*': '/p/:product_key/*',
        '/c/:category_key/*': '/c/:category_key/*',
        '/account': '/account',
        '/account/login': '/account/login',
        '/account/forgotpassword': '/account/forgotpassword',
        '/account/resetpassword': '/account/resetpassword',
        '/account/overview': '/account/overview',
        '/account/orders': '/account/orders',
        '/account/profile': '/account/profile',
        '/account/profile/edit': '/account/profile/edit',
        '/account/profile/editpassword': '/account/profile/editpassword',
        '/cart': '/cart',
        '/search': '/search',
        '/promotions': '/acties',
        '/checkout': '/checkout',
        '/checkout/addresses': '/checkout/addresses',
        '/checkout/shipment': '/checkout/shipment',
        '/checkout/summary': '/checkout/summary',
        '/checkout/payment': '/checkout/payment',
        '/checkout/payment/adyen/redirect/return': '/checkout/payment/adyen/redirect/return',
        '/checkout/success': '/checkout/success',
        '/register': '/register',
        '/register/companycheck': '/register/companycheck',
        '/register/selectcompany': '/register/selectcompany',
        '/register/details': '/register/details',
        '/register/verification': '/register/verification',
        '/register/success': '/register/success',
        '/termsandconditions':  '/algemene-voorwaarden',
    }
}