import IconWrapper from '../wrapper/IconWrapper';

const CheckIcon = ({ size = 24, color = null, direction='top', className = null, title = "checkmark-icon" }) => {
    return (
        <IconWrapper size={size} color={color} direction={direction} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14.558" height="11.156" viewBox="0 0 14.558 11.156">
                <title>{title}</title>
                <path id="check" d="M18.058,6.763,8.075,16.746,3.5,12.17,4.673,11l3.4,3.394,8.81-8.8Z" transform="translate(-3.5 -5.59)" fill="#65b32e"/>
            </svg>
        </IconWrapper>
    );
}

export default CheckIcon;