import { ProductImage, Price } from '@kega/sps-components';
import { useConfig, useUpdateCart } from '@kega/sps-core';
import { Loader } from '@kega/sps-elements';

import Counter from '../../../components/counter/Counter';
import { MinIcon, MaxIcon } from '../../../components/icons';

import useUserId from "../../../hooks/useUserId";

import classes from './CartItem.module.css';

const CartItem = ({ product, totalPrice, quantity, entryNumber }) => {
    const { updateCart, loading: loadingUpdate } = useUpdateCart();

    const getUserId = useUserId();
    const config = useConfig();
    const locale = config.get('locale');
    const currency = config.get('currency');

    const { code, name, stock = {}, images = [], assortmentMinQuantity } = product;
    const stockLevel = stock.stockLevel || 9999;
    const minQuantity = assortmentMinQuantity || 1;
    const productName = product?.formattedName || product?.name;

    const onQtyChange = (value) => {
        updateCart({
            product: { code: code },
            quantity: value,
            entryNumber: entryNumber,
            userId: getUserId()
        });
    };


    return (
        <div className={classes.root}>
            
            <div className={classes.image}>
                <ProductImage
                    src={(images[0] ? images[0].url : '/images/placeholder.png')}
                    width={256}
                    height={256}
                    alt={name}
                />
            </div>

            <div className={classes.details}>

                <div className={classes.name}>
                    { productName }
                </div>

                <div style={{ display:'flex', alignItems:'center' }}>
                    <div className={classes.quantity}>
                        <Counter
                            name="quantity"
                            value={quantity}
                            min={minQuantity}
                            max={stockLevel}
                            step={minQuantity}
                            parentCallback={onQtyChange}
                            trigger="blur"
                            minComponent={MinIcon}
                            maxComponent={MaxIcon}
                        />
                    </div>
                    <div className={classes.price}>
                        <Price
                            price={totalPrice.value}
                            currency={currency}
                            locale={locale}
                            currencySymbol={false}
                        />
                    </div>
                </div>

            </div>

            { loadingUpdate ? <div className={classes.loading}><Loader variant="primary" /></div> : null }

        </div>
    );
}

export default CartItem;