const env = (key, fallback='') => {
    return (process.env.hasOwnProperty('REACT_APP_' + key) ? process.env['REACT_APP_' + key] : fallback);
}

const config = {

    authentication: {
        grant_type: 'password',
        client_id: env('OCC_CLIENT_ID'),
        client_secret: env('OCC_CLIENT_SECRET')
    },

    occ: {
        url: env('OCC_URL'),
        sc_url: env('OCC_SC_URL')
    },

    algolia: {
        index: env('ALGOLIA_APP_INDEX', 'nonfood'),
        appId: env('ALGOLIA_APP_ID', 'testing3KOQO06MWN'),
        apiKey: env('ALGOLIA_API_KEY', '3817bdd5280ff6323f703addb47ca346')
    },

    adyen: {
        environment: env('ADYEN_ENV', 'test'),
        clientKey: env('ADYEN_CLIENT_KEY', 'test_KKA3G4RRPBAAPC27PPS57TS6XE3X4WV3'),
    },

    google: {
        tagmanagerkey: env('GTM_KEY'),
        recaptchakey: env('RECAPTCHA_KEY', '6Lex0fMfAAAAAFWbf5GmwFruW_0bu321R6D_b0ir'),
    },

    facebook: {
        domain_verification_key: env('DOMAIN_VERIFICATION_KEY', 'xs29fqqu5pzexs757x8ycos8a8lp8b'),
    },

    bugsnag: {
        key: env('BUGSNAG_KEY', null),
    },

    trustPilot: {
        apiKey: env('TRUSTPILOT_API_KEY'),
        businessUnitId: env('TRUSTPILOT_BUSINESS_ID'),
        productReviewLimit: 10
    },

    productlist: {
        pagination: {
            defaultPerPage: 24,
            perPage: [ 20, 50, 100 ]
        },
        sort: [
            { key: '' },
            { key: '_abc' },
            { key: '_price_asc' },
            { key: '_price_desc' }
        ]
    },
    
    defaultStoreCode: 'nl_nl',

    form: {
        validation: {
            zipcode: {
                error: 'form.error.zipcode',
                BE: {
                    pattern: '^[1-9]\\d{3}$',
                },
                NL: {
                    pattern: '^[1-9]\\d{3} [A-Za-z]{2}$'
                },
            },
        }
    },

    storeViews: {
        
        nl_nl: {
            name: 'Nederland (nl)',
            locale: 'nl-NL',
            country: 'nl',
            currency: 'EUR',
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            baseUrl: 'https://www.hanosshop.com',
            baseSiteId: 'nonfood-nl'
        },

        be_nl: {
            name: 'België (nl)',
            locale: 'nl-BE',
            country: 'be',
            currency: 'EUR',
            url: {
                prefix_product: 'p',
                prefix_category: 'c'
            },
            baseUrl: 'https://www.hanosshop.com',
            baseSiteId: 'nonfood-be'
        }
    }

} 

export default config;