import { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import classNames from 'classnames';

import useMediaQuery from '../../hooks/useMediaQuery';

import classes from './ReadMore.module.css';

const ReadMore = ({ children, className, height=200, background='rgba(255, 255, 255, 1)', labelMore='Toon meer', labelLess='Toon minder', labelClassName=null, footerClassName=null,  mobileOnly=false }) => {
    const [ open, setOpen ] = useState(false);
    const [ readMore, setReadMore ] = useState(true);
    const { match } = useMediaQuery('(min-width: 768px)');
    const contentRef = useRef(null);
    const readmoreRef = useRef(null);
    const location = useLocation();

    useEffect(() => {

        if (contentRef.current?.scrollHeight > height) {
            setReadMore(true);
            if (!open) {
                contentRef.current.style.transition = 'height 0.4s ease';
                contentRef.current.style.height = height+'px';
            } else {
                contentRef.current.style.transition = 'height 0.4s ease';
                contentRef.current.style.height = (readmoreRef.current.scrollHeight + contentRef.current.scrollHeight-1) + 'px';
            }
        } else {
            setReadMore(false);
        }

    }, [ open, children ]);

    useEffect(() => {
        setOpen(false);
        if (contentRef.current) {
            contentRef.current.style.transition = '';
            contentRef.current.style.height = '';
        }
    }, [location]);

    const toggle = () => {
        setOpen(!(open));
    }

    if (mobileOnly && match) {
        return children;
    }

    return (
        <div className={classNames(classes.root, className)}>

            <div ref={contentRef}>
                { children }
            </div>

            {
                readMore
                &&
                <div className={classes.footer} onClick={toggle}> 

                    <div className={classNames(classes.fade, (open ? classes.fade_open : null)) } style={{ background: 'linear-gradient(rgba(255, 255, 255, 0), '+background+')' }} />

                    <div ref={readmoreRef} className={classNames(classes.reademore, labelClassName)} style={{ background: background }} >
                        {
                            !open
                                ?
                                <span>{ labelMore }</span>
                                :
                                <span>{ labelLess }</span>
                        }
                    </div>
                
                </div>
            }

        </div>
    )
}

export default ReadMore;