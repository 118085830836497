const ShopOnly = ({ className }) => {
    return (
        <div className={className}>
            <svg id="Laag_2" data-name="Laag 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 411.8 60">
                <title>HanosShop Only</title>
                <g id="Laag_1-2" data-name="Laag 1">
                    <g>
                        <rect fill="#f6a31c" strokeWidth="0" width="411.8" height="60" rx="6" ry="6"/>
                        <g>
                            <path fill="#fff" strokeWidth="0" d="m49.01,16.99v27.63h-5.21v-11.6h-11.36v11.6h-5.17v-27.63h5.17v11.56h11.36v-11.56h5.21Z"/>
                            <path fill="#fff" strokeWidth="0" d="m70.86,38.35h-10.46l-1.97,6.27h-5.41l9.35-27.63h6.68l9.35,27.63h-5.58l-1.97-6.27Zm-1.31-4.22l-.37-1.23c-3.12-10-3.53-12.05-3.53-12.05,0,0-.37,2.05-3.53,12.1l-.37,1.19h7.79Z"/>
                            <path fill="#fff" strokeWidth="0" d="m82.38,16.99h5.86l6.64,11.52c3.73,6.4,5.12,9.76,5.12,9.76,0,0-.33-3.4-.33-9.76v-11.52h5.21v27.63h-5.9l-6.72-11.48c-3.61-6.07-5.17-9.92-5.17-9.92,0,0,.45,3.9.45,9.88v11.52h-5.17v-27.63Z"/>
                            <path fill="#fff" strokeWidth="0" d="m122.52,16.49c8.08,0,13.08,5.66,13.08,14.23s-5.08,14.19-13.04,14.19-13.08-5.66-13.08-14.19,5.08-14.23,13.04-14.23Zm.04,23.66c4.67,0,7.5-3.61,7.5-9.43s-2.83-9.47-7.54-9.47-7.5,3.65-7.5,9.47,2.95,9.43,7.54,9.43Z"/>
                            <path fill="#fff" strokeWidth="0" d="m155.61,23.14c-1.85-1.11-4.31-1.84-6.56-1.84-2.62,0-4.1,1.31-4.1,3.07,0,1.97,2.09,3.03,4.67,4.18,3.48,1.52,7.3,3.36,7.3,8.32s-3.94,8.08-9.59,8.08c-2.79,0-5.74-.62-7.75-1.76v-5.29c2.38,1.72,5.08,2.54,7.71,2.54,2.46,0,4.18-1.27,4.18-3.4s-2.38-3.12-5.37-4.47c-3.2-1.48-6.72-3.32-6.72-8s3.77-7.79,9.51-7.79c2.17,0,4.67.37,6.72,1.35v5Z"/>
                            <path fill="#fff" strokeWidth="0" d="m187.01,23.14c-1.85-1.11-4.31-1.84-6.56-1.84-2.62,0-4.1,1.31-4.1,3.07,0,1.97,2.09,3.03,4.67,4.18,3.48,1.52,7.3,3.36,7.3,8.32s-3.94,8.08-9.59,8.08c-2.79,0-5.74-.62-7.75-1.76v-5.29c2.38,1.72,5.08,2.54,7.71,2.54,2.46,0,4.18-1.27,4.18-3.4s-2.38-3.12-5.37-4.47c-3.2-1.48-6.72-3.32-6.72-8s3.77-7.79,9.51-7.79c2.17,0,4.67.37,6.72,1.35v5Z"/>
                            <path fill="#fff" strokeWidth="0" d="m214.61,16.99v27.63h-5.21v-11.6h-11.36v11.6h-5.17v-27.63h5.17v11.56h11.36v-11.56h5.21Z"/>
                            <path fill="#fff" strokeWidth="0" d="m232.28,16.49c8.08,0,13.08,5.66,13.08,14.23s-5.08,14.19-13.04,14.19-13.08-5.66-13.08-14.19,5.08-14.23,13.04-14.23Zm.04,23.66c4.67,0,7.5-3.61,7.5-9.43s-2.83-9.47-7.54-9.47-7.5,3.65-7.5,9.47,2.95,9.43,7.54,9.43Z"/>
                            <path fill="#fff" strokeWidth="0" d="m258.64,16.99c6.31,0,10.29,3.85,10.29,9.06,0,5.78-4.47,9.18-10.17,9.18h-3.61v9.39h-5.17v-27.63h8.65Zm-.57,14.06c3.9,0,5.62-2.09,5.62-5s-1.76-4.84-5.49-4.84h-3.03v9.84h2.91Z"/>
                            <path fill="#fff" strokeWidth="0" d="m294.88,16.49c8.08,0,13.08,5.66,13.08,14.23s-5.08,14.19-13.04,14.19-13.08-5.66-13.08-14.19,5.08-14.23,13.04-14.23Zm.04,23.66c4.67,0,7.5-3.61,7.5-9.43s-2.83-9.47-7.54-9.47-7.5,3.65-7.5,9.47,2.95,9.43,7.54,9.43Z"/>
                            <path fill="#fff" strokeWidth="0" d="m312.59,16.99h5.86l6.64,11.52c3.73,6.4,5.12,9.76,5.12,9.76,0,0-.33-3.4-.33-9.76v-11.52h5.21v27.63h-5.9l-6.72-11.48c-3.61-6.07-5.17-9.92-5.17-9.92,0,0,.45,3.9.45,9.88v11.52h-5.17v-27.63Z"/>
                            <path fill="#fff" strokeWidth="0" d="m356.75,40.03v4.59h-15.95v-27.63h5.17v23.04h10.78Z"/>
                            <path fill="#fff" strokeWidth="0" d="m373.11,34.37v10.25h-5.17v-10.09l-9.43-17.55h6.03l1.85,4.02c3.61,7.87,4.1,9.39,4.1,9.39,0,0,.37-1.52,4.22-9.39l1.89-4.02h6.03l-9.51,17.38Z"/>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
};

export default ShopOnly;
