import IconWrapper from '../wrapper/IconWrapper';

const EnveloppeIcon = ({ size = 24, color = null, direction = 'top', className = null }) => {
    return (
        <IconWrapper size={size} color={color} direction={direction} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="42.579" height="39.97" viewBox="0 0 42.579 39.97">
                <path id="Path_494" data-name="Path 494" d="M0,0H42.579V39.97H0Z" fill="none" />
                <path id="Path_495" data-name="Path 495" d="M40.579,8a3.94,3.94,0,0,0-3.858-4H5.858A3.94,3.94,0,0,0,2,8V31.974a3.94,3.94,0,0,0,3.858,4H36.721a3.94,3.94,0,0,0,3.858-4ZM36.721,8,21.29,17.987,5.858,8Zm0,23.977H5.858V11.992L21.29,21.983l15.432-9.991Z" fill="currentColor" transform="translate(0 0)" />
            </svg>
        </IconWrapper>
    );
}

export default EnveloppeIcon;