import IconWrapper from '../wrapper/IconWrapper';

const YoutubeIcon = ({ size = 24, color = null, className = null, title = "youtube-icon" }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26.793" height="18.755" viewBox="0 0 26.793 18.755">
                <title>{title}</title>
                <path id="youtube" d="M12.717,18.4l6.953-4.019-6.953-4.019V18.4M28.2,7.907a13.85,13.85,0,0,1,.375,2.545c.094,1.072.134,2,.134,2.8l.08,1.125a26.616,26.616,0,0,1-.589,6.47,3.139,3.139,0,0,1-2.318,2.318,18.886,18.886,0,0,1-3.55.375c-1.742.094-3.336.134-4.809.134l-2.13.08c-5.613,0-9.11-.214-10.489-.589a3.139,3.139,0,0,1-2.318-2.318A13.85,13.85,0,0,1,2.214,18.3c-.094-1.072-.134-2-.134-2.8L2,14.377a26.616,26.616,0,0,1,.589-6.47A3.139,3.139,0,0,1,4.907,5.589a18.887,18.887,0,0,1,3.55-.375c1.742-.094,3.336-.134,4.809-.134L15.4,5c5.613,0,9.11.214,10.489.589A3.139,3.139,0,0,1,28.2,7.907Z" transform="translate(-2 -5)" fill="#fff"/>
            </svg>
        </IconWrapper>
    );
}

export default YoutubeIcon;


