import IconWrapper from '../wrapper/IconWrapper';

const CloseCircleIcon = ({ size = 24, color = "#b5b5b5", className = null, onClick = () => {} }) => {
    return (
        <IconWrapper size={size} color={color} className={className} onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14.781" height="14.781" viewBox="0 0 14.781 14.781">
                <path d="M9.391,2a7.391,7.391,0,1,0,7.391,7.391A7.384,7.384,0,0,0,9.391,2Zm3.7,10.044-1.042,1.042L9.391,10.433,6.737,13.086,5.7,12.044,8.348,9.391,5.7,6.737,6.737,5.7,9.391,8.348,12.044,5.7l1.042,1.042L10.433,9.391Z" transform="translate(-2 -2)"/>
            </svg>
        </IconWrapper>
    );
}

export default CloseCircleIcon;


