export const getCookie = (key) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${key}=`);

    if (parts.length === 2) {
        return parts.pop().split(';').shift() ?? null;
    }

    return null;
};

export default ({ client, config, tokens }) => {

    const api = {};

    const getUserOrGuestToken = async (userId) => {
        const { token = '' } = (userId === 'current')
            ? await tokens.get('customer')
            : await tokens.get('public');

        return token;
    };

    api.getCheckoutSessionData = async ({ userId, cartId }) => {

        const token = await getUserOrGuestToken(userId);

        return new Promise((resolve, reject) => {

            const headers = {
                'Authorization': 'Bearer ' + token
            }
            
            client.get('/hanosUsers/'+userId+'/carts/'+cartId+'/checkout/payment/adyen/session?lang=en&curr=EUR', null, headers).then((response) => {
                resolve(response);
            }).catch((error) => {
                console.log(error);
                reject(error);
            });
          
        });

    }

    api.authorize = () => {
        return new Promise((resolve, reject) => {

        });
    }

    api.additionalDetails = () => {
        return new Promise((resolve, reject) => {

        });
    }

    api.redirectReturn = () => {
        return new Promise((resolve, reject) => {

        });
    }

    api.cartFromOrder = async ({ orderCode, userId }) => {

        const token = await getUserOrGuestToken(userId);

        return new Promise((resolve, reject) => {

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            client.post('/orgUsers/'+userId+'/cartFromOrder?orderCode='+orderCode+'&fields=FULL', null, headers).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });

        });
    }

    api.placeOrder = async ({ cartId, userId, orderkiosk=null }) => {

        const token = await getUserOrGuestToken(userId);
        const gaCookie = getCookie('_ga');

        let params = '';

        if (orderkiosk !== null) {
            params += `&orderkiosk=${orderkiosk}`;
        }

        if (gaCookie) {
            params += `&ga=${encodeURIComponent(gaCookie)}`;
        }

        return new Promise((resolve, reject) => {

            const headers = {
                'Authorization': 'Bearer ' + token,
            };

            client.post(`/hanosUsers/${userId}/orders/?cartId=${cartId}&fields=FULL${params}`, null, headers).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });

        });
    },

    api.setOrderReference = (reference) => {
        return new Promise(async (resolve, reject) => {
    
            try {

                const { token='' } = await tokens.get('customer');

                let headers = {}
                if (token !== '') {
                    headers = {
                        'Authorization': 'Bearer ' + token
                    }
                }

                const payload = {};

                const response = await client.patch(`/hanosUsers/current/carts/current/checkout/setOrderReference?reference=${reference}`, payload, headers);

                resolve(response)
            } catch (error) {
                console.log('setOrderReference error', error);
                reject(error);
            }
        });
    }

    return api;
};