const EnergyLabelG = ({ className }) => {
    return (
        <div className={className}>
            <svg id="Laag_2" data-name="Laag 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128.28 60">
                <title>Energy Label G</title>
                <g id="Laag_1-2" data-name="Laag 1">
                    <g>
                        <polygon strokeWidth="0" fill="#e30613" points="100.77 0 0 0 0 60 100.77 60 128.28 30 100.77 0"/>
                        <path strokeWidth="0" fill="#fff" d="m50.11,41.16h-.04l.04.45c-1.89,1.35-5.12,2.71-9.43,2.71-8.61,0-14.64-5.33-14.64-14.19s6.31-14.27,14.68-14.27c2.58,0,5.49.41,8.08,1.76v5.29c-2.95-1.76-5.74-2.25-8-2.25-5.53,0-9.23,3.73-9.23,9.47s3.53,9.43,9.31,9.43c1.52,0,2.91-.29,4.14-.74v-6.52h-5.08v-4.43h10.17v13.28Z"/>
                    </g>
                </g>
            </svg>
        </div>
    )
};

export default EnergyLabelG;
