const EnergyLabelF = ({ className }) => {
    return (
        <div className={className}>
            <svg id="Laag_2" data-name="Laag 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128.28 60">
                <title>Energy Label F</title>
                <g id="Laag_1-2" data-name="Laag 1">
                    <g>
                        <polygon strokeWidth="0" fill="#eb5d0b" points="100.77 0 0 0 0 60 100.77 60 128.28 30 100.77 0"/>
                        <path strokeWidth="0" fill="#fff" d="m32.36,20.58v8.16h9.59v4.22h-9.59v11.03h-5.21v-27.63h16.19v4.22h-10.99Z"/>
                    </g>
                </g>
            </svg>
        </div>
    )
};

export default EnergyLabelF;
