import { useEffect } from "react";
import { NavLink } from "react-router-dom";

import { t } from "../../../lib/translations";

import useTranslatePath from "../../../hooks/useTranslatePath";
import useFetchWishlist from "../../../hooks/wishlist/useFetchWishlist";

import { WishlistIcon, WishlistIconFilled } from "../../../components/icons";

import classes from "../Header.module.css";


const Wishlist = ({ loggedIn = false }) => {
    const { getPath } = useTranslatePath();
    const { wishlist, fetchWishlist } = useFetchWishlist();

    const wishlistCount = Object.keys(wishlist).length;

    useEffect( () => {
        if (wishlist.length === 0 && loggedIn) {
            fetchWishlist();
        }
    }, [wishlist])

    return (
        <>
            {
                <NavLink to={getPath('/account/wishlist')} className={classes.iconlink}>
                    {
                        (wishlistCount > 0 && loggedIn)
                            ?
                            <>
                                <WishlistIconFilled size={20}/>
                                <span className={classes.iconlink_quantity}>{ wishlistCount }</span>
                            </>
                            :
                            <WishlistIcon className={classes.wishlist_not_filled_icon} size={21}/>
                    }
                    <span className={classes.iconlink_label}>{t('header.link_wishlist')}</span>
                </NavLink>
            }
        </>
    )
}

export default Wishlist;