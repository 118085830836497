import { Suspense, lazy } from 'react';
import { Loader } from '@kega/sps-elements';

const BuilderContent = lazy(() => import('./BuilderContent'));

const BuilderContentLazy = (props) => {

    return (
        <Suspense fallback={<Loader />}>
            <BuilderContent {...props} />
        </Suspense>
    );
}

export default BuilderContentLazy;