import { useState, useEffect, useRef } from 'react';
import { Form } from '@kega/sps-elements';
import { useAddCoupon } from '@kega/sps-core';

import { t } from '../../../../lib/translations';

import { Row, Col } from '../../../../components/grid';
import Button from '../../../../components/buttons/Button';
import Input from '../../../../components/form/Input';

import classNames from 'classnames';
import classes from './CouponForm.module.css';

const CouponForm = ({ coupons = [], userId }) => {
    const [ active, setActive ] = useState(false);
    const [ coupon, setCoupon ] = useState('');
    const { error, loading, add } = useAddCoupon();
    const timeoutRef = useRef(null);

    useEffect(() => {
        if (coupons.length > 0) {
            timeoutRef.current = setTimeout(() => {
                setActive(false);
                setCoupon('');
            }, 250);
        }

        return () => {
            clearTimeout(timeoutRef.current);
        };

    },[coupons, setActive])

    const submit = async ({ valid }) => {
        if (valid) {
            await add({ coupon, userId });
        }
    }

    const toggleForm = () => {
        if (coupons.length === 0) {
            setActive(!active);
        }
    }

    return (
        <>
            <Form onSubmit={submit} className={classNames(classes.form_row, (coupons.length === 0 && !active) ? classes.no_coupon_state : null, active ? classes.active : null)}>
                <Row className={classes.toggle_row}>
                    <div className={classes.toggle} onClick={toggleForm}>
                        {t('checkout.cart.toggle_form')}
                    </div>
                </Row>
                <Row>
                    <Col md={8}>
                        <Input name="couponCode" value={coupon} onChange={(e) => setCoupon(e.target.value)} required/>
                        {
                            error && 
                            <div className={classes.error}>
                                {t('checkout.cart.error_coupon')}
                            </div>
                        }
                    </Col>
                    <Col md={4}>
                        <Button
                            type="submit"
                            variant="primary"
                            className={classes.button}
                            loading={loading}
                        >
                            {t('checkout.cart.coupon_submit')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}
 
export default CouponForm;