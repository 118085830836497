import { useCallback, useMemo } from 'react';
import { useLocation } from "react-router-dom";

import { useConfig } from '@kega/sps-core';

import paths from '../config/paths';

const useTranslatePath = () => {
    const config = useConfig();
    const { pathname } = useLocation();
    const storeCode = config.get('storeCode');

    const urlPathWithoutStore = useMemo(() => {
        let path = pathname || '';
        let pattern = '(\\/)?(.*)';

        if (storeCode) {
            pattern = '(\\/' + storeCode + ')?' + pattern;
        }

        const regex = new RegExp(pattern);

        return path.replace(regex, '$3');
    }, [storeCode, pathname]);

    const getRoute = useCallback((path, params={}) => {
 
        if (paths[storeCode] && paths[storeCode][path]) {
            let to = paths[storeCode][path];
            if (params?.addStoreCode) { to = `/${storeCode}${to}`; }
            return to;
        } else {
            //console.log(path)
        }

        return path;
    }, []);

    const getPath = useCallback((path, params={}, overideStoreCode=null) => {

        let to = `/${(overideStoreCode !== null ? overideStoreCode : storeCode)}`;

        if (paths[storeCode] && paths[storeCode][path]) {
            let urlpath = paths[storeCode][path];

            Object.keys(params).forEach((key) => {
                urlpath = urlpath.replace(':'+key, params[key]);
            });

            to += urlpath;
        } else {
            to += path;
        }

        return to;
    }, []);

    return {
        urlPathWithoutStore,
        getRoute,
        getPath
    };
}

export default useTranslatePath;