import IconWrapper from '../wrapper/IconWrapper';

const PhoneIcon = ({ size = 24, color = null, direction = 'top', className = null }) => {
    return (
        <IconWrapper size={size} color={color} direction={direction} className={className}>
            <svg id="call_black_24dp" xmlns="http://www.w3.org/2000/svg" width="39.975" height="39.975" viewBox="0 0 39.975 39.975">
                <path id="Path_492" data-name="Path 492" d="M0,0H39.975V39.975H0Z" fill="none" />
                <path id="Path_493" data-name="Path 493" d="M8.9,6.331a20.508,20.508,0,0,0,.75,4.314l-2,2A24.694,24.694,0,0,1,6.381,6.331H8.9M25.319,26.352a21.241,21.241,0,0,0,4.331.75v2.482a25.694,25.694,0,0,1-6.329-1.249l2-1.982M10.5,3H4.666A1.671,1.671,0,0,0,3,4.666,28.313,28.313,0,0,0,31.315,32.981a1.671,1.671,0,0,0,1.666-1.666V25.5a1.671,1.671,0,0,0-1.666-1.666,19,19,0,0,1-5.946-.949,1.4,1.4,0,0,0-.516-.083,1.706,1.706,0,0,0-1.183.483l-3.664,3.664A25.232,25.232,0,0,1,9.03,15.975l3.664-3.664a1.672,1.672,0,0,0,.416-1.7,18.922,18.922,0,0,1-.949-5.946A1.671,1.671,0,0,0,10.5,3Z" transform="translate(1.997 1.997)" fill="currentColor" />
                <path id="Path_500" data-name="Path 500" d="M8.9,6.331a20.508,20.508,0,0,0,.75,4.314l-2,2A24.694,24.694,0,0,1,6.381,6.331H8.9M25.319,26.352a21.241,21.241,0,0,0,4.331.75v2.482a25.694,25.694,0,0,1-6.329-1.249l2-1.982M10.5,3H4.666A1.671,1.671,0,0,0,3,4.666,28.313,28.313,0,0,0,31.315,32.981a1.671,1.671,0,0,0,1.666-1.666V25.5a1.671,1.671,0,0,0-1.666-1.666,19,19,0,0,1-5.946-.949,1.4,1.4,0,0,0-.516-.083,1.706,1.706,0,0,0-1.183.483l-3.664,3.664A25.232,25.232,0,0,1,9.03,15.975l3.664-3.664a1.672,1.672,0,0,0,.416-1.7,18.922,18.922,0,0,1-.949-5.946A1.671,1.671,0,0,0,10.5,3Z" transform="translate(1.997 1.997)" fill="currentColor" />
            </svg>
        </IconWrapper>
    );
}

export default PhoneIcon;