import IconWrapper from '../wrapper/IconWrapper';

const HamburgerIcon = ({ size = 24, color = null, className = null, onClick = () => {} }) => {
    return (
        <IconWrapper size={size} color={color} className={className} onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="24" height="24" viewBox="0 0 24 24">
                <path id="Path_448" data-name="Path 448" d="M0,0H24V24H0Z" fill="none"/>
                <path id="Path_449" data-name="Path 449" d="M3,18H21V16H3Zm0-5H21V11H3ZM3,6V8H21V6Z" />
            </svg>
        </IconWrapper>
    );
}

export default HamburgerIcon;


