import { useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Form, Loader } from '@kega/sps-elements';

import { Col, Row } from '../../../components/grid';
import { t } from '../../../lib/translations';

import useTranslatePath from '../../../hooks/useTranslatePath';
import useRegister from '../../../hooks/useRegister';
import useOccRegister from "../../../hooks/useOccRegister";
import useTagManager from '../../../hooks/useTagManager';

import ErrorMessage from '../../../components/messages/ErrorMessage';

import Input from '../../../components/form/Input';

import classes from './Verification.module.css';

const Verification = () => {
    const { mode, user, company } = useRegister();

    const navigate = useNavigate();
    const { setBreadcrumb  } = useOutletContext();

    const { loading, error, loadingReCaptcha, register } = useOccRegister();

    const { getPath } = useTranslatePath();
    const { dataLayer } = useTagManager();

    useEffect(() => {
        if (company === null || user === null || mode === null) {
            navigate(getPath('/register/companycheck'));
        }
    }, [company, mode, user])

    useEffect(() => {
        setBreadcrumb([
            { name: 'Home', url_key: '/' },
            { name: 'Registratie', url_key: '/register/companycheck' },
            { name: t('register.verification.header') }
        ]);
    }, []);

    const onlyNumbers = (value) => {
        return value.replace(/[^0-9+]/g, '');
    };

    const onSubmit = async ({ valid, values, resetForm }) => {
        const onSuccess = () => {
            resetForm();

            try {
                dataLayer.push({
                    'event': 'sign_up',
                    'method': 'normal'
                });
            } catch (error) {
                //
            }

            navigate(getPath('/register/success'));
        }

        if (valid) {
            const { code } = values;

            register(company, user, onSuccess, code);
        }
    }

    const renderLoading = () => {
        return (
            <div>
                <span className={classes.label}>
                    {t("register.verification.btn_send")}
                </span>
                <div className={classes.loader}><Loader size={24} style={{ color: 'inherit' }} /></div>
            </div>
        );
    }
    
    return (
        <>
            <h1>{ t('register.verification.header') }</h1>

            {
                error !== null
                &&
                <ErrorMessage>
                    { 
                        Array.isArray(error)
                            ?
                            <>
                                {
                                    error.map(({ message }, index) => {
                                        return <span key={'e'+index}>{ message }</span>
                                    })
                                }
                            </>
                            :
                            error.message 
                    }
                </ErrorMessage>
            }
            
            <Form onSubmit={onSubmit}>
                <div className={classes.form}>
                    <Row>
                        <Col md="12">
                            <h4 className={classes.header}>
                                {t("register.verification.form_header")}
                            </h4>
                            <p>{t("register.verification.form_text")}</p>
                        </Col>
                        <Col md="7">
                            <Input
                                modifiers={[onlyNumbers]}
                                name="code"
                                placeholder={t("register.verification.code_placeholder")}
                                label={t("register.verification.code_label")}
                                required
                                className={classes.input}
                                maxLength={4}
                            />
                        </Col>
                        <Col md="12">
                            <button type="submit" className={classes.button} disabled={loadingReCaptcha || loading}>
                                {(loadingReCaptcha || loading) ? renderLoading() : t("register.verification.btn_send")}
                            </button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </>
    );
} 

export default Verification;