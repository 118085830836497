import { useConfig } from '@kega/sps-core';

import classes from './PaymentMethods.module.css';

const PaymentMethods = () => {
    const config = useConfig();
    const country = config.get('country');

    return (
        <div className={classes.root} >
            <img loading="lazy" src="/images/ideal.svg" alt="ideal" width="27" height="24" />
            <img loading="lazy" src="/images/american-express.svg" alt="american express" width="21" height="21" />
            <img loading="lazy" src="/images/visa.svg" alt="visa" width="57" height="24" />
            <img loading="lazy" src="/images/mastercard.svg" alt="mastercard" width="31" height="24" />
            <img loading="lazy" src="/images/applepay.svg" alt="apple pay" width="37" height="24" />
            {
                country === 'be' && <img loading="lazy" src="/images/bancontact.svg" alt="bancontact" width="32" height="24" />
            }
            {
                country === 'de' && <img loading="lazy" src="/images/klarna.svg" alt="klarna" width="59" height="24" />
            }
            <img loading="lazy" src="/images/postnl.svg" alt="postnl" width="24" height="24" />
        </div>
    );
}

export default PaymentMethods;