import { useConfig } from '@kega/sps-core';

const useProductStructuredData = (product) => {
    const config = useConfig();
    const storeCode = config.get('storeCode');
    const currency = config.get('currency');
    const { prefix_product } = config.get('url');

    if (!product) { return null; }

    return {
        "@type": "Product",
        "name": product?.formattedName || product?.name,
        "image": 
        product?.images?.length > 0 ?
            product?.images?.map((img) => { return img.url })
            :
            [
                product['img-256Wx256H'],
            ],
        "url": window.location.host + `/${storeCode}/${prefix_product}/${product.code}`,
        "brand": {
            "@type": "Brand",
            "name": product?.Merk || product?.manufacturer
        },
        "offers": {
            "@type": "Offer",
            "price": product?.Prijs ? product.Prijs : product.price?.value ? product.price.value : product.price,
            "priceCurrency": currency,
            "availability": product.stockLevelStatus
        }
    };
};

export default useProductStructuredData;