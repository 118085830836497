import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useConfig } from '@kega/sps-core';

import qs from 'qs';

import { t } from '../../lib/translations';
import { default as ProductListModule } from '../../modules/algolia/ProductList.js';

import useTranslatePath from '../../hooks/useTranslatePath';

const Search = () => {
    const config = useConfig();
    const { search } = useLocation();
    const { getPath } = useTranslatePath();

    const baseUrl = config.get('baseUrl');
    const storeViews = config.get('storeViews');

    const { q } = qs.parse(search.replace('?', ''), { comma: true })

    return (
        <>
            <Helmet>
                <title>{ t('search.page_title') +': '+ q }</title>
                <meta name="robots" content="noindex, follow" />

                <link rel="canonical" href={`${baseUrl}${getPath('/search')}`} />

                {
                    storeViews
                    &&
                    Object.keys(storeViews).map((storeCode) => {
                        const href = getPath('/search', {}, storeCode);
                        const lang = storeCode.split('_')[0] === 'be' ? storeCode.split('_').reverse().join('-') : storeCode.split('_').join('-');

                        return <link key={href} rel="alternate" hrefLang={ lang } href={window.location.origin + href} />
                    })
                }

            </Helmet>

            <ProductListModule 
                title={t('search.page_title') +': '+ q}
                query={q}
            />

        </>
    )
}

export default Search;