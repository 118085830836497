import { useEffect, useRef, useState } from "react";

import { t } from "../../../../../lib/translations";
import useI18n from "../../../../../hooks/useI18n";

import ReviewStars from "../../../../../components/reviews/reviewstars/ReviewStars";

import classes from './Review.module.css';
import classNames from "classnames";

const Review = ({ review }) => {
    const { formatDate } = useI18n();
    const descriptionRef = useRef();
    const [ showMore, setShowMore ] = useState(null);

    useEffect(() => {
        if (descriptionRef.current?.scrollHeight > 60) {
            setShowMore(false);
        }
    }, []);

    const toggleShowMore = () => {
        setShowMore((prevShowMore) => !prevShowMore);
    };

    return (
        <div className={classes.root}>
            <div className={classes.score}>
                <ReviewStars score={review.stars}/>
                <span className={classes.score_label}>{review.stars} {t(review.stars === 1 ? 'reviews.star' : 'reviews.stars')}</span>
            </div>
            <div className={classes.description}>
                <div className={classNames(classes.text, showMore ? classes.text_full : '')} ref={descriptionRef}>{review.content}</div>
                {
                    showMore !== null && (
                        <div onClick={toggleShowMore}>
                            {
                                showMore
                                    ? <span className={classes.show_more}>{t('productdetails.reviews.show_less')}</span>
                                    : <span>... <span className={classes.show_more}>{t('productdetails.reviews.show_more')}</span></span>
                            }
                        </div>
                    )
                }
            </div>
            <div className={classes.info}>
                {
                    review.consumer?.displayName && (
                        <span>{review.consumer.displayName}</span>
                    )
                }
                {
                    review.createdAt && (
                        <span>{formatDate(review.createdAt)}</span>
                    )
                }
            </div>
        </div>
    ); 
};

export default Review;
