import { Helmet } from 'react-helmet-async';
import { useCart } from '@kega/sps-core';

import { t } from '../../lib/translations';

import { default as CartModule } from '../../modules/cart/Cart';

const Cart = () => {
    
    const { items, totals, coupons } = useCart();
    
    return (
        <>
            <Helmet>
                <title>{ t('cart.page_title') }</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <CartModule items={items} totals={totals} coupons={coupons} />
        </>
    )
}

export default Cart;