import { Slider, SliderItem } from '../../components/slider';
import ProductTile from '../../components/product/ProductTile';

import useMediaQuery from '../../hooks/useMediaQuery';

import SliderButton from './SliderButton';

import classes from './ProductSlider.module.css';

const ProductSlider = ({ products = [], hideReviews = false }) => {

    const { match: desktop } = useMediaQuery('(min-width: 768px)');

    return (
        <Slider
            scrollbar={desktop ? false : true}
            ComponentNext={<SliderButton type="next" />}
            ComponentPrev={<SliderButton type="prev" />}
        >
            {
                products.map((product, index) => {
                    return (
                        product.code !== undefined &&
                        <SliderItem key={product.code + '' + index} className={classes.item}>
                            <ProductTile product={product} hideReviews={hideReviews} />
                        </SliderItem>
                    )
                })
            }
        </Slider>
    )
}

export default ProductSlider;