import { connectCurrentRefinements } from 'react-instantsearch-dom';

import { t } from '../../../lib/translations';

import classes from './ClearFilters.module.css';

const ClearFilters = ({ items, refine }) => {

    return (
        items.length > 0 && 
        <button className={classes.root} onClick={() => refine(items)} disabled={!items.length}>
            {t('productlist.filter.clear') }
        </button>
    );
};

export default connectCurrentRefinements(ClearFilters);