import { connectInfiniteHits } from 'react-instantsearch-dom';

import useProductStructuredData from '../../../hooks/useProductStructuredData';

const StructuredData = ({ title, hits }) => {
    const data = {
        "@context": "https://schema.org/",
        "@type": "ItemList",
        "name": title,
        "numberOfItems": hits.length,
        "itemListElement": [
            ...(hits.map((product) => {
                const productData = useProductStructuredData(product);
        
                return { 
                    "@type": "ListItem",
                    "position": product.__position,
                    "item": { ...(productData) }
                };
        
            }))
        ], 
        
    };

    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }} />
    )
}

export default connectInfiniteHits(StructuredData);