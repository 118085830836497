import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useConfig, useCustomer } from '@kega/sps-core';

import { t } from '../../../lib/translations';

import paths from '../../../config/paths';

import classes from './Progressbar.module.css';

import Step from './Step';

const Progressbar = () => {
    const { authenticated = false } = useCustomer();
    const [current, setCurrent] = useState(authenticated ? 20 : 17);
    const location = useLocation();
    const config = useConfig();
    const storeCode  = config.get('storeCode');

    const steps = {
        '/checkout/addresses': { key: 'addresses', value: 20, label: t('checkout.steps.addresses') },
        '/checkout/shipment': { key: 'shipment', value: 40, label: t('checkout.steps.shipment') },
        '/checkout/summary': { key: 'summary', value: 60, label: t('checkout.steps.summary') },
        '/checkout/payment': { key: 'payment', value: 80, label: t('checkout.steps.payment') }
    };

    useEffect(() => {
        let path = location.pathname.replace('/'+storeCode, '');
        let routePath = Object.keys(paths[storeCode]).find((key) => {
            return paths[storeCode][key] === path;
        })
 
        const step = steps[routePath];
        
        if (step) {
            setCurrent(step.value);
        }

    }, [location]);


    return (
        <div className={classes.root}>
            <progress className={classes.progress} max="100" value={current}></progress>
            <div className={classes.steps}>
                {
                    Object.values(steps).map(({ key, value, label }) => {
                        return (
                            <Step
                                key={key}
                                value={value}
                                active={current === value}
                                complete={current > value}
                            >
                                <span>{ label }</span>
                            </Step>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default Progressbar;