import { useEffect } from "react";
import { useStoreErrors, useLogout, useClearCart } from '@kega/sps-core';
import { useNavigate } from "react-router-dom";

import useTranslatePath from '../../hooks/useTranslatePath';
import usePublicToken from "../../hooks/usePublicToken";

const Errors = () => {
    const navigate = useNavigate();
    const logout = useLogout();
    const clearCart = useClearCart();
    const { listenError } = useStoreErrors();
    const { getPath } = useTranslatePath();
    const { getPublicToken } = usePublicToken();

    useEffect(() => {

        const removeErrorListener = listenError( async ({ detail }) => {

            const { status, data = null } = detail;
            const error = data?.errors ? data.errors[0] : null;

            if (status === 401) {
                // Logout if token is expired
                logout();
                await getPublicToken();
                // navigate(getPath('/account/login'));
            } else if (error?.subjectType === 'cart' && error?.reason === 'notFound') {
                // Clear cart/checkout session if cart no longer exists
                clearCart();
            }

        });

        return () => {
            removeErrorListener();
        }

    }, [ listenError, getPublicToken, logout, navigate, getPath, clearCart ]);

    return null;
}

export default Errors;