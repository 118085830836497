import SliderButton from "../../../sliders/SliderButton";
import { Slider, SliderItem } from "../../../../components/slider";
import Review from "./review/Review";

import classes from './ReviewSlider.module.css';

const ReviewSlider = ({ reviews }) => {
    if (!reviews?.length) {
        return null;
    }

    return (
        <div className={classes.root}>
            <Slider
                className={classes.slider}
                noControlsClassName={classes.slider_no_controls}
                scrollbar={false}
                itemsToShow={3}
                mobileItemsToShow={1}
                ComponentNext={<SliderButton type="next" />}
                ComponentPrev={<SliderButton type="prev" />}
            >
                {
                    reviews.map((review, index) => {
                        return (
                            <SliderItem key={index} className={classes.item}>
                                <Review review={review} />
                            </SliderItem>
                        )
                    })
                }
            </Slider>
        </div>
    );
};

export default ReviewSlider;
