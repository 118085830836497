import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addProduct } from '../redux/recentlyviewed/actions';

const useRecentlyViewed = (product) => {
    const dispatch = useDispatch();

    const products = useSelector((state) => state.recentlyviewed);

    useEffect(() => {
        if (product !== null && product !== undefined && product.code !== undefined) {
            dispatch(addProduct(product));
        }
    }, [product])

    return {
        products
    };
}

export default useRecentlyViewed;