import { useMemo } from 'react';

import { useSelector } from 'react-redux';


const useNavigationPath = (type, url_key = '') => {
    
    const navigation = useSelector((state) => state.navigation.navigation);

    const find = (nav) => {
        
        const path = [];

        const recursive = (nodes, level) => {
            
            if (level === undefined) {
                level = 0;
            } else {
                level++;
            }
            
            for (let i = 0; i < nodes.length; i++) {
                const node = nodes[i];
                
                path[level] = node;

                if (node.children) {
                    const found = recursive(node.children, level);
                    if (found) { return found; }
                }
                
                if (node.category_code === url_key) {
                    return node;
                }

                path.pop();
            }

            return null;
        }

        if (Array.isArray(nav)) {
            recursive(nav);
        }

        return path;
    };


    const path = useMemo(() => {

        if (!url_key || !navigation[type]) {
            return [];
        }

        return find(navigation[type]);
    }, [navigation, type, url_key]);


    return path;
}

export default useNavigationPath;