import { useRemoveCoupon } from '@kega/sps-core';
import { Loader } from '@kega/sps-elements';

import { t } from '../../../lib/translations';

import useUserId from '../../../hooks/useUserId';

import CouponForm from './form/CouponForm';

import { Container } from '../../../components/grid';
import { CloseIcon, CouponIcon } from '../../../components/icons';

import classes from './Coupon.module.css';

const Coupon = ({ coupons = [] }) => {
    const { remove, loading } = useRemoveCoupon();
    const getUserId = useUserId();

    const userId = getUserId();

    const deleteCoupon = async (coupon) => {
        await remove({ coupon, userId });
    }

    return ( 
        <Container className={classes.root}>
            <CouponIcon size={25} className={classes.icon} />
            <CouponForm coupons={coupons} userId={userId} />

            {
                coupons.length > 0 
                &&
                <div className={classes.coupons_wrapper}>
                    {
                        coupons.map((coupon) => {
                            return (
                                <div key={coupon.code}>
                                    {
                                        loading && <div className={classes.loader}><Loader /></div>
                                    }
                                    <div onClick={() => deleteCoupon(coupon.code)} className={classes.coupon}>{t('checkout.cart.coupon')} {coupon.code} <CloseIcon className={classes.delete_icon} size={16} /></div>
                                    <div className={classes.valid}>{t('checkout.cart.coupon_valid')}</div>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </Container>
    );
}

export default Coupon;