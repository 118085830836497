import IconWrapper from '../wrapper/IconWrapper';

const MinIcon = ({ size = 24, color = null, direction='top', className = null }) => {
    return (
        <IconWrapper size={size} color={color} direction={direction} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path id="Path_453" data-name="Path 453" d="M0,0H24V24H0Z" fill="none"/>
                <path id="Path_454" data-name="Path 454" d="M19,13H5V11H19Z" fill="currentColor"/>
            </svg>
        </IconWrapper>
    );
}

export default MinIcon;