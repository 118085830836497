import { NavLink } from 'react-router-dom';
import { useConfig } from '@kega/sps-core';

import classNames from 'classnames';

import links from './links.json';
import classes from './MetaNavigation.module.css';

const MetaNavigation = ({ className, onClick }) => {

    const config = useConfig();

    const storeCode = config.get('storeCode');

    const rootClassName = classNames(classes.root, className);

    return (
        <nav className={rootClassName}>         
            {
                links[storeCode] &&
                links[storeCode].map(({ name, url_key }, index) => {
                    return <NavLink key={String('nav'+index)} to={url_key} onClick={onClick}>{ name }</NavLink>
                })
            }
        </nav>
    );
}

export default MetaNavigation;