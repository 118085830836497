import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useConfig } from '@kega/sps-core';

import { isScUrl } from "./lib/utils/deployment";

import DefaultLayout from './layouts/DefaultLayout';
import NotFound from './pages/notfound/NotFound';

import ScrolltoTop from './components/scrolltotop/ScrolltoTop';
import UserToken from "./modules/algolia/usertoken/UserToken";

import AppRoutes from './router/AppRoutes';

import aa from 'search-insights'

const App = () => {

    const config = useConfig();
    
    const { appId, apiKey } = config.get('algolia');
  
    const storeviews = config.get('storeViews');

    useEffect(() => {
        aa('init', {
            appId: appId,
            apiKey: apiKey
        });
    }, []);

    return (
        <BrowserRouter>
            <Helmet>
                {
                    isScUrl() && <meta name="robots" content="noindex, nofollow" />
                }
            </Helmet>
            <Routes>
                <Route path="/" element={<Navigate replace to={"/" + config.get('defaultStoreCode')} />} />
                {
                    Object.keys(storeviews).map((storecode) => {
                        return <Route key={storecode} path={'/' + storecode + '/*'} element={<AppRoutes />} />;
                    })
                }
                <Route path="*" element={<DefaultLayout />} >
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
            <ScrolltoTop />
            <UserToken/>
        </BrowserRouter>
    );
}

export default App;