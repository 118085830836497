import { useConfig } from '@kega/sps-core';

import Button from '../buttons/Button';
import { t } from '../../lib/translations';

import Header from './header/Header';
import classes from './UncaughtErrorFallback.module.css';

const UncaughtErrorFallback = ({ error, resetErrorBoundary }) => {
    
    const config = useConfig();
    const storeCode = config.get('storeCode');

    const onClick = () => {
        window.location = '/' + storeCode;
    }

    return (
        <>
            <Header />
            
            <div className={classes.root}>
                <div className={classes.message}>
                    <h1>{ t('uncaught_error.header') }</h1>
                    <p>{ t('uncaught_error.text') }</p>
                    
                    { error.message && <pre className={classes.error}>{ error.message }</pre> }
                    
                    <Button variant="primary" onClick={onClick}>
                        { t('uncaught_error.button') }
                    </Button>
                </div>
            </div>
        </>
    )
}

export default UncaughtErrorFallback;