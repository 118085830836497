import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
    token: null,
    expires_in: null,
    expires_at: null,
};

export default createReducer(initialState, {

    [actions.addToken]: (state, action) => {
        const { payload } = action;

        return {
            token: payload?.token,
            expires_in: payload?.expires_in,
            expires_at: payload?.expires_at,
        }
    }

});
