import { useConfig } from '@kega/sps-core';
import { Price } from '@kega/sps-components';

import { t } from '../../../../lib/translations';

import { Col } from '../../../../components/grid';

import classes from './Order.module.css';

const Order = ({ hanosOrderNumber, total, placed }) => {
    const date = new Date(placed).toLocaleDateString('en-GB'); // by design it should be in the format 'dd/mm/yyyy'

    const config = useConfig();
    const locale = config.get('locale');

    return (
        <Col md={8} className={classes.root}>
            <div className={classes.number_date}>
                <div>
                    <span>{t('account.account_overview.banners.orders.order_number')} </span>
                    <span className={classes.order_number}>{hanosOrderNumber} </span>
                    {total?.value &&
                        <span>
                            | <Price price={total.value} locale={locale} currencySymbol={false} />
                        </span>
                    }
                </div> 
                <span>{t('account.account_overview.banners.orders.date')} {date}</span>
            </div>
        </Col>
    );

}

export default Order;