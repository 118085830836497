import { useStorefront, useRequest } from "@kega/sps-core";

const useOrders = () => {
    const { api: { occcustomer } } = useStorefront();

    const { data, error, loading, setRequest, setSuccess, setError } = useRequest();

    const getOrders = async (payload) => {
        try {
            setRequest();

            const result = await occcustomer.getOrders(payload);

            setSuccess(result);

            return result;

        } catch (error) {
            console.log('[GetOrders]', error);
            setError(error);

            return null;
        }
    };

    return {
        orders: data?.orders || [],
        pagination: data?.pagination || {},
        error,
        loading,
        getOrders
    };
};

export default useOrders;
