import { ProductImage, Price } from '@kega/sps-components';
import { useConfig } from '@kega/sps-core';

import classes from './CartItem.module.css';

const CartItem = ({ product, totalPrice, quantity }) => {
    
    const config = useConfig();
    const locale = config.get('locale');
    const currency = config.get('currency');

    const { name, images = [] } = product;
    const productName = product?.formattedName || product?.name;

    return (
        <div className={classes.root}>
            
            <div className={classes.image}>
                <ProductImage
                    src={(images[0] ? images[0].url : '/images/placeholder.png')}
                    width={256}
                    height={256}
                    alt={name}
                />
            </div>

            <div className={classes.details}>

                <div className={classes.name}>
                    { productName }
                </div>

                <div className={classes.quantity}>
                    { quantity } x
                </div>

            </div>

            <div className={classes.price}>
                <Price
                    price={totalPrice.value}
                    currency={currency}
                    locale={locale}
                    currencySymbol={false}
                />
            </div>

        </div>
    );
}

export default CartItem;