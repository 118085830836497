import { useMemo } from 'react';
import { connectDynamicWidgets } from 'react-instantsearch-dom';

import { Accordion } from '@kega/sps-elements';

import { t } from '../../../lib/translations';

import ClearFilters from '../clearfilters/ClearFilters';

import MultiFilter from './types/multi/MultiFilter';
import RangeFilter from './types/range/RangeFilter';
import LevelMenu from './types/levelmenu/LevelMenu';
import ToggleFilter from './types/toggle/ToggleFilter';

import classes from './Filters.module.css';

const Filters = ({ attributesToRender, params = {} }) => {
    const filters = useMemo(() => {
        const components = new Map();

        attributesToRender.map((attribute, index) => {
            let filter;
            const shouldOpen = index < 4;

            if (attribute === 'Prijs') {
                filter = <RangeFilter shouldOpen={shouldOpen} attribute={attribute} precision={2} separatorText="tot" submitText="Bevestig" {...params} />;
            } else if (attribute === 'inPromotion') {
                filter = <ToggleFilter shouldOpen={shouldOpen} attribute={attribute} value={true} label={attribute} {...params} />;
            } else if (attribute === 'Categorieën') {
                const levels = ['mainCategory', 'secondCategory', 'thirdCategory', 'fourthCategory', 'fifthCategory'];
                filter = <LevelMenu shouldOpen={shouldOpen} attribute={levels[params.level]} />;
            } else {
                filter = <MultiFilter shouldOpen={shouldOpen} attribute={attribute} searchable={attribute === 'Merk'} limit={10} showMore={true} showMoreLimit={150} searchText={t('productlist.filter.search', { attribute: attribute })} emptyText={t('productlist.filter.no_results')} />;
            }

            const formattedAttribute = attribute.replace(/-/g, ' '); 

            components.set(formattedAttribute, filter);
        });

        return components;
    }, [attributesToRender]);

    return (
        <div className={classes.root}>
            <ClearFilters />
            <Accordion>
                {
                    attributesToRender.map((attribute, index) => {
                        const formattedAttribute = attribute.replace(/-/g, ' ');
                        return (
                            <div key={index}>{filters.has(formattedAttribute) && filters.get(formattedAttribute)}</div>
                        );
                    })
                }
            </Accordion>
        </div>
    );
};

export default connectDynamicWidgets(Filters);
