import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { t } from '../../lib/translations';

import BuilderSectionLazy from '../../modules/builder/BuilderSectionLazy';

import useTagManager from '../../hooks/useTagManager';
import useTranslatePath from '../../hooks/useTranslatePath';

const NotFound = () => {
    const { dataLayer } = useTagManager();
    const { getPath } = useTranslatePath();

    useEffect(() => {

        try {
            const { pathname, href } = window.location;

            dataLayer.push({
                'event': '404',
                'pathname': pathname,
                'url': href
            });
        } catch (error) {
            //
        }
    }, [])

    return (
        <>
            <Helmet>
                <title>{ t('notfound.page_title') }</title>
                <meta name="description" content={''} />
                <meta name="robots" content="noindex, follow" />
                <meta name="render:status_code" content="404" />
            </Helmet>

            <BuilderSectionLazy model="404-page" url={getPath('/')} />
        </>
    )
}

export default NotFound;