import AnchorScrollItem from "../../../components/product/anchors/AnchorScrollItem";

import { t } from '../../../lib/translations';

import classes from "./Anchors.module.css";

const Anchors = ({ product, relatedNumber, reviewSummary }) => {
    
    const { classifications = [], descriptionHs = "", alternatives = [] } = product;
    return (
        <div className={classes.root}>
            {
                descriptionHs && <AnchorScrollItem label={t('Description')} scrollToId="description" className={classes.anchor} />
            }
            {
                classifications.length > 0 && <AnchorScrollItem label={t('Classifications')} scrollToId="classifications" className={classes.anchor} />
            }
            {
                relatedNumber?.length > 0 && <AnchorScrollItem label={t('Related Products')} scrollToId="related-products-container" className={classes.anchor} />
            }
            {
                (reviewSummary?.numberOfReviews?.total > 0 && reviewSummary?.starsAverage) && <AnchorScrollItem label={t('Reviews')} scrollToId="reviews" className={classes.anchor} />
            }
            {
                alternatives.length > 0 && <AnchorScrollItem label={t('Alternatives')} scrollToId="classifications" className={classes.anchor} />
            }
        </div>
    );
}

export default Anchors;