import { useRequest, useStorefront } from '@kega/sps-core';

const useRemoveAddress = () => {
    const { api: { occcustomer } } = useStorefront();
    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const removeAddress = async (addressId) => {
        try {
            setRequest();
            await occcustomer.removeAddress({
                addressId: addressId
            });
            setSuccess();
        } catch (error) {
            console.log('[RemoveAddress]', error);
            setError(error);
        }
    }

    return {
        error,
        loading,
        removeAddress
    };
}

export default useRemoveAddress;