import { LinkButton as DefaultLinkButton } from '@kega/sps-elements';
import { Link } from "react-router-dom";

import classNames from 'classnames';

import classes from './Button.module.css';

const LinkButton = ({ children, className, variant = 'link', ...props }) => {

    const buttonClassName = classNames(classes.root, classes[variant], className);

    return (
        <DefaultLinkButton className={buttonClassName} variant={variant} linkComponent={Link} {...props} >
            { children }
        </DefaultLinkButton>
    );
}

export default LinkButton;