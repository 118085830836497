export default () => {

    const api = {};

    api.getProductReviewSummary = ({ sku, businessUnitId, apiKey }) => {
        return new Promise((resolve, reject) => {
            fetch(`https://api.trustpilot.com/v1/product-reviews/business-units/${businessUnitId}?sku=${sku}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'apikey': apiKey
                },
            }).then((response) => {
                return response.json();
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    api.getProductReviews = ({ sku, businessUnitId, apiKey, limit }) => {
        return new Promise((resolve, reject) => {
            fetch(`https://api.trustpilot.com/v1/product-reviews/business-units/${businessUnitId}/reviews?sku=${sku}&perPage=${limit}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'apikey': apiKey
                },
            }).then((response) => {
                return response.json();
            }).then((response) => {
                resolve(response?.productReviews);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    return api;
};
