export default ({ client, tokens }) => {

    const api = {};

    api.getWishlist = async () => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }

            client.get('/hanosUsers/current/wishlists/default', null, headers).then((response) => {
                resolve(processWishlistResponse(response));
            }).catch((error) => {
                console.log(error);
                reject(error);
            });

        });

    }

    api.addProductToWishlist = async ({ productCode }) => {

        const { token = '' } = await tokens.get('customer');


        return new Promise((resolve, reject) => {

            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }

            client.post('/hanosUsers/current/wishlists/default/' + productCode, null, headers).then((response) => {
                resolve(processWishlistResponse(response));
            }).catch((error) => {
                console.log(error);
                reject(error);
            });

        });

    }

    api.removeProductFromWishlist = async ({ entryNumber }) => {

        const { token = '' } = await tokens.get('customer');

        return new Promise((resolve, reject) => {

            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }

            client.delete('/hanosUsers/current/wishlists/default/' + entryNumber, null, headers).then((response) => {
                resolve(processWishlistResponse(response));
            }).catch((error) => {
                console.log(error);
                reject(error);
            });

        });

    }

    return api;
};

const processWishlistResponse = (data = {}) => {
    const { entries = [] } = data;

    return entries.reduce(
        (itemsByCode, item) => {
            const { product } = item;

            return (product && product.code)
                ? {
                    ...itemsByCode,
                    [product.code]: item
                } : itemsByCode;
        }, {}
    )
}