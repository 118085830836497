import { Helmet } from 'react-helmet-async';

import { t } from '../../../lib/translations';

import { default as VerificationModule } from '../../../modules/register/verification/Verification';

const Verification = () => {
    return (
        <>
            <Helmet>
                <title>{ t('register.verification.page_title') }</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <VerificationModule />
        </>
    )
}

export default Verification;