import { useDispatch, useSelector } from "react-redux";

import { clearCheckoutData } from "../../redux/checkout/actions";

const useCheckoutData = () => {
    const dispatch = useDispatch();

    const { lastOrderId } = useSelector((state) => state.hanosCheckout);

    const clear = () => {
        dispatch(clearCheckoutData());
    };

    return {
        lastOrderId,
        clear
    }
};

export default useCheckoutData;