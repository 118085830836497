import { connectToggleRefinement } from 'react-instantsearch-dom';

import { AccordionItem, AccordionHeader, AccordionContent, AccordionIcon } from '@kega/sps-elements';

import { t } from "../../../../../lib/translations";

import Checkbox from '../../../../../components/form/Checkbox';

import classes from './Toggle.module.css';

const ToggleFilter = ({
    headingText,
    currentRefinement,
    label,
    count,
    refine,
    createURL,
    shouldOpen,
    attribute
}) => {

    const count_checked = count.checked || 0;
    const count_unchecked = count.unchecked || 0;

    if(count_unchecked === 0){ return null;}

    return (
        <AccordionItem className={classes.filter} resizeOnOpen isOpen={shouldOpen}>
            <AccordionHeader className={classes.heading}>{t('productlist.filter.' + attribute)}<AccordionIcon color="#000" /></AccordionHeader>
            <AccordionContent>
                <div className={classes.root}>
                    {
                        headingText &&
                        <div className={classes.heading}>
                            {headingText}
                        </div>
                    }
                    {
                        <ul className={classes.values}>
                            <li>
                                <a className={classes.link}
                                    href={createURL(!currentRefinement)}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        refine(!currentRefinement);
                                    }}
                                >
                                    <Checkbox
                                        name={label}
                                        className={classes.checkbox}
                                        checked={currentRefinement ? 'checked' : ''}
                                    />

                                    <span className={classes.value}>{label}</span>
                                    <span className={classes.count}>({currentRefinement ? count_checked : count_unchecked})</span>
                                </a>
                            </li>
                        </ul>
                    }
                </div>
            </AccordionContent>
        </AccordionItem>
    );
}

export default connectToggleRefinement(ToggleFilter);