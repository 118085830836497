import { Helmet } from 'react-helmet-async';

import { default as OverviewModule } from '../../../modules/account/overview/Overview';
import { t } from '../../../lib/translations';

const Overview = () => {
    return (
        <>
            <Helmet>
                <title>{ t('account.account_overview.page_title') }</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <OverviewModule />
        </>
    )
}

export default Overview;