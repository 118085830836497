import { useEffect } from 'react';

import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import { useConfig, useFetchCategory } from '@kega/sps-core';

import useCurrentCategory from '../../hooks/useCurrentCategory';
import useTranslatePath from '../../hooks/useTranslatePath';

import { default as ProductListModule } from '../../modules/algolia/ProductList.js';

const ProductListPage = () => {
    const config = useConfig();
    const { category_key } = useParams();

    const { match } = useCurrentCategory(category_key);
    const { fetchCategory, category } = useFetchCategory();
    const { getPath, urlPathWithoutStore } = useTranslatePath();

    const baseUrl = config.get('baseUrl');
    const storeViews = config.get('storeViews');

    useEffect(() => {
        if (category_key) {
            fetchCategory({ category_code: category_key });
        }
    }, [category_key]);

    return (
        <>
            <Helmet>
                <title>{category?.seoTitleHs ? category.seoTitleHs : category?.name}</title>
                <meta name="description" content={category ? category?.seoMetaDescriptionHs?.replace(/<\/?[^>]+(>|$)/g, "") : ' '} />
                
                <link rel="canonical" href={`${baseUrl}${getPath(category?.url)}`} />

                {
                    storeViews
                    &&
                    Object.keys(storeViews).map((storeCode) => {
                        const href = '/' + storeCode + '/' + urlPathWithoutStore;
                        const lang = storeCode.split('_')[0] === 'be' ? storeCode.split('_').reverse().join('-') : storeCode.split('_').join('-');

                        return <link key={href} rel="alternate" hrefLang={ lang } href={window.location.origin + href} />
                    })
                }

            </Helmet>

            <ProductListModule
                title={category?.name}
                description={category?.introductionHs}
                filters={'allCategoryCodes:"' + category_key + '"'}
                level={(match?.level ? match.level : 0 )}
                url= {category?.url}
            />
        </>
    )
}

export default ProductListPage;