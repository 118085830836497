import { Fragment, useState, useCallback, useRef } from 'react';

import classNames from 'classnames';

import { t } from '../../../lib/translations';
import NavItem from '../../../components/navigation/NavItem';

import useMediaQuery from '../../../hooks/useMediaQuery';

import classes from './ShowCollection.module.css';

const renderCols = (children, colnumber) => {
    const cols = [[], [], []];

    children.map(({ name, type, url_key, external, children = [] }, index) => {

        cols[index % colnumber].push(
            <Fragment key={'nav' + index}>
                <NavItem url_key={url_key} type={type} external={external}>{name}</NavItem>
                {
                    <ul>
                        {
                            children.map(({ id, name, type, url_key, external }) => {
                                return <li key={id}><NavItem url_key={url_key} type={type} external={external}>{name}</NavItem></li>
                            })
                        }
                    </ul>
                }
            </Fragment>

        )
    });

    return cols.map((col, index) => {
        return <div key={'c' + index} className={classes.menu_column} >{col}</div>;
    });
}

const ShowCollection = ({ categories }) => {
    const [isMenuVisible, setMenuVisible] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);
    const subMenusHeightsRef = useRef([]);
    const timeoutRef = useRef(null);

    const { match } = useMediaQuery('(min-width: 1024px)');

    const openMenu = () => {
        if (!isMenuVisible) {
            timeoutRef.current = setTimeout(function () {
                setMenuVisible(true);
            }, 150)
        }
    };

    const closeMenu = (event) => {
        if ((event.relatedTarget instanceof Node && (buttonRef.current === event.relatedTarget))) { return; }
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        if (isMenuVisible) {
            setMenuVisible(false)
        }
    };

    const onSubmenuMouseOver = (index) => {
        if (subMenusHeightsRef.current[index]) {
            menuRef.current.style.height = subMenusHeightsRef.current[index] + 'px';
        }
    }

    return (
        <div className={classes.root}>
            <div ref={buttonRef} className={classNames(classes.button, (isMenuVisible ? classes.open : null))} onMouseEnter={openMenu} onMouseLeave={closeMenu}>
                <div className={classes.label}>{t('header.showcollection')}</div>
                {
                    isMenuVisible
                    &&
                    <div className={classes.menu_container}>

                        <div ref={menuRef} className={classes.menu} onMouseLeave={closeMenu} >
                            <div className={classes.left_menu}>
                                <ul>
                                    {
                                        categories.map(({ id, name, type, url_key, external, children }, index) => {
                                            return (
                                                <li key={id} onMouseEnter={onSubmenuMouseOver.bind(this, index)}>
                                                    <NavItem url_key={url_key} type={type} external={external} className={classes.link} >{name}</NavItem>
                                                    <div
                                                        ref={(el) => {
                                                            if (el !== null) {
                                                                subMenusHeightsRef.current[index] = el.scrollHeight + (el.scrollHeight > 522 ? 30 : 0);
                                                            }
                                                        }}
                                                        className={classes.submenu}
                                                    >
                                                        {
                                                            renderCols(children, (match ? 3 : 2))
                                                        }
                                                    </div>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            </div>

                        </div>

                    </div>
                }

            </div>
        </div>
    );
}

export default ShowCollection;