import { Helmet } from 'react-helmet-async';

import { default as OrdersModule } from '../../../modules/account/orders/Orders';
import { t } from '../../../lib/translations';

const Orders = () => {
    return (
        <>
            <Helmet>
                <title>{ t('account.orders.page_title') }</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <OrdersModule />
        </>
    )
}

export default Orders;