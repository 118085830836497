import { Helmet } from 'react-helmet-async';

import { t } from '../../../lib/translations';

import { default as PaymentModule } from '../../../modules/checkout/payment/Payment';

const Payment = () => {
    return (
        <>
            <Helmet>
                <title>{ t('checkout.payment.page_title') }</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <PaymentModule />
        </>
    )
}

export default Payment;