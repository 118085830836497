import { useNavigation, useConfig } from '@kega/sps-core';
import { Link } from "react-router-dom";

import {
    Accordion,
    AccordionItem,
    AccordionHeader,
    AccordionContent,
    AccordionIcon
} from '@kega/sps-elements';

import { t } from '../../lib/translations';
import { Col, Row, Container } from '../../components/grid';
import { FaceBookIcon, InstagramIcon, YoutubeIcon, LinkedinIcon } from '../../components/icons';
import PaymentMethods from '../../components/paymentmethods/PaymentMethods';
import NavItem from '../../components/navigation/NavItem';

import useMediaQuery from '../../hooks/useMediaQuery';
import useTranslatePath from '../../hooks/useTranslatePath';
import BuilderSectionLazy from "../builder/BuilderSectionLazy";

import NewsletterInput from './newsletterinput/NewsletterInput';

import classes from './Footer.module.css';
import TrustboxWidget from '../../components/trustboxwidget/TrustboxWidget';

const Footer = () => {
    const { match: desktop } = useMediaQuery('(min-width: 768px)');
    const { getPath } = useTranslatePath();
    const config = useConfig();

    const storeCode = config.get('storeCode');

    const links = useNavigation('HS_FooterNavBarComponent', {
        catalogCode: 'nonfood-nlContentCatalog'
    });

    return (
        <footer className={classes.root}>

            {
                desktop
                &&
                <Container xl margins g-xs={2} g-md={3}>
                    <div className={classes.usps}>
                        <BuilderSectionLazy model="usp" url={getPath('/')} />
                    </div>
                </Container>
            }

            <nav className={classes.content}>
                <Container xl margins g-xs={2} g-md={3}>
                    <Row>

                        <Col md={12} order-sm={2}>
                            <Accordion className={classes.accordion} >
                                <Row>
                                    {
                                        links &&
                                        links.map((link, index) => {
                                            return (
                                                <Col md={3} key={String('link-col' + index)}>
                                                    <AccordionItem alwaysOpen={desktop} className={classes.links}>
                                                        <AccordionHeader className={classes.header}>
                                                            <div className={classes.footer_link_title}>{link.name}</div>
                                                            {!desktop ? <AccordionIcon /> : null}
                                                        </AccordionHeader>
                                                        <AccordionContent className={classes.list}>
                                                            <ul>
                                                                {
                                                                    link.children.map(({ name, type, url_key, external }, index) => {
                                                                        return <li key={String('link' + index)}><NavItem url_key={url_key} type={type} external={external}>{name}</NavItem></li>;
                                                                    })
                                                                }
                                                            </ul>
                                                        </AccordionContent>
                                                    </AccordionItem>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </Accordion>
                        </Col>

                        <Col md={12} order-sm={1}>
                            <Row>

                                <Col md={6}>
                                    <h4>{t('footer.socials_title')}</h4>
                                    <div>{t('footer.socials_text')}</div>
                                    <div className={classes.socials}>
                                        <a href="https://www.facebook.com/HANOSSHOPcom-101863652314799" target="_blank" rel="noreferrer"><FaceBookIcon color="white" /></a>
                                        <a href="https://www.instagram.com/hanosshop_com" target="_blank" rel="noreferrer"><InstagramIcon /></a>
                                        <a href="https://www.youtube.com/channel/UCeJ1Kpwvrc_ZWZwIAP7uL6A" target="_blank" rel="noreferrer"><YoutubeIcon /></a>
                                        <a href="https://www.linkedin.com/company/80442878/admin/" target="_blank" rel="noreferrer"><LinkedinIcon /></a>
                                    </div>
                                </Col>

                                <Col md={6}>
                                    {/*<div className={classes.newsletter}>
                                        <h4>{t('footer.newsletter_title')}</h4>
                                        <div>{t('footer.newsletter_text')}</div>
                                        <NewsletterInput className={classes.newsletterinput} />
                                    </div>*/}
                                </Col>

                            </Row>
                        </Col>

                    </Row>
                </Container>
            </nav>

            <div className={classes.payment}>
                <PaymentMethods />
            </div>

            <div className={classes.metelinks}><Link to={'/' + storeCode + '/algemene-voorwaarden'}>{t('footer.terms_and_Conditions')}</Link><span>|</span><Link to={'/' + storeCode + '/privacy'}>{t('footer.privacy')}</Link><span>|</span><Link to={'/' + storeCode + '/cookies'}>{t('footer.cookies')}</Link></div>

            <div className={classes.copyright}>
                &copy; 2022 - {new Date().getFullYear()} - HANOS SHOP
            </div>
            <div className={classes.widget}>
                <TrustboxWidget />
            </div>
        </footer >
    )
}

export default Footer;