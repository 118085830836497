import IconWrapper from '../wrapper/IconWrapper';

const CheckRoundIcon = ({ size = 24, color = '#65b32e', direction='top', className = null, title = 'checkmark-icon' }) => {
    return (
        <IconWrapper size={size} color={color} direction={direction} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <title>{title}</title>
                <path id="check-circle" d="M10,2a8,8,0,1,0,8,8,8.024,8.024,0,0,0-8-8M8.4,14l-4-4L5.528,8.872,8.4,11.736l6.072-6.072L15.6,6.8Z" transform="translate(-2 -2)"/>
            </svg>
        </IconWrapper>
    );
}

export default CheckRoundIcon;
