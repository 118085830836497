import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useConfig, useStorefront } from '@kega/sps-core';
import { Form } from '@kega/sps-elements';

import { Col, Row } from '../../../components/grid';
import { t } from '../../../lib/translations';

import Button from '../../../components/buttons/Button';
import Select from '../../../components/form/Select';
import ErrorMessage from '../../../components/messages/ErrorMessage';

import useReCaptcha from '../../../hooks/useReCaptcha';
import useRegister from '../../../hooks/useRegister';
import useTranslatePath from '../../../hooks/useTranslatePath';

import classes from './SelectCompany.module.css';

const SelectCompany = () => {
    const config = useConfig();
    const navigate = useNavigate();
    const { setBreadcrumb } = useOutletContext();

    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    const { api: { occregister } } = useStorefront();
    const { mode, companies, company, addCompany } = useRegister();
    const { getPath } = useTranslatePath();

    const { recaptchakey } = config.get('google');

    const { loadToken, loading: loadingToken } = useReCaptcha({ 
        key: recaptchakey,  
        action: 'companycheck',
        language: config.get('language'),
        autoLoad: false
    });

    useEffect(() => {
        if (companies === null || mode === null) {
            navigate(getPath('/register/companycheck'));
        }
    }, [companies, mode]);

    useEffect(() => {
        setBreadcrumb([
            { name: 'Home', url_key: '/' },
            { name: 'Registratie', url_key: '/register/companycheck' },
            { name: t('register.selectcompany.header') }
        ]);
    }, []);

    const onSubmit = async ({ valid, values, resetForm }) => {
        if (valid) {
            const { companyid } = values;

            const company = companies.find(({ dossierNumber }) => {
                return dossierNumber === companyid;
            });

            setLoading(true);

            const recaptchaToken = await loadToken();

            let payload = {
                recaptchaToken: recaptchaToken
            }

            if (config.get('country') === 'nl') {
                payload = {
                    ...payload,
                    dossierNumber: company.dossierNumber,
                }
            } else {
                payload = {
                    ...payload,
                    //vatNumber: vatNumber
                }
            }

            try {
                
                const companies = await occregister.getCompanyInfo(payload);
                if (companies && companies.length > 0) {
                    addCompany(companies[0]);

                    setLoading(false);
                    navigate(getPath('/register/details'));
                } else {
                    setError({ message: t('register.selectcompany.no_company_info') });
                    setLoading(false);
                }
                    
            } catch (error) {
                setError(error.data.errors);
                setLoading(false);
            }

        }

    }

    return (    
        <>
            <h1>{ t('register.selectcompany.header') }</h1>
            {
                error !== null
                &&
                <ErrorMessage>
                    { 
                        Array.isArray(error)
                            ?
                            <>
                                {
                                    error.map(({ message }, index) => {
                                        return <span key={'e'+index}>{ message }</span>
                                    })
                                }
                            </>
                            :
                            error?.message 
                    }
                </ErrorMessage>
            }

            <div className={classes.form}>
                <Form onSubmit={onSubmit}>
                    <Row>
                        <Col md="12"><h4 className={classes.header}>{ t('register.selectcompany.form_header') }</h4></Col>
                        <Col md="12">
                            <Select
                                name="companyid"
                                placeholder={ t('register.selectcompany.companyid_label') }
                                label={ t('register.selectcompany.companyid_label') }
                                value={company?.dossierNumber}
                                required
                                className={classes.input}
                            >
                                {
                                    companies !== null
                                    &&
                                    companies.map(({ dossierNumber, tradeName }) => {
                                        return <option key={dossierNumber} value={dossierNumber}>{ tradeName }</option>
                                    })
                                }
                            </Select>
                        </Col>       
                        <Col md="12"><Button variant="primary" type="submit" loading={(loadingToken || loading)} >{ t('register.selectcompany.btn_next') }</Button></Col>
                    </Row>
                </Form>
            </div>

        </>
    );
} 

export default SelectCompany;