import { Helmet } from 'react-helmet-async';

import { default as ResetPasswordModule } from '../../../modules/account/resetpassword/ResetPassword';
import { t } from '../../../lib/translations';

const ResetPassword = () => {
    return (
        <>
            <Helmet>
                <title>{ t('account.resetpassword.page_title') }</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <ResetPasswordModule />
        </>
    )
}

export default ResetPassword;