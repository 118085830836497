import { useRef, useState, useEffect } from 'react';

import classNames from 'classnames';

import classes from './Slider.module.css';

const Slider = ({ children, controls = true, scrollbar = true, automate = false, duration = 5000, itemsToShow = 6, mobileItemsToShow = null, ComponentNext = null, ComponentPrev = null, className = null, noControlsClassName = null }) => {
    const sliderRef = useRef(null);
    const [showPrev, seShowPrev] = useState(controls);
    const [showNext, seShowNext] = useState(controls);

    useEffect(() => {
        if (automate) {
            const autoSlide = setInterval(() => {
                automateScroll()
            }, duration);

            return () => clearInterval(autoSlide);
        }
    }, []);

    useEffect(() => {
        if (controls) {
            checkScroll();
        }
    }, [children]);

    const findWidth = () => {
        const children = sliderRef.current.children;
        const sliderLeft = sliderRef.current.getBoundingClientRect().left;

        let index = 0;
        for (let c = 0; c < children.length; c++) {
            const child = children[c];
            if ((child.getBoundingClientRect().left - sliderLeft) === 0) {
                index = c;
            }
        }

        return children[index].clientWidth;
    }

    const next = () => { sliderRef.current.scrollBy(findWidth(), 0); }

    const prev = () => { sliderRef.current.scrollBy(findWidth() * -1, 0); }

    const automateScroll = () => {
        let slide_width = findWidth();

        if (sliderRef.current) {

            if (sliderRef.current.scrollLeft >= (sliderRef.current.scrollWidth - sliderRef.current.clientWidth)) {
                slide_width = slide_width * - children.length;
            }

            sliderRef.current.scrollBy(slide_width, 0);
        }
    }

    const checkScroll = () => {

        if (sliderRef.current.scrollLeft <= 0) {
            seShowPrev(false);
        } else {
            seShowPrev(true);
        }

        if (sliderRef.current.scrollLeft >= (sliderRef.current.scrollWidth - sliderRef.current.clientWidth)) {
            seShowNext(false);
        } else {
            seShowNext(true);
        }

    };

    const sliderClasses = classNames(
        classes.slides,
        (!scrollbar ? classes.noscrollbar : null),
        classes['show-' + itemsToShow],
        mobileItemsToShow ? classes['show-mobile-' + mobileItemsToShow] : null
    );

    return (
        <div className={classNames(classes.root, className, !(showPrev || showNext) ? noControlsClassName : null)}>

            {
                controls
                &&
                <>
                    {
                        showPrev
                        &&
                        <div className={classes.prev} onClick={prev} >
                            {
                                ComponentPrev
                                    ?
                                    ComponentPrev
                                    :
                                    <button type="button"> prev </button>
                            }
                        </div>
                    }

                    {
                        showNext
                        &&
                        <div className={classes.next} onClick={next} >
                            {
                                ComponentNext
                                    ?
                                    ComponentNext
                                    :
                                    <button type="button" > next </button>
                            }
                        </div>
                    }
                </>
            }

            <div ref={sliderRef} onScroll={(controls ? checkScroll : null)} className={sliderClasses}>
                {children}
            </div>

        </div>
    );
}

export default Slider;