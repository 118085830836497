import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '@kega/sps-elements';
import { useShippingMethods, useFetchShippingMethods, useCart, useConfig } from '@kega/sps-core';

import { t } from '../../../lib/translations';
import useTagManager from '../../../hooks/useTagManager';
import useItemCategories from '../../../hooks/useItemCategories';
import useTranslatePath from '../../../hooks/useTranslatePath';
import useUserId from "../../../hooks/useUserId";

import ShipmentMethode from './shipmentmethode/ShipmentMethode';
import Button from '../../../components/buttons/Button';
import ErrorMessage from '../../../components/messages/ErrorMessage';
import BackToLink from '../backtolink/BackToLink';
import BuilderSectionLazy from "../../builder/BuilderSectionLazy";

import classes from './Shipment.module.css';
const Shipment = () => {
    const { setShippingMethods, shippingMethod, error, loading } = useShippingMethods();
    const { fetchShippingMethods, error: fetchError, loading: fetchLoading, methodes } = useFetchShippingMethods();
    const [ selected, setSelected ] = useState(shippingMethod);
    const { getPath } = useTranslatePath();
    const { ecommerceEvent } = useTagManager();
    const { cartId, items, totals } = useCart();
    const config = useConfig();
    const navigate = useNavigate();
    const getUserId = useUserId();

    const categoryPath = items.map((item) => item.product.categoryPath);
    const itemCategories = useItemCategories(categoryPath);
    
    useEffect(() => {
        const fetch = async () => {
            try {
                await fetchShippingMethods({
                    cartId: cartId,
                    userId: getUserId()
                });
            } catch (error) {
                console.log(error);
            }    
        }

        fetch();
    }, []);

    useEffect(() => {
        if (selected === null && methodes.length > 0) {
            setSelected(methodes[0].code);
        }
    }, [selected, methodes]);

    const onClick = (code) => {
        setSelected(code)
    }

    const onSubmit = async () => {

        if (selected) {
            try {
                await setShippingMethods({
                    cartId: cartId, 
                    deliveryModeId: selected,
                    userId: getUserId()
                });

                try {

                    ecommerceEvent('add_shipping_info', {
                        value: totals?.total?.value,
                        currency: config.get('currency'),
                        shipping_tier: selected,
                        items: items.map(({ quantity=0, product={}, totalPrice }) => {
            
                            return {
                                item_id: product?.code,
                                item_name: product?.formattedName || product?.name,
                                quantity: quantity,
                                item_brand: product?.Merk ?? product?.manufacturer,
                                price: totalPrice?.value, 
                                item_category: itemCategories[0],
                                item_category2: itemCategories[1],
                                item_category3: itemCategories[2],
                                item_category4: itemCategories[3],
                                item_category5: itemCategories[4],
                            }
                        
                        })
                    });
                } catch (error) {
                    console.log(error)
                }
    
                navigate(getPath('/checkout/summary'));
            } catch (error) {
                console.log(error);
            }
        } 

    }

    return (
        <div>
            <BackToLink url_key={'/checkout/addresses'}>{ t('checkout.shipment.backtoaddresses') }</BackToLink>

            <div className={classes.header}>
                <h1>{ t('checkout.shipment.header') }</h1>
                <Button onClick={onSubmit} loading={loading}>{ t('checkout.shipment.btn_next') }</Button>
            </div>
            
            {
                error !== null
                &&
                <ErrorMessage className={classes.error}>{ error?.message }</ErrorMessage>
            }

            {
                fetchError !== null
                &&
                <ErrorMessage className={classes.error}>{ fetchError?.message }</ErrorMessage>
            }

            {
                fetchLoading
                &&
                <Loader variant="primary" />
            }

            {
                methodes !== null
                &&
                methodes.map((methode, index) => {
                    return <ShipmentMethode key={'m'+index} data={methode} selected={(selected===methode.code)} onClick={onClick}/>
                })
            }

            <BuilderSectionLazy model="checkout-shipment-info" url={getPath('/')} />

            <div className={classes.next}>
                <Button onClick={onSubmit} loading={loading}>{ t('checkout.shipment.btn_next') }</Button>
            </div>
    
        </div>
    );
}

export default Shipment;