import { forwardRef } from 'react';
import { Radio as DefaultRadio } from '@kega/sps-elements';

import classNames from 'classnames';

import classes from './Form.module.css';

const Radio = ({ className, ...props }, ref) => {
    const radioClassName = classNames(classes.radio, className);

    return (
        <DefaultRadio ref={ref} className={radioClassName} {...props} />
    );
}

export default forwardRef(Radio);