import IconWrapper from '../wrapper/IconWrapper';

const TruckIcon = ({ size = 24, color = null, className = null }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="35.658" height="35.658" viewBox="0 0 35.658 35.658">
                <path d="M0,0H35.658V35.658H0Z" fill="none"/>
                <path d="M29.229,9.943H24.772V4H3.972A2.98,2.98,0,0,0,1,6.972V23.315H3.972a4.457,4.457,0,1,0,8.915,0H21.8a4.457,4.457,0,1,0,8.915,0h2.972V15.886Zm-.743,2.229L31.4,15.886H24.772V12.172ZM8.429,24.8a1.486,1.486,0,1,1,1.486-1.486A1.49,1.49,0,0,1,8.429,24.8Zm3.3-4.457a4.4,4.4,0,0,0-6.6,0H3.972V6.972H21.8V20.343ZM26.258,24.8a1.486,1.486,0,1,1,1.486-1.486A1.49,1.49,0,0,1,26.258,24.8Z" transform="translate(0.486 1.943)"/>
            </svg>
        </IconWrapper>
    );
}

export default TruckIcon;


