import IconWrapper from '../wrapper/IconWrapper';

const InstagramIcon = ({ size = 24, color = null, className = null, title = "instagram-icon" }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26.793" height="26.793" viewBox="0 0 26.793 26.793">
                <title>{title}</title>
                <path id="instagram" d="M9.77,2H21.023a7.775,7.775,0,0,1,7.77,7.77V21.023a7.77,7.77,0,0,1-7.77,7.77H9.77A7.775,7.775,0,0,1,2,21.023V9.77A7.77,7.77,0,0,1,9.77,2M9.5,4.679A4.823,4.823,0,0,0,4.679,9.5V21.291A4.82,4.82,0,0,0,9.5,26.113H21.291a4.823,4.823,0,0,0,4.823-4.823V9.5a4.82,4.82,0,0,0-4.823-4.823H9.5M22.429,6.689a1.675,1.675,0,1,1-1.675,1.675,1.675,1.675,0,0,1,1.675-1.675M15.4,8.7a6.7,6.7,0,1,1-6.7,6.7,6.7,6.7,0,0,1,6.7-6.7m0,2.679A4.019,4.019,0,1,0,19.415,15.4,4.019,4.019,0,0,0,15.4,11.377Z" transform="translate(-2 -2)" fill="#fff"/>
            </svg>
        </IconWrapper>
    );
}

export default InstagramIcon;


