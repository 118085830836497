import { connectSortBy } from 'react-instantsearch-dom';

import Select from '../../../components/form/Select';

const SortBy = ({ className, items, refine, createURL, value, defaultRefinement }) => {

    const onChange = ({ target }) => {
        refine(target.value);
    }

    return (
        <Select
            rootClassName={className}
            placeholder="Select"
            onChange={onChange}
            value={value ?? defaultRefinement}
        >
            {
                items.map(({ label, value }) => {
                    return <option key={value} value={value}>{ label }</option>
                })
            }

        </Select>
    );
}

export default connectSortBy(SortBy);