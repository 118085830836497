import { connectStateResults } from 'react-instantsearch-dom';

import { t } from '../../../lib/translations';

const Stats = ({ className, searchResults }) => {
    const { nbHits=0, hitsPerPage=0, page=0, hits=[] } = searchResults ?? {};

    const startNumber = (page)*hitsPerPage;
    const toNumber = startNumber + hits.length;

    return <span className={className}> <strong>{ startNumber+1 } - { toNumber }</strong><>&nbsp;</>{ t('productlist.stats.from') }<>&nbsp;</><strong>{ nbHits }</strong><>&nbsp;</>{ t('productlist.stats.products') }</span>;
}

export default connectStateResults(Stats)