import { Button as DefaultButton } from '@kega/sps-elements';
import classNames from 'classnames';

import classes from './Button.module.css';

const Button = ({ children, className, variant = 'secondary', ...props }) => {

    const buttonClassName = classNames(classes.root, classes[variant], className);

    return (
        <DefaultButton className={buttonClassName} variant={variant} {...props} >
            { children }
        </DefaultButton>
    );
}

export default Button;