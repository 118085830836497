import { useEffect } from "react";
import { useCustomer } from "@kega/sps-core";

import useUserToken from "../../../hooks/useUserToken";
import useTagManager from "../../../hooks/useTagManager";

const UserToken = () => {
    const customer = useCustomer();
    const { set } = useUserToken();
    const { dataLayer } = useTagManager();

    useEffect(() => {
        if (customer?.customerId || !customer?.authenticated) {
            const token = set(true);

            try {
                dataLayer.push({ 'user_token': String(token) });
            } catch (error) {
                //
            }
        }
    }, [customer?.customerId, customer?.authenticated]);

    return null;
};

export default UserToken;
