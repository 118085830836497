import IconWrapper from '../wrapper/IconWrapper';

const FaceBookIcon = ({ size = 24, color = 'black', className = null, title = "facebook-icon" }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26.793" height="26.686" viewBox="0 0 26.793 26.686">
                <title>{title}</title>
                <path id="facebook" d="M15.4,2.04a13.427,13.427,0,0,0-2.09,26.686V19.348H9.9V15.463h3.4V12.5c0-3.362,2-5.211,5.064-5.211a21,21,0,0,1,2.987.255v3.309H19.67a1.937,1.937,0,0,0-2.184,2.09v2.519H21.21l-.6,3.885H17.486v9.377A13.427,13.427,0,0,0,15.4,2.04Z" transform="translate(-2 -2.04)" fill={color} />
            </svg>
        </IconWrapper>
    );
}

export default FaceBookIcon;


