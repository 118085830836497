import IconWrapper from '../wrapper/IconWrapper';

const PlusIcon = ({ size = 24, color = 'black', className = null }) => {
    return (
        <IconWrapper size={size} color={color} className={className}>
            <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m0 0h24v24h-24z" fill="none" /><path d="m19 13h-6v6h-2v-6h-6v-2h6v-6h2v6h6z" fill={color} /></svg>
        </IconWrapper>
    );
}

export default PlusIcon;


