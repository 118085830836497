import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setMode as setModeAction, setCompanies, setCompany, setUser, clear } from '../redux/register/actions';

const useRegister = () => {
    const dispatch = useDispatch();
    const { mode, user, company, companies } = useSelector((state) => state.register);

    const addCompanies = useCallback((companies) => {
        dispatch(setCompanies(companies));
    }, [dispatch]);

    const addCompany = useCallback((company) => {
        dispatch(setCompany(company));
    }, [dispatch]);


    const addUser = useCallback((user) => {
        dispatch(setUser(user));
    }, [dispatch]);

    const setMode = useCallback((mode) => {
        dispatch(setModeAction(mode));
        clearRegister();
    }, [dispatch]);

    const clearRegister = useCallback(() => {
        dispatch(clear());
    }, [dispatch]);

    return {
        mode,
        user,
        company,
        companies,
        addCompanies,
        addCompany,
        addUser,
        setMode,
        clear: clearRegister
    };
}

export default useRegister;