import { t } from "../../../lib/translations";

import { Col, Row } from '../../../components/grid';

import classNames from "classnames";
import classes from "./RegisterMode.module.css";

const RegisterMode = ({ mode, setMode }) => {
    return (
        <div className={classes.root}>
            <h4 className={classes.header}>{ t('register.mode.header') }</h4>
            <Row>
                <Col md="6">
                    <div
                        className={classNames(classes.button, mode === 'kvk' ? classes.active : '')}
                        onClick={() => setMode('kvk')}
                    >{t('register.mode.kvk')}</div>
                </Col>
                <Col md="6">
                    <div
                        className={classNames(classes.button, mode === 'address' ? classes.active : '')}
                        onClick={() => setMode('address')}
                    >{t('register.mode.address')}</div>
                </Col>
            </Row>
        </div>
    );
};

export default RegisterMode;