import { useEffect, useState, useCallback } from 'react';

import classNames from 'classnames';

import BuilderSectionLazy from '../../builder/BuilderSectionLazy';

import useTranslatePath from '../../../hooks/useTranslatePath';

import classes from './MobilePayoff.module.css';

const MobilePayoff = () => {
    const [ hide, setHide ] = useState(false);
    const { getPath } = useTranslatePath();

    const toggleHide = useCallback(() => {
        const scrollTop = window.scrollY;

        if (scrollTop > 0 && !hide) {
            setHide(true);
        } else if (scrollTop <= 0 && hide) {
            setHide(false);
        }

    }, [hide]);

    useEffect(() => {
        window.addEventListener("scroll", toggleHide);
        return () => {
            window.removeEventListener("scroll", toggleHide);
        };
    }, [toggleHide]);

    return (
        <div className={classNames(classes.root, (hide ? classes.hide : null))}>
            <BuilderSectionLazy model="usp" url={getPath('/')} />
        </div>
    )
}

export default MobilePayoff;