import { Navigate, Outlet } from "react-router-dom";
import { useCustomer } from '@kega/sps-core';

import useTranslatePath from "../hooks/useTranslatePath";

const RequireAuth = ({ returnPath }) => {
    const { authenticated = false } = useCustomer();
    const { getPath } = useTranslatePath();

    /*
     * Allow Builder.io to preview pages without requiring authentication.
     * Use window.location instead of useLocation to prevent unnecessary re-renders when location changes;
     * Builder preview is always a direct page visit.
     */
    const isBuilderPreview = window.location.search.includes('builder.preview');

    if (!authenticated && !isBuilderPreview) {
        let path = getPath("/account/login");

        if (returnPath) {
            path += "?ref=" + returnPath;
        }

        return <Navigate to={path} />;
    }

    return <Outlet />;
}

export default RequireAuth;