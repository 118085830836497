import { useConfig } from "@kega/sps-core";

import { t } from '../../lib/translations';

const useInputValidation = () => {
    const config = useConfig();
    const { validation: { zipcode: zipcodeConfig } } = config.get('form');

    const zipcode = (countryCode, customError) => {

        return (value) => {

            const countryConfig = countryCode ? zipcodeConfig[countryCode] : null;

            if (!countryConfig) {
                return null
            }

            const regex = new RegExp(countryConfig.pattern);

            return !regex.test(value) ? t(zipcodeConfig.error ?? 'form.error.zipcode') :
                null;

        };
    };


    return {
        zipcode
    }
}

export default useInputValidation;
