const EnergyLabelE = ({ className }) => {
    return (
        <div className={className}>
            <svg id="Laag_2" data-name="Laag 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128.28 60">
                <title>Energy Label E</title>
                <g id="Laag_1-2" data-name="Laag 1">
                    <g>
                        <polygon strokeWidth="0" fill="#fab400" points="100.77 0 0 0 0 60 100.77 60 128.28 30 100.77 0"/>
                        <path strokeWidth="0" fill="#fff" d="m43.63,39.76v4.22h-16.48v-27.63h16.32v4.22h-11.11v7.5h9.88v4.18h-9.88v7.5h11.27Z"/>
                    </g>
                </g>
            </svg>
        </div>
    )
};

export default EnergyLabelE;
