import classes from './ReviewStars.module.css';
import classNames from "classnames";

const ReviewStars = ({ score, className }) => {
    let roundedScore = 0;

    if (score > 0 && score <= 1) {
        roundedScore = 1;
    } else if (score <= 5) {
        roundedScore = Math.round(score * 2) / 2;
    }

    return (
        <div className={classNames(classes.root, className, classes[`score_${String(roundedScore).replace('.','_')}`])}>
            <svg xmlns="http://www.w3.org/2000/svg" width="512px" height="96px" viewBox="0 0 512 96" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g fillRule="nonzero">
                        <rect id="Rectangle-path" fill={roundedScore >= 1 ? "currentColor" : "#DCDCE6"} x="0" y="0" width="96" height="96"/>
                        <rect id="Rectangle-path" fill={roundedScore >= 1.5 ? "currentColor" : "#DCDCE6"} x="104" y="0" width="48" height="96"/>
                        <rect id="Rectangle-path" fill={roundedScore >= 2 ? "currentColor" : "#DCDCE6"} x="152" y="0" width="48" height="96"/>
                        <rect id="Rectangle-path" fill={roundedScore >= 2.5 ? "currentColor" : "#DCDCE6"} x="208" y="0" width="48" height="96"/>
                        <rect id="Rectangle-path" fill={roundedScore >= 3 ? "currentColor" : "#DCDCE6"} x="256" y="0" width="48" height="96"/>
                        <rect id="Rectangle-path" fill={roundedScore >= 3.5 ? "currentColor" : "#DCDCE6"} x="312" y="0" width="48" height="96"/>
                        <rect id="Rectangle-path" fill={roundedScore >= 4 ? "currentColor" : "#DCDCE6"} x="360" y="0" width="48" height="96"/>
                        <rect id="Rectangle-path" fill={roundedScore >= 4.5 ? "currentColor" : "#DCDCE6"} x="416" y="0" width="48" height="96"/>
                        <rect id="Rectangle-path" fill={roundedScore >= 5 ? "currentColor" : "#DCDCE6"} x="464" y="0" width="48" height="96"/>
                        <path d="M48,64.7 L62.6,61 L68.7,79.8 L48,64.7 Z M81.6,40.4 L55.9,40.4 L48,16.2 L40.1,40.4 L14.4,40.4 L35.2,55.4 L27.3,79.6 L48.1,64.6 L60.9,55.4 L81.6,40.4 L81.6,40.4 L81.6,40.4 L81.6,40.4 Z" id="Shape" fill="#FFFFFF"/>
                        <path d="M152,64.7 L166.6,61 L172.7,79.8 L152,64.7 Z M185.6,40.4 L159.9,40.4 L152,16.2 L144.1,40.4 L118.4,40.4 L139.2,55.4 L131.3,79.6 L152.1,64.6 L164.9,55.4 L185.6,40.4 L185.6,40.4 L185.6,40.4 L185.6,40.4 Z" id="Shape" fill="#FFFFFF"/>
                        <path d="M256,64.7 L270.6,61 L276.7,79.8 L256,64.7 Z M289.6,40.4 L263.9,40.4 L256,16.2 L248.1,40.4 L222.4,40.4 L243.2,55.4 L235.3,79.6 L256.1,64.6 L268.9,55.4 L289.6,40.4 L289.6,40.4 L289.6,40.4 L289.6,40.4 Z" id="Shape" fill="#FFFFFF"/>
                        <path d="M360,64.7 L374.6,61 L380.7,79.8 L360,64.7 Z M393.6,40.4 L367.9,40.4 L360,16.2 L352.1,40.4 L326.4,40.4 L347.2,55.4 L339.3,79.6 L360.1,64.6 L372.9,55.4 L393.6,40.4 L393.6,40.4 L393.6,40.4 L393.6,40.4 Z" id="Shape" fill="#FFFFFF"/>
                        <path d="M464,64.7 L478.6,61 L484.7,79.8 L464,64.7 Z M497.6,40.4 L471.9,40.4 L464,16.2 L456.1,40.4 L430.4,40.4 L451.2,55.4 L443.3,79.6 L464.1,64.6 L476.9,55.4 L497.6,40.4 L497.6,40.4 L497.6,40.4 L497.6,40.4 Z" id="Shape" fill="#FFFFFF"/>
                    </g>
                </g>
            </svg>
        </div>
    );    
};

export default ReviewStars;
