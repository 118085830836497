import { useState } from "react";
import { useConfig, useStorefront } from "@kega/sps-core";

import { t } from "../lib/translations";

import useReCaptcha from "./useReCaptcha";
import useInputModifiers from "./form/useInputModifiers";

const useOccRegister = () => {
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const config = useConfig();
    const { api: { occregister } } = useStorefront();
    const { zipcode } = useInputModifiers();

    const { recaptchakey } = config.get('google');

    const { loadToken, loading: loadingReCaptcha } = useReCaptcha({
        key: recaptchakey,
        action: 'companycheck',
        language: config.get('language'),
        autoLoad: false
    });

    const validateLoginData = (
        { email, email_repeat, password, password_repeat },
        { emailRef, emailRepeatRef, passwordRef, passwordRepeatRef }
    ) => {
        let errors = {
            emailError: '',
            passwordError: '',
        };

        if (email.toLowerCase() !== email_repeat.toLowerCase()) {
            if (emailRef.current) { emailRef.current.setCustomValidity('invalid') }
            if (emailRepeatRef.current) { emailRepeatRef.current.setCustomValidity('invalid') }
            errors.emailError = t('form.error.email');
        } else {
            if (emailRef.current) { emailRef.current.setCustomValidity('') }
            if (emailRepeatRef.current) { emailRepeatRef.current.setCustomValidity('') }
        }

        if (password !== password_repeat) {
            if (passwordRef.current) { passwordRef.current.setCustomValidity('invalid') }
            if (passwordRepeatRef.current) { passwordRepeatRef.current.setCustomValidity('invalid') }
            errors.passwordError = t('form.error.password');
        } else {
            if (passwordRef.current) { passwordRef.current.setCustomValidity('') }
            if (passwordRepeatRef.current) { passwordRepeatRef.current.setCustomValidity('') }
        }

        return errors;

    };

    const register = async (company = {}, user = {}, onSuccess, confirmationCode) => {
        const { tradeName, telephone, city, houseNumber, houseNumberExtension = ' ', postalCode, streetName, dossierNumber, vatNumber } = company;
        const { email, firstname, lastname, password, phonenumber, newsletter } = user;

        try {

            setLoading(true);

            const recaptchaToken = await loadToken();

            const payload = {
                email: email.toLowerCase(),
                firstName: firstname,
                lastName: lastname,
                titleCode: "",
                language: {
                    isocode: config.get('language')
                },
                currency: {
                    "isocode": "EUR"
                },
                orgUnit: {
                    uid: (config.get('country') === 'nl' ? dossierNumber : vatNumber),
                    name: tradeName,
                    addresses: [{
                        email: email.toLowerCase(),
                        title: "",
                        titleCode: "",
                        firstName: firstname,
                        lastName: lastname,
                        line1: streetName,
                        line2: houseNumber,
                        postalCode: zipcode(postalCode),
                        town: city,
                        country: {
                            isocode: String(config.get('country')).toUpperCase()
                        },
                        phone: phonenumber,
                        companyName: tradeName,
                        houseNumberExtension: houseNumberExtension,
                        visibleInAddressBook: true,
                        shippingAddress: true,
                        billingAddress: false,
                        defaultAddress: true
                    }],
                    approvers: [{ uid: email.toLowerCase() }]
                },
                password: password,
                roles: [
                    "b2bcustomergroup",
                    "b2badmingroup",
                    "b2bapprovergroup"
                ],
                subscriptionConsent: newsletter
            }

            const registerPayload = {
                payload: payload,
                recaptchaToken: recaptchaToken
            };

            if (confirmationCode) {
                registerPayload.confirmationCode = confirmationCode;
            }

            await occregister.register(registerPayload);

            setLoading(false);

            if (onSuccess) {
                onSuccess();
            }

        } catch (error) {

            // TODO: Normalize error handeling
            setError((error?.data?.errors ? error.data.errors : error));
            setLoading(false);

            window.scrollTo(0, 0);
        }
    };

    return {
        loading,
        error,
        loadingReCaptcha,
        validateLoginData,
        register
    }
};

export default useOccRegister;
