import { Helmet } from 'react-helmet-async';

import { default as EditProfileModule } from '../../../../modules/account/profile/edit/EditProfile';
import { t } from '../../../../lib/translations';

const EditProfile = () => {
    return (
        <>
            <Helmet>
                <title>{ t('account.edit_profile.page_title') }</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <EditProfileModule />
        </>
    )
}

export default EditProfile;