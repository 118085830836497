import { useEffect } from 'react';
import { useOutletContext } from "react-router-dom";

import { t } from '../../../lib/translations';

import useTranslatePath from '../../../hooks/useTranslatePath';

import { Col, Row } from '../../../components/grid';
import RecentlyViewed from '../../../components/recentlyviewed/RecentlyViewed';
import BuilderSectionLazy from '../../builder/BuilderSectionLazy';

const Overview = () => {
    const { getPath } = useTranslatePath();
    const setBreadcrumb = useOutletContext();

    useEffect(() => {
        setBreadcrumb([
            { name: 'Home', url_key: '/' },
            { name: 'Account', url_key: '/account/overview' },
            { name: t('account.account_overview.header') }
        ]);
    }, []);

    return (
        <Row>
            <BuilderSectionLazy model="account-overview" url={getPath('/')} />
            <Col md={10}>
                <RecentlyViewed />
            </Col>
        </Row>
    );
}

export default Overview;