import { Helmet } from 'react-helmet-async';

import { default as ForgotPasswordModule } from '../../../modules/account/forgotpassword/ForgotPassword';
import { t } from '../../../lib/translations';

const ForgotPassword = () => {
    return (
        <>
            <Helmet>
                <title>{ t('account.login.page_title') }</title>
                <meta name="robots" content="noindex, follow" />
            </Helmet>

            <ForgotPasswordModule />
        </>
    )
}

export default ForgotPassword;