import { useState, useEffect } from "react";

import classNames from 'classnames';
import classes from './Counter.module.css';

const Counter = ({ name = '', value = 0, min = 0, max = 9999, step = 1, rootClassName, inputClassName, decrementerClassName, incrementerClassName, parentCallback, trigger = "change", autoAdjustQty = true, collapsed = false, minComponent:MinComponent, maxComponent:MaxComponent, ...restProps  }) => {
    const [ quantity, setQuantity ] = useState(value); // Current value in field
    const [ lastQuantity, setLastQuantity ] = useState(value); // Last "saved" value in field
    const [ buttonsDisabled, setButtonsDisabled ] = useState(false);

    const getIncrementQuantity = (quantity) => {
        let newQuantity = quantity + step;

        if (newQuantity >= max) {
            newQuantity = max - (max % step)
        } else if (newQuantity % step > 0) {
            newQuantity = newQuantity - (newQuantity % step);
        }

        return newQuantity;
    };

    const getDecrementQuantity = (quantity) => {
        let newQuantity = quantity - step;

        if (newQuantity < 0) {
            newQuantity = min;
        } else if (newQuantity <= min) {
            newQuantity = (min < step) ? step : min;
        } else if (quantity % step > 0) {
            newQuantity = quantity - (quantity % step);
        }

        return newQuantity;
    };

    const nextQty = getIncrementQuantity(quantity);
    const prevQty = getDecrementQuantity(quantity);

    const rootClasses = classNames(classes.root, quantity ? classes.open : null, collapsed ? classes.open : null, rootClassName);
    const inputClasses = classNames(classes.input, step !== 1 ? classes.disabled : null, inputClassName);
    const decrementerClasses = classNames(classes.button, decrementerClassName, (quantity === prevQty || quantity < prevQty || buttonsDisabled) ? classes.button_disabled : null);
    const incrementerClasses = classNames(classes.button, incrementerClassName, (quantity === nextQty || quantity > nextQty || buttonsDisabled) ? classes.button_disabled : null);

    useEffect(() => {
        setQuantity(value);
    }, [value]);

    const incrementQuantity = () => {
        setQuantity((quantity) => {
            const newQuantity = getIncrementQuantity(quantity);

            setLastQuantity(newQuantity);

            if (parentCallback && quantity !== newQuantity ) { parentCallback(newQuantity) }

            return newQuantity;
        });
    };

    const decreaseQuantity = () => {
        setQuantity((quantity) => {
            const newQuantity = getDecrementQuantity(quantity);

            setLastQuantity(newQuantity);

            if (parentCallback && quantity !== newQuantity ) { parentCallback(newQuantity) }

            return newQuantity;
        });
    };

    const onChange = ({ target: { value } }) => {
        if (trigger === "change") {
            onChangeQuantity(value);
        } else {
            setQuantity(value);
            setButtonsDisabled(true);
        }
    };

    const onBlur = ({ target: { value } }) => {
        if (trigger === "blur") {
            onChangeQuantity(value);
        }
    };

    const onChangeQuantity = (value) => {
        let newQuantity = (value ? value : min);

        if (autoAdjustQty) {
            if (newQuantity < min) {
                newQuantity = ((min < step) ? step : min);
            } else if (newQuantity > max) {
                newQuantity = max - (max % step);
            } else if (newQuantity % step > 0) {
                newQuantity = newQuantity - (newQuantity % step) + step;

                if (newQuantity > max) {
                    newQuantity = max - (max % step);
                }
            }
        }

        setQuantity(newQuantity);
        setLastQuantity(newQuantity);
        setButtonsDisabled(false);

        if (parentCallback && lastQuantity !== newQuantity ) { parentCallback(newQuantity) }
    };

    return (
        <div className={rootClasses}>
            <button className={decrementerClasses} onClick={decreaseQuantity}>
                {
                    MinComponent
                        ?
                        <MinComponent />
                        :
                        <>-</>
                }
            </button>
            <input type="number" name={name} onChange={onChange} onBlur={onBlur} value={quantity} step={step} className={inputClasses} min={min} max={max} {...restProps} />
            <button className={incrementerClasses} onClick={incrementQuantity}>
                {
                    MaxComponent
                        ?
                        <MaxComponent />
                        :
                        <>+</>
                }
            </button>
        </div>
    );
}

export default Counter;
