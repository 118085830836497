import { Drawer } from '@kega/sps-elements';

import { t } from '../../../lib/translations';

import useUi from '../../../hooks/useUi';
import useScrollLock from '../../../hooks/useScrollLock';

import { CloseIcon } from '../../../components/icons';
import MetaNavigation from '../../../components/navigation/meta/MetaNavigation';

import MenuPager from './menupager/MenuPager';

import classes from './MobileMenu.module.css';

const MobileMenu = ({ categories }) => {
    const { isMobileMenuOpen, closeMobileMenu } = useUi();
    
    useScrollLock(isMobileMenuOpen);

    return (
        <Drawer open={isMobileMenuOpen} onClose={closeMobileMenu} direction='left'>
            <div className={classes.root}>
                <header className={classes.header}>{ t('header.mobile_menu_header') } <CloseIcon className={classes.close} onClick={closeMobileMenu}/></header>
                
                <div className={classes.items}>
                    <MenuPager categories={categories} />
                    <div className={classes.seperator} />
                    <MetaNavigation className={classes.meta} onClick={closeMobileMenu} />
                </div>
            </div>
        </Drawer>
    )
}

export default MobileMenu;