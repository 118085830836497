const EnergyLabelA = ({ className }) => {
    return (
        <div className={className}>
            <svg id="Laag_2" data-name="Laag 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128.28 60">
                <title>Energy Label A</title>
                <g id="Laag_1-2" data-name="Laag 1">
                    <g>
                        <polygon strokeWidth="0" fill="#00983e" points="100.77 0 0 0 0 60 100.77 60 128.28 30 100.77 0"/>
                        <path strokeWidth="0" fill="#fff" d="m43.26,37.71h-10.46l-1.97,6.27h-5.41l9.35-27.63h6.68l9.35,27.63h-5.58l-1.97-6.27Zm-1.31-4.22l-.37-1.23c-3.12-10-3.53-12.05-3.53-12.05,0,0-.37,2.05-3.53,12.1l-.37,1.19h7.79Z"/>
                    </g>
                </g>
            </svg>
        </div>
    )
};

export default EnergyLabelA;
