import IconWrapper from '../wrapper/IconWrapper';

const CloseIcon = ({ size = 24, color = null, className = null, onClick = () => {} }) => {
    return (
        <IconWrapper size={size} color={color} className={className} onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="22.205" height="22.205" viewBox="0 0 22.205 22.205">
                <path id="Path_289" data-name="Path 289" d="M0,0H22.205V22.205H0Z" fill="none"/>
                <path id="Path_290" data-name="Path 290" d="M17.953,6.3,16.648,5l-5.172,5.172L6.3,5,5,6.3l5.172,5.172L5,16.648l1.3,1.3,5.172-5.172,5.172,5.172,1.3-1.3-5.172-5.172Z" transform="translate(-0.374 -0.374)" />
            </svg>
        </IconWrapper>
    );
}

export default CloseIcon;


