import IconWrapper from '../wrapper/IconWrapper';

const CookieIcon = ({ size = 24, color = null, className = null, onClick = () => { } }) => {
    return (
        <IconWrapper size={size} color={color} className={className} onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 32 32">
                <g id="Group_1712" data-name="Group 1712">
                    <rect id="Rectangle_639" data-name="Rectangle 639" width="32" height="32" fill="none" />
                </g>
                <g id="Group_1714" data-name="Group 1714" transform="translate(2.661 2.881)">
                    <g id="Group_1713" data-name="Group 1713">
                        <circle id="Ellipse_10" data-name="Ellipse 10" cx="2" cy="2" r="2" transform="translate(9.339 7.119)" />
                        <ellipse id="Ellipse_11" data-name="Ellipse 11" cx="2.5" cy="1.5" rx="2.5" ry="1.5" transform="translate(6.339 14.119)" />
                        <ellipse id="Ellipse_12" data-name="Ellipse 12" cx="1" cy="1.5" rx="1" ry="1.5" transform="translate(16.339 16.119)" />
                        <path id="Path_458" data-name="Path 458" d="M28.545,13.97a3.988,3.988,0,0,1-3.566-5.615,5.3,5.3,0,0,1-6.906-6.067C8.8.332,2,7.876,2,15.314A13.306,13.306,0,1,0,28.545,13.97ZM15.306,25.959A10.659,10.659,0,0,1,4.661,15.314c0-4.4,3.632-10.884,10.751-10.671a7.965,7.965,0,0,0,6.64,6.573,6.725,6.725,0,0,0,3.885,4.83A10.675,10.675,0,0,1,15.306,25.959Z" transform="translate(-2 -1.974)" />
                    </g>
                </g>
            </svg>

        </IconWrapper>
    );
}

export default CookieIcon;


