import IconWrapper from '../wrapper/IconWrapper';

const MarkerIcon = ({ size = 24, color = null, direction = 'top', className = null }) => {
    return (
        <IconWrapper size={size} color={color} direction={direction} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="36.817" height="39.97" viewBox="0 0 36.817 39.97">
                <path id="Path_496" data-name="Path 496" d="M0,0H36.817V39.97H0Z" fill="none" />
                <path id="Path_497" data-name="Path 497" d="M18.409,19.985a3.6,3.6,0,1,1,3.6-3.6A3.61,3.61,0,0,1,18.409,19.985Zm10.807-3.237c0-6.529-4.773-11.151-10.807-11.151S7.6,10.219,7.6,16.748c0,4.208,3.512,9.784,10.807,16.438C25.7,26.532,29.215,20.956,29.215,16.748ZM18.409,2A14.317,14.317,0,0,1,32.817,16.748q0,8.957-14.409,21.222Q4.009,25.7,4,16.748A14.317,14.317,0,0,1,18.409,2Z" transform="translate(0 0)" fill="currentColor" />
            </svg>

        </IconWrapper>
    );
}

export default MarkerIcon;