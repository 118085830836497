import { connectRange } from 'react-instantsearch-dom';
import { useConfig } from '@kega/sps-core';
import { Form, AccordionItem, AccordionHeader, AccordionContent, AccordionIcon } from '@kega/sps-elements';

import { t } from "../../../../../lib/translations";

import Input from '../../../../../components/form/Input';
import Button from '../../../../../components/buttons/Button';

import classes from './RangeFilter.module.css';

const RangeFilter = ({
    headingText,
    submitText,
    separatorText,
    currentRefinement,
    min,
    max,
    refine,
    shouldOpen,
    attribute
}) => {

    const config = useConfig();
    const locale = config.get('locale');

    const number_format = new Intl.NumberFormat(locale);

    const onSubmit = ({ values }) => {
        try {
            refine({
                min: Number(values?.min ? values?.min : currentRefinement.min),
                max: Number(values?.max ? values?.max : currentRefinement.max),
            });
        } catch (error) {
            // Empty
        }
    }

    return (
        <AccordionItem className={classes.filter} resizeOnOpen isOpen={shouldOpen}>
            <AccordionHeader className={classes.heading}>{t('productlist.filter.' + attribute)}<AccordionIcon color="#000" /></AccordionHeader>
            <AccordionContent>
                <div className={classes.root}>
                    {
                        headingText &&
                        <div className={classes.heading}>
                            {headingText}
                        </div>
                    }

                    <Form onSubmit={onSubmit} className={classes.values}>
                        <Input
                            type="number"
                            name="min"
                            min={min}
                            max={max}
                            step="any"
                            placeholder={currentRefinement.min ? number_format.format(currentRefinement.min) : ''}
                            value={currentRefinement.min !== min ? currentRefinement.min : ''}
                        />
                        <div className={classes.separator}>
                            {separatorText}
                        </div>
                        <Input
                            type="number"
                            name="max"
                            min={min}
                            max={max}
                            step="any"
                            placeholder={currentRefinement.max ? number_format.format(currentRefinement.max) : ''}
                            value={currentRefinement.max !== max ? currentRefinement.max : ''}
                        />
                        <Button type="submit" variant="primary" className={classes.submit}>
                            {submitText}
                        </Button>
                    </Form>

                </div>
            </AccordionContent>
        </AccordionItem>
    );
}

export default connectRange(RangeFilter);