import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useConfig } from '@kega/sps-core';

import Header from '../modules/header/Header';
import Footer from '../modules/footer/Footer';
import Errors from '../modules/errors/Errors';

import CookieConsent from '../components/cookieconsent/CookieConsent';

import UspMenu from '../modules/usps/UspMenu';

import classes from './DefaultLayout.module.css';

const DefaultLayout = () => {
    const config = useConfig();
    const language = config.get('language');
    const { domain_verification_key } = config.get('facebook');
    const { appId } = config.get('algolia');

    return (
        <>
            <Helmet
                defaultTitle={'HANOS SHOP'}
                titleTemplate={'%s | HANOS SHOP'}
            >
                <html lang={language} />
                <meta name="facebook-domain-verification" content={domain_verification_key} />

                <link crossOrigin href={"https://" + appId + "-dsn.algolia.net"} rel="preconnect" />
                <link crossOrigin href={"https://cdn.builder.io"} rel="preconnect" />

            </Helmet>

            <Header />

            <main className={classes.main}>
                <UspMenu />
                <Outlet />
            </main>

            <CookieConsent />

            <Footer />

            <Errors />
        </>
    )
}

export default DefaultLayout;