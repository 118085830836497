import { Price } from '@kega/sps-components';
import { useConfig } from '@kega/sps-core';

import { t } from '../../../lib/translations';

import classes from './CartTotals.module.css';

const CartTotals = ({ total, subTotal }) => {

    const config = useConfig();
    const locale = config.get('locale');
    const currency = config.get('currency');

    return (
        <div className={classes.root}>
            <div className={classes.total}>
                {t('checkout.cart.totals_carttotals')} <Price price={subTotal.value} currency={currency} locale={locale} />
            </div>
        </div>
    )
}

export default CartTotals;