import { useEffect, useMemo, useState } from 'react';
import { useCustomer, useAddresses } from '@kega/sps-core';
import { Loader } from '@kega/sps-elements';

import classNames from 'classnames';

import useRemoveAddress from '../../../../hooks/customer/useRemoveAddress';

import { Slider, SliderItem } from '../../../../components/slider';
import { CloseIcon } from '../../../../components/icons';
import Address from '../../../../components/address/Address';

import classes from './ShippingAddress.module.css';

const ShippingAddress = ({ onChange, selected }) => {
    const { addresses } = useCustomer();
    const { fetchAddresses } = useAddresses();

    const { loading: loadingRemove, removeAddress } = useRemoveAddress();

    const [ deletedAddress, setDeletedAddress ] = useState();

    const shippingAddresses = useMemo(() => {
        if (addresses === undefined) { return [];}
        return addresses.filter(({ shippingAddress }) => { return shippingAddress; })
    }, [addresses]);

    const selectedId = useMemo(() => {
        if (shippingAddresses.length > 0) {
            return shippingAddresses[shippingAddresses.length - 1].id;
        }
        return null;
    }, [addresses]);

    useEffect(() => {
        onChange(selectedId);
    }, [shippingAddresses]);

    const selectAddress = async (id) => {
        onChange(id);
    }

    const onDelete = async (addressId) => {
        try {
            setDeletedAddress(addressId);
            await removeAddress(addressId);
            fetchAddresses();
        } catch (errorRemove) {
            console.log(errorRemove);
        }
    }

    const createSliderItem = (address, isDefault = false) => {
        if (!address) { return null }
        return (
            <SliderItem key={address.id} className={classes.item}>
                <button
                    className={classNames(classes.address, selected === address.id && classes.address_selected)}
                    onClick={() => selectAddress(address.id)}
                >
                    {!isDefault && (
                        <div className={classes.close_icon}>
                            {loadingRemove && deletedAddress === address.id ? 
                                <Loader variant="primary" /> : 
                                <CloseIcon className={classes.close_icon} onClick={(e) => {
                                    e.stopPropagation(); 
                                    onDelete(address.id);
                                }} 
                                />}
                        </div>
                    )}
                    <Address address={address} />
                </button>
            </SliderItem>
        )
    };

    const firstSliderItem = createSliderItem(shippingAddresses[0], true);
    
    const remainingSliderItems = shippingAddresses?.slice(1).reverse().map((address) => createSliderItem(address));
    
    const combinedSliderItems = [firstSliderItem, ...remainingSliderItems];

    return (
        <div className={classes.root}>
            <Slider className={classes.slider} controls={false}>
                {
                    combinedSliderItems
                }
            </Slider>
        </div>
    )
}

export default ShippingAddress;