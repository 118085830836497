import { forwardRef } from 'react';
import { Checkbox as DefaultCheckbox } from '@kega/sps-elements';

import classNames from 'classnames';

import classes from './Form.module.css';

const Checkbox = ({ className, ...props }, ref) => {
    const checkboxClassName = classNames(classes.checkbox, className);

    return (
        <DefaultCheckbox ref={ref} className={checkboxClassName} {...props} />
    );
}

export default forwardRef(Checkbox);