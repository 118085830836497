const useTagManager = () => {

    const initGTM = ({ id=null, initialConsent={} }) => {
        const js =`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            window.gtag = gtag;

            gtag('consent', 'default', ${ JSON.stringify(initialConsent) });

            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${id}');
        `;

        const script = document.createElement('script');
        script.innerHTML = js;
        script.id = 'tagmanager';
        document.head.insertBefore(script, document.head.childNodes[1]);
    }

    const updateConcent = (params) => {
        if (window.gtag) {
            window.gtag('consent', 'update', params);
        } else {
            console.error('Google tag manager not initialized.');
        }
    }

    const ecommerceEvent = (event, data={}) => {

        if (window.dataLayer) {
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                event: event,
                ecommerce: data
            });
        } else {
            console.error('Google tag manager datalayer doesn\'t exists.');
        }
       
    }

    return {
        initGTM,
        updateConcent,
        ecommerceEvent,
        dataLayer: window.dataLayer
    }

}

export default useTagManager;