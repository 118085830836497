import { useStorefront, useRequest } from '@kega/sps-core';

const useUpdateSubscriptionStatus = () => {
    const { api: { occnewsletter } } = useStorefront();
    const { error, loading, setRequest, setSuccess, setError } = useRequest();

    const updateSubscriptionStatus = async (objectID, isSubscribed) => {
        setRequest();
        try {
            const result = await occnewsletter.updateSubscriptionStatus({
                objectID,
                subscriptionConsentNonfood: isSubscribed,
            });
            setSuccess();
            return result;
        } catch (error) {
            console.error('[UpdateSubscriptionStatus]', error);
            setError(error);
            return null;
        }
    };

    return {
        error,
        loading,
        updateSubscriptionStatus
    };
};

export default useUpdateSubscriptionStatus;
