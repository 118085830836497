import { useState } from 'react';
import { useLogout } from '@kega/sps-core';

import { NavLink, Outlet } from "react-router-dom";
import { t } from '../../lib/translations';
import { Col, Row, Container } from '../../components/grid';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';

import useTranslatePath from '../../hooks/useTranslatePath';

import classes from './Account.module.css';

const Account = () => {
    const [breadcrumb, setBreadcrumb] = useState([]);
    const { getPath } = useTranslatePath();
    const logout = useLogout();

    return (
        <>
            <Container xl margins g-xs={2} g-md={3}>

                <Row>
                    <Col md={12}>
                        <Breadcrumb paths={breadcrumb} />
                    </Col>
                    <Col md="3">
                        <nav className={classes.nav}>
                            <NavLink to={getPath('/account/overview')}>{ t('account.navigation.overview') }</NavLink>
                            <NavLink to={getPath('/account/profile')}>{ t('account.navigation.profile') }</NavLink>
                            <NavLink to={getPath('/account/orders')}>{ t('account.navigation.orders') }</NavLink>
                            <NavLink to={getPath('/account/wishlist')}>{ t('account.navigation.wishlist') }</NavLink>
                            <NavLink to={getPath('/account/return')}>{ t('account.navigation.return') }</NavLink>
                            
                            <button onClick={logout}>{ t('account.navigation.logout') }</button>
                        </nav>
                    </Col>
                    <Col md="9">
                        <Outlet context={setBreadcrumb} />
                    </Col>
                </Row>

            </Container>
        </>
    )
}

export default Account;