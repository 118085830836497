import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useConfig } from '@kega/sps-core';

import MinimizedHeader from '../modules/header/MinimizedHeader';
import MinimizedFooter from '../modules/footer/MinimizedFooter';

import CookieConsent from '../components/cookieconsent/CookieConsent';

import classes from './MinimalLayout.module.css';

const MinimalLayout = () => {
    const config = useConfig();
    const language = config.get('language');
    const { domain_verification_key } = config.get('facebook');
    
    const { appId } = config.get('algolia');

    return (
        <>
            <Helmet
                defaultTitle={'HANOS SHOP'}
                titleTemplate={'%s | HANOS SHOP'}
            >
                <html lang={language} />
                <meta name="facebook-domain-verification" content={domain_verification_key} />

                <link crossOrigin href={"https://" + appId + "-dsn.algolia.net"} rel="preconnect" />
                <link crossOrigin href={"https://cdn.builder.io"} rel="preconnect" />

            </Helmet>

            <MinimizedHeader />

            <main className={classes.main}>
                <Outlet />
            </main>

            <CookieConsent />

            <MinimizedFooter />
        </>
    )
}

export default MinimalLayout;