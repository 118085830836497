import { t } from '../../lib/translations';

import { Col } from '../grid';

import classes from './CustomPagination.module.css'

const CustomPagination = ({ totalPages, currentPage, onPageChange }) => {
    const handleFirstPage = () => {
        onPageChange(0);
    };
    
    const handleLastPage = () => {
        onPageChange(totalPages - 1);
    };
    
    const handlePrevPage = () => {
        onPageChange(currentPage - 1);
    };
    
    const handleNextPage = () => {
        onPageChange(currentPage + 1);
    };

    const handlePage = (page) => {
        onPageChange(page)
    }

    const isFirst = currentPage === 0;
    const isLast = currentPage === totalPages - 1;

    return (
        <Col md={10} className={classes.pagination}>
            {!isFirst && 
                <>
                    <div onClick={handleFirstPage}>«</div>
                    <div onClick={handlePrevPage}>{ t('pagination.previous') }</div>
                </>
            }
            {Array.from({ length: totalPages }, (_, index) => (
                <div 
                    key={index}
                    className={currentPage === index ? classes.active_container : null} 
                    onClick={() => handlePage(index)} 
                >
                    {index + 1}
                </div>
            ))}
            {!isLast &&
                <>
                    <div onClick={handleNextPage}>{ t('pagination.next') }</div>
                    <div onClick={handleLastPage}>»</div>
                </>
            } 
        </Col>
    );
}

export default CustomPagination;