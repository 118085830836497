import { Suspense, lazy } from 'react';

const BuilderSection = lazy(() => import('./BuilderSection'));

const BuilderSectionLazy = (props) => {

    return (
        <Suspense fallback={<></>}>
            <BuilderSection {...props} />
        </Suspense>
    );
}

export default BuilderSectionLazy;