import { createAction } from '@reduxjs/toolkit';

export const cartOpen = createAction('ui/cart_open');
export const cartClose = createAction('ui/cart_close');

export const mobileMenuOpen = createAction('ui/mobilemenu_open');
export const mobileMenuClose = createAction('ui/mobilemenu_close');

export const filtersOpen = createAction('ui/filters_open');
export const filtersClose = createAction('ui/filters_close');
